<script>
  import { fade, fly, slide } from "svelte/transition";
  import { onMount } from "svelte";
  import { getCSRFToken } from "../../utils";
  import { circleChart } from "../bootstrapActivate";
  import ProjectWebSocketComponent from "../websocket/ProjectWebSocketComponent.svelte";
  import ChecklistStep from "./ChecklistStep.svelte";
  export let userid;
  export let currentOrgId;
  export let createProjectUrl;
  export let organizationDetailsUrl;
  export let currentOrganizationHasCustomBranding = false;
  export let isCollapsed = false;

  // Fixing the string/boolean issue from django -> svelte
  $: currentOrganizationHasCustomBranding =
    currentOrganizationHasCustomBranding == "True";

  export let permissions;

  let user = {};

  let isDismissed = false;
  let userHasCompletedAllSteps = false;
  let textColor = "#377dff";
  let completedStepsLength;

  $: textColor = userHasCompletedAllSteps ? "#00c9a7" : "#377dff";
  $: completedStepsLength = steps.filter((step) =>
    completed_steps.includes(step.id)
  ).length;

  let loading = 1;

  let completed_steps = [];

  let steps = [
    {
      id: "create_project",
      name: "Create a Project",
      href: createProjectUrl,
      dependencies: [],
      required_permission: "create_projects",
    },
    {
      id: "create_task",
      name: "Create a Task",
      href: "#",
      dependencies: ["create_project"],
      required_permission: "create_tasks",
      description:
        "Create a task in a project by clicking '+ Add Task.' Type in a task name and hit 'Return' key or click the '+ Add Task' button!",
    },
    {
      id: "set_task_to_notify_client",
      name: "Set a Task to Notify Client",
      href: "#",
      dependencies: ["create_project", "create_task"],
      required_permission: "change_tasks",
      description:
        "On any task, click the dropdown button on the far right. Then toggle on 'Notify Client.' This will set this task to notify your client upon task completion.",
    },
    {
      id: "send_kickoff_email",
      name: "Send a Project Kickoff Email",
      href: "#",
      dependencies: ["create_project", "create_task"],
      required_permission: "change_projects",
      description:
        "Open any project and click 'Start Project.' Then click 'Send Kickoff Email' to fire off a spiffy new kickoff email to your client.",
    },
    {
      id: "notify_client_task",
      name: "Send a Task Update",
      href: "#",
      dependencies: [
        "create_project",
        "create_task",
        "set_task_to_notify_client",
      ],
      required_permission: "message_clients",
      description:
        "Check off any task that has been marked 'Notify Client.' Then click Send & Complete. This will send your client a project update!",
    },
    {
      id: "org_has_custom_branding",
      name: "Customize Organization Branding",
      href: organizationDetailsUrl,
      dependencies: [],
      required_permission: "change_organization_profile",
    },
  ];

  $: permitted_steps = steps.filter((step) =>
    permissions.includes(step.required_permission)
  );

  $: userHasCompletedAllSteps = permitted_steps.every((permitted_step) =>
    completed_steps.includes(permitted_step.id)
  );

  async function fetchUser() {
    const response = await fetch(
      `/api/users/${userid}?ts=${new Date().getTime()}`
    );

    if (response.ok) {
      const result = await response.json();
      user = result;
      completed_steps = currentOrganizationHasCustomBranding
        ? [...user.completed_actions, "org_has_custom_branding"]
        : user.completed_actions;

      isDismissed = !user.dismissed_welcome_message;
    } else {
      error = "Failed to load user details.";
    }
  }

  async function setIsCollapsed(event) {
    isCollapsed = !isCollapsed;

    await fetch(user.url + "set_checklist_is_collapsed/", {
      method: "PUT",
      body: JSON.stringify({
        ...user,
        checklist_is_collapsed: isCollapsed,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CsrfToken": getCSRFToken(),
      },
      credentials: "include",
    });
  }

  async function dismissWelcomeChecklist(event) {
    isDismissed = true;

    await fetch(user.url + "dismiss_welcome_checklist/", {
      method: "PUT",
      body: JSON.stringify({
        ...user,
        dismissed_welcome_checklist: isDismissed,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CsrfToken": getCSRFToken(),
      },
      credentials: "include",
    });
  }

  onMount(async () => {
    await fetchUser();

    // Convert the normalized string to a boolean
    isCollapsed = isCollapsed == "True";

    loading--;
  });
</script>

{#if loading === 0 && !isDismissed}
  <ProjectWebSocketComponent
    {userid}
    roomName={currentOrgId}
    on:updateChecklist={async (event) => {
      await fetchUser();
    }}
  />
  <div
    class="card mb-3 {userHasCompletedAllSteps
      ? 'bg-soft-secondary'
      : 'bg-soft-primary'}"
    in:fly={{ y: 40 }}
    out:slide
  >
    <div class="card-body p-4">
      <button
        class="btn float-end me-n4 mt-n4 p-0 pt-1 pe-2"
        on:click={dismissWelcomeChecklist}
        ><i class="bi-x" />
      </button>
      <div class="d-flex align-items-center justify-content-between">
        <div class="flex-grow-1">
          <button
            class="btn p-0 border-0 m-0 text-start"
            on:click={setIsCollapsed}
          >
            <h5 class="mb-0">Quickstart Checklist</h5>
            <p class="mb-0">
              {permitted_steps.filter((permitted_step) =>
                completed_steps.includes(permitted_step.id)
              ).length}/{permitted_steps.length} complete
            </p>
          </button>
        </div>
        <!-- Circle -->
        <div
          id="hi"
          class="js-circle"
          use:circleChart={{
            value: completedStepsLength,
            maxValue: steps.length,
            duration: 200,
            isViewportInit: true,
            isHideValue: true,
            colors: ["rgba(55, 125, 255, 0.1)", textColor],
            radius: 25,
            width: 4,
            textFontSize: 16,
            type: "iconic",
            icon: '<i class="bi-trophy"></i>',
            textClass: "circles-chart-content p-0",
            fgStrokeLinecap: "round",
            textColor: textColor,
          }}
        />
        <!-- End Circle -->
      </div>
      {#if !userHasCompletedAllSteps}
        {#if !isCollapsed}
          <div transition:slide>
            <!-- Step -->
            <ul class="step step-border-last-0 step-icon-xs mt-3">
              {#each permitted_steps as step}
                <ChecklistStep {completed_steps} {step} />
              {/each}
            </ul>
            <!-- End Step -->
          </div>
        {/if}
        <div class="d-grid">
          <button
            class="btn p-0 pb-1 border-0 z-1 mb-n4"
            on:click={setIsCollapsed}
            ><i
              class={isCollapsed
                ? "bi-chevron-compact-down"
                : "bi-chevron-compact-up"}
            />
          </button>
        </div>
      {/if}
    </div>
  </div>
{/if}
