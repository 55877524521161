import Quill from "quill";
import DOMPurify from "dompurify"

export function tooltip(node, options) {
  let tooltip = new bootstrap.Tooltip(node, typeof options === 'string' ? {
    position: options,
  } : options);

  return {
    destroy() {
      tooltip?.dispose();
    },

    update(options) {
      tooltip?.dispose();
      tooltip = new bootstrap.Tooltip(node, typeof options === 'string' ? {
    position: options,
  } : options);
    }
	}
}
  
export function chart(node, options) {
  new HSCore.components.HSChartJS.init(node, options);

  return {
    destroy() {
    }
	}
}

export function circleChart(node, options) {
  
  function initializeCircleChart(node, options) {
    node.setAttribute('data-hs-circles-options', JSON.stringify(options));
    HSCore.components.HSCircles.init(node, options);
  }
  
  initializeCircleChart(node, options)

  
  return {
    update(options) {
      initializeCircleChart(node, options)
    },
   
    destroy() {
      // Cleanup code here if necessary
    }
 };
}
  
export function stickyBlock(node, options) {
  new HSStickyBlock(node, options);

  return {
    destroy() {

    }
  }
}
export function navScroller(node) {
  new HsNavScroller(node);

  return {
    destroy() {

    }
  }
}

export function videoPlayer(node, options) {
  new HSVideoPlayer(node, options);

  return {
    destroy() {

    }
  }
}

export function quill(node, options) {
  
HSCore.components.HSQuill.init(node, options);

  const quill = HSCore.components.HSQuill.getItem(node.id);
  
  // FORMATTING FOR CONTENT

  // Autolink URLs when typing
  quill.on('text-change', function (delta, oldDelta, source) {
  
  function isWhitespace(ch) {
    var whiteSpace = false
    if ((ch == ' ') || (ch == '\t') || (ch == '\n')) {
      whiteSpace = true;
    }
    return whiteSpace;
  }
    
  var regex = /https?:\/\/[^\s]+$/;
  if(delta.ops.length === 2 && delta.ops[0].retain && isWhitespace(delta.ops[1].insert)) {
    var endRetain = delta.ops[0].retain;
    var text = quill.getText().substr(0, endRetain);
    var match = text.match(regex);

    if(match !== null) {
      var url = match[0];

      var ops = [];
      if(endRetain > url.length) {
        ops.push({ retain: endRetain - url.length });
      }

      ops = ops.concat([
        { delete: url.length },
        { insert: url, attributes: { link: url } }
      ]);

      quill.updateContents({
        ops: ops
      });
    }
  }
});

// Prevent images from being pasted
quill.clipboard.addMatcher('IMG', (node, delta) => {
    const Delta = Quill.import('delta');
    return new Delta().insert('');
});

quill.clipboard.addMatcher('PICTURE', (node, delta) => {
    const Delta = Quill.import('delta');
    return new Delta().insert('');
});

  
quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
 // Process each operation in the delta
 delta.ops = delta.ops.map(op => {
    // Check if the operation has an 'attributes' property
    if (op.attributes) {
      // Filter out unwanted attributes
      const allowedAttributes = ['bold', 'italic', 'strike', 'underline', 'list', 'link'];
      op.attributes = Object.keys(op.attributes)
        .filter(attr => allowedAttributes.includes(attr))
        .reduce((obj, key) => {
          obj[key] = op.attributes[key];
          return obj;
        }, {});
    }
    return op;
 });

 // Add a matcher for text nodes to preserve hyperlinks
 quill.clipboard.addMatcher(Node.TEXT_NODE, function(node, delta) {
    var regex = /https?:\/\/[^\s]+/g;
    if (regex.exec(node.data) != null) {
      delta.ops = [{ insert: node.data, attributes: { link: node.data }}];
    }
    return delta;
 });

 return delta;
});

// Sanitize the content
  function sanitizeContent(content) {
  return DOMPurify.sanitize(content);
}
  
  // AFTER INITIALIZING QUILL

  // Access the Quill editor container
  const container = node.getElementsByClassName("ql-editor")[0];
  // Set the initial content of the editor

  // Convert the new content to a Delta object
  const newDelta = quill.clipboard.convert(sanitizeContent(options.initialContent));

  // Apply the new content using updateContents
  quill.setContents(newDelta);


  // Listen for text changes in the Quill editor
  quill.on("text-change", function (delta, oldDelta, source) {
    if (source === 'user') {
      node.dispatchEvent(
        new CustomEvent("text-change", {
          detail: {
            html: sanitizeContent(container.innerHTML.replaceAll("<p><br></p>", "")),
            text: container.innerText
          }
        })
      );
    }
  });

quill.on('selection-change', function(range, oldRange, source) {
  if (source === 'user') {
    if (quill.hasFocus()) {
      node.dispatchEvent(
        new CustomEvent("focus")
      );
    } 
  }
});

  
  const handleClick = event => {
    const isClickOutsideNode = node != null && !node.contains(event.target) && !event.defaultPrevented;
    if (isClickOutsideNode) {
      node.dispatchEvent(
        new CustomEvent('click_outside', {
          detail: {
            html: sanitizeContent(container.innerHTML.replaceAll("<p><br></p>", "")),
            text: container.innerText
          },
        })
      )
    }
  }

	document.addEventListener('click', handleClick, true);
  
  return {
    update(options) {
      quill.root.setAttribute('data-placeholder', options.placeholder);
      
      // Convert the new content to a Delta object
      const newDelta = quill.clipboard.convert(sanitizeContent(options.initialContent));
      
      const currentSelection = quill.getSelection();
      quill.setContents(newDelta);
      
      // Retain the cursor position
      if (currentSelection) {
        quill.setSelection(currentSelection.index, currentSelection.length);
      }
    },
    
    destroy() {
      document.removeEventListener('click', handleClick, true);
      HSCore.components.HSQuill.collection = HSCore.components.HSQuill.collection.filter(item => item.id !== node.id)
    }
  }
}

// Custom Actions

  export function autoResize(node, content) {
    const resize = () => {
      // Force a reflow to ensure the browser recalculates scrollHeight
      void node.offsetHeight;
      node.style.height = "auto"; // Reset the height to shrink
      node.style.height = node.scrollHeight + "px"; // Set the height to scrollHeight
    };

    // Initial resize
    resize();
    return {
      update(content) {
        // Have to reset the value of the node to recalculate scrollHeight before calling resize.
        node.value = content
        resize();
      },
      destroy() {
      },
    };
  }
