<script>
  import { onMount } from "svelte";
  import axios from "axios";
  import { getCSRFToken } from "../../utils";
  import Loader from "../Loader.svelte";
  import { fade, slide } from "svelte/transition";
  import { getContext } from "svelte";
  import {
    projCreateSchema,
    projCreateClientContactSchema,
    projCreateContactSchema,
  } from "../../schema";
  const user = getContext("user");
  const currentOrg = getContext("currentOrg");
  import dayjs from "dayjs";
  import Popover from "svelte-easy-popover";
  import SveltyPicker from "../picker/SveltyPicker.svelte";
  import { clickOutside } from "../clickOutside";
  import { tooltip } from "../bootstrapActivate";

  let day = dayjs();

  export let contactsApiUrl;
  export let clientsApiUrl;

  export let projectName = "";
  export let client = "";
  export let contact = "";
  export let clientName = "";
  export let firstName = "";
  export let lastName = "";
  export let title = "";
  export let email = "";
  export let startDate = null;
  export let key_contact = { id: null };
  export let isAddingClient = false;
  export let isAddingContact = false;
  export let convertProjectToTemplate = false;
  let form;
  let isEditingDate;
  let referenceElementStartDate;

  let errors = {};

  let loading = 1;

  let clients = [];
  export let availableContacts = [];
  export let selectedContacts = [];

  $: hasClients = clients.length > 0;
  $: hasContacts = client && availableContacts.length > 0;
  $: isAddingClient = !hasClients;
  $: isAddingContact = isAddingClient || !hasContacts;
  $: if (key_contact == null) {
    key_contact = { id: null };
  }

  async function load(callback) {
    ++loading;
    try {
      const result = await callback();
      return result;
    } finally {
      --loading;
    }
  }
  // Select first client if we have possible clients to select,
  // but we haven't yet selected a client.

  async function loadContacts() {
    try {
      const response = await axios.get(`${client}get_contacts`);
      availableContacts = response.data.results;
    } catch (e) {
      console.error("Failed to load client contacts.");
      clients = [];
    }
  }

  async function loadClients(selectedClient = 0) {
    return load(async () => {
      try {
        const response = await axios.get(`${clientsApiUrl}?page_size=100`);
        clients = response.data.results;
        if (!client && clients.length > 0) {
          client = clients[selectedClient].url;
        }
        await loadContacts();
      } catch (e) {
        console.error("Failed to load clients.");
        clients = [];
      }
    });
  }

  onMount(async () => {
    await loadClients();
    --loading;
  });

  const extractErrors = ({ inner }) => {
    return inner.reduce((acc, err) => {
      return { ...acc, [err.path]: err.message };
    }, {});
  };

  export function validateForm() {
    form = {
      projectName: projectName,
      selectedContacts: selectedContacts,
    };

    if (isAddingClient) {
      form = {
        ...form,
        clientName: clientName,
        firstName: firstName,
        lastName: lastName,
        title: title,
        email: email,
      };
      return projCreateClientContactSchema
        .validate(form, { abortEarly: false })
        .then(() => {
          // clear the errors
          errors = {};
          return true;
        })
        .catch((err) => {
          errors = extractErrors(err);
          return false;
        });
    } else if (isAddingContact) {
      form = {
        ...form,
        firstName: firstName,
        lastName: lastName,
        title: title,
        email: email,
      };
      return projCreateContactSchema
        .validate(form, { abortEarly: false })
        .then(() => {
          // clear the errors
          errors = {};
          return true;
        })
        .catch((err) => {
          errors = extractErrors(err);
          return false;
        });
    } else {
      return projCreateSchema
        .validate(form, { abortEarly: false })
        .then(() => {
          // clear the errors
          errors = {};
          return true;
        })
        .catch((err) => {
          errors = extractErrors(err);
          return false;
        });
    }
  }

  export function checkIsAddingContact() {
    if (isAddingContact) {
      {
        !isAddingClient
          ? (errors.isAddingContact =
              "Please finish adding your contact before continuing.")
          : (errors.isAddingContact =
              "Please finish adding your client and contact before continuing.");
      }
      return true;
    } else {
      return false;
    }
  }

  async function createContact() {
    let formIsValid = await validateForm();
    if (formIsValid) {
      if (isAddingClient) {
        try {
          const response = await axios.post(
            `${clientsApiUrl}`,
            {
              name: clientName,
              user: user,
              organization: currentOrg,
            },
            {
              headers: {
                "X-CsrfToken": getCSRFToken(),
              },
            }
          );

          const newClient = response.data;
          client = newClient.url;
          await loadClients();
          clientName = "";
          isAddingClient = false;
        } catch (e) {
          console.error("Failed to create client:", e);
        }
      }
      try {
        const response = await axios.post(
          `${contactsApiUrl}`,
          {
            first_name: firstName,
            last_name: lastName,
            email: email,
            title: title,
            user: user,
            organization: currentOrg,
            client: client,
          },
          {
            headers: {
              "X-CsrfToken": getCSRFToken(),
            },
          }
        );

        const newContact = response.data;
        contact = newContact.id;

        selectedContacts.push(contact);
        loadContacts();
        firstName = "";
        lastName = "";
        title = "";
        email = "";
        isAddingContact = false;
      } catch (e) {
        console.error("Failed to create contact:", e);
      }
    }
  }
</script>

{#if loading > 0}
  <div in:fade class="d-flex justify-content-center">
    <Loader />
  </div>
{:else}
  <div in:fade class="ProjectForm">
    <div class="ProjectForm__name row mb-4">
      <span class="col-sm-3 col-form-label form-label">Name</span>
      <div class="col-sm-9">
        <input
          bind:value={projectName}
          class="form-control"
          placeholder="Project Name"
        />
        {#if errors.projectName}
          <div class="text-danger">
            {errors.projectName}
          </div>
        {/if}
      </div>
    </div>
    <div class="ProjectForm__client row mb-4">
      <span class="col-sm-3 col-form-label form-label">Client</span>
      <div class="col-sm-9">
        {#if !isAddingClient}
          <div in:fade>
            <select
              bind:value={client}
              on:change={() => {
                selectedContacts = [];
                key_contact.id = null;
                firstName = "";
                lastName = "";
                title = "";
                email = "";
                loadContacts();
              }}
              class="form-select tomselected"
            >
              {#each clients as { url, name }}
                <option value={url}>{name}</option>
              {/each}
            </select>
          </div>
        {:else}
          <div in:fade>
            <input
              bind:value={clientName}
              class="form-control"
              placeholder="Organization Name"
            />
            {#if errors.clientName}
              <div class="text-danger">
                {errors.clientName}
              </div>
            {/if}
          </div>
        {/if}
        {#if hasClients}
          <button
            class="btn btn-link form-link p-0
          {isAddingClient ? 'text-secondary' : ''}"
            on:click={() => {
              isAddingClient = !isAddingClient;
              if (isAddingClient) {
                selectedContacts = [];
                key_contact.id = null;
              }
            }}
          >
            {#if !isAddingClient}
              <i class="bi-plus-circle me-1" /> Create New Client
            {:else}
              <i class="bi-x-circle me-1" /> Choose Existing Client
            {/if}
          </button>
        {/if}
      </div>
    </div>

    <div class="ProjectForm__contact row mb-4">
      <span class="col-sm-3 col-form-label form-label"
        >Client Contact{#if availableContacts.length > 1}s{/if}</span
      >
      <div class="col-sm-9">
        <!-- List Group -->
        <ul
          class="list-group"
          data-testid={hasContacts ? "hasContacts" : "noContacts"}
          in:fade
          out:slide
        >
          {#if hasContacts && !isAddingClient}
            {#each availableContacts as { id, first_name, last_name, email, title, contact_edit_url }}
              <li class="list-group-item d-flex align-items-center">
                <!-- Checkbox Switch -->
                <div class="form-check form-switch mb-0 me-4">
                  <input
                    type="checkbox"
                    class="form-check-input md"
                    id="formSwitch1"
                    bind:group={selectedContacts}
                    value={id}
                    on:change={() => {
                      if (!selectedContacts.includes(key_contact.id)) {
                        key_contact.id = null;
                      }
                    }}
                  />
                </div>
                <!-- End Checkbox Switch -->
                <div>
                  <h6 class="text-hover-primary mb-0">
                    {first_name}
                    {last_name}
                    <span
                      class=""
                      on:click={() => {
                        if (id == key_contact.id) {
                          key_contact.id = null;
                        } else {
                          if (!selectedContacts.includes(id)) {
                            selectedContacts.push(id);
                            selectedContacts = selectedContacts;
                          }
                          key_contact.id = id;
                        }
                      }}
                      >{#if id == key_contact.id}<i
                          class="bi bi-star-fill text-warning"
                        />{:else}<i class="bi bi-star text-muted" />{/if}
                    </span>
                  </h6>
                  <small class="mt-0"
                    >{#if title}{title} |{/if}
                    {email}</small
                  >
                </div>
                <div class="ms-auto">
                  <a class="btn btn-white btn-xs" href={contact_edit_url}>
                    <i class="bi-pencil-fill me-1" /> Edit
                  </a>
                </div>
              </li>
            {/each}
          {/if}
          {#if isAddingContact}
            <li class="list-group-item" transition:slide>
              <div class="row">
                <div class="col pe-1">
                  <label class="form-label" for="firstName">First Name</label>
                  <input
                    bind:value={firstName}
                    class="form-control"
                    placeholder="Steve"
                    data-testid="contactFirstName"
                  />
                  {#if errors.firstName}
                    <div class="text-danger">
                      {errors.firstName}
                    </div>
                  {/if}
                </div>
                <div class="col ps-1">
                  <label class="form-label" for="lastName">Last Name</label>
                  <input
                    bind:value={lastName}
                    class="form-control"
                    placeholder="Irwin"
                    data-testid="contactLastName"
                  />
                  {#if errors.lastName}
                    <div class="text-danger">
                      {errors.lastName}
                    </div>
                  {/if}
                </div>
              </div>
              <label class="form-label mt-2" for="title">Title (Optional)</label
              >
              <input
                bind:value={title}
                class="form-control"
                placeholder="Crocodile Hunter"
                data-testid="title"
              />
              {#if errors.title}
                <div class="text-danger">
                  {errors.title}
                </div>
              {/if}
              <label class="form-label mt-2" for="email">Email</label>
              <input
                bind:value={email}
                class="form-control"
                placeholder="steve@crikey.com"
                data-testid="email"
              />
              {#if errors.email}
                <div class="text-danger">
                  {errors.email}
                </div>
              {/if}

              <div
                class="d-flex {isAddingClient
                  ? 'justify-content-end'
                  : 'justify-content-between'} align-items-center mt-3"
              >
                {#if !isAddingClient}
                  <button
                    class="btn btn-link form-link text-danger p-0 mt-0"
                    on:click={() => {
                      if (errors.isAddingContact) {
                        errors.isAddingContact = null;
                      }
                      isAddingContact = !isAddingContact;
                    }}
                  >
                    <i class="bi-x-circle me-1" /> Cancel</button
                  >
                {/if}
                <button
                  transition:fade
                  class="btn btn-soft-primary btn-sm"
                  on:click={createContact}
                  ><i class="bi-person-plus me-1" /> Create {#if isAddingClient}Client
                    +{/if} Contact</button
                >
              </div>
            </li>
          {/if}
        </ul>
        <!-- End List Group -->
        {#if isAddingClient}
          <div class="form-link p-0 text-secondary">
            When creating a client for the first time, you must add a contact.
          </div>
        {:else if !hasContacts}
          <div class="form-link p-0 text-secondary">
            It looks like you need to add a contact for this client.
          </div>
        {:else}
          <div class={isAddingContact ? "d-flex justify-content-between" : ""}>
            <button
              in:fade
              class="btn btn-link form-link p-0
            {isAddingContact ? 'text-danger' : ''}"
              on:click={() => {
                isAddingContact = !isAddingContact;
              }}
            >
              {#if !isAddingContact}
                <i class="bi-plus-circle me-1" /> Add New Contact
              {/if}
            </button>
          </div>
        {/if}
        {#if errors.selectedContacts}
          <div class="text-danger mt-2">
            {errors.selectedContacts}
          </div>
        {/if}
        {#if errors.isAddingContact}
          <div class="text-danger mt-2">
            {errors.isAddingContact}
          </div>
        {/if}
      </div>
    </div>
    {#if convertProjectToTemplate}
      <div class="ProjectForm__contact row mb-4">
        <span class="col-sm-3 col-form-label form-label"
          >Project Start Date <i
            class="bi bi-info-circle"
            use:tooltip={"top"}
            title="Used to calculate deadlines"
          /></span
        >
        <div class="col-sm-9">
          <button
            bind:this={referenceElementStartDate}
            class="btn btn-white"
            on:click={() => {
              isEditingDate = !isEditingDate;
            }}
            >{#if startDate}{dayjs(startDate).format("ddd, MMM D")}{:else}<i
                class="bi bi-calendar-event"
              /> Add a Date{/if}</button
          >
          <Popover
            triggerEvents={["click"]}
            referenceElement={referenceElementStartDate}
            placement="bottom-end"
            spaceAway={10}
            bind:isOpen={isEditingDate}
          >
            {#if isEditingDate}
              <div
                class="popover-contents"
                transition:fade={{ duration: 250 }}
                use:clickOutside
                on:click_outside={() => {
                  isEditingDate = false;
                }}
              >
                <SveltyPicker
                  inputClasses=""
                  pickerOnly={true}
                  bind:value={startDate}
                  isOpen={isEditingDate}
                />
              </div>
            {/if}
          </Popover>
        </div>
      </div>
    {/if}
  </div>
{/if}
