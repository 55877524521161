<script>
  import Popover from "svelte-easy-popover";
  import { getContext } from "svelte";
  import { tooltip } from "./bootstrapActivate";
  import { clickOutside } from "./clickOutside";
  import { getCSRFToken } from "../utils";
  import { createEventDispatcher } from "svelte";
  import axios from "axios";

  const dispatch = createEventDispatcher();

  let permissions = getContext("permissions");

  export let client;
  export let project;
  export let memberships;
  let membershipUserIds = memberships.map((membership) => membership.user.id);

  let tooltipPlacement;
  let contactsElement;
  let projectUserElement;
  let showContacts;
  let showProjectUsers;

  $: tooltipPlacement = showProjectUsers ? "top" : "bottom";
  $: usedAllOrganizationMembers = membershipUserIds.every((userId) =>
    project.users.some((user) => user.id === userId)
  );

  async function removeProjectUser(user) {
    // put the updated project object
    try {
      const response = await axios.put(
        `${project.url}remove_project_user/`,
        { id: project.id, user: user },
        {
          headers: {
            "X-CsrfToken": getCSRFToken(),
          },
        }
      );
    } catch (e) {
      console.error("Failed to update project:", e);
    }
  }

  async function addProjectUser(user) {
    // put the updated project object
    try {
      const response = await axios.put(
        `${project.url}add_project_user/`,
        { id: project.id, user: user },
        {
          headers: {
            "X-CsrfToken": getCSRFToken(),
          },
        }
      );
    } catch (e) {
      console.error("Failed to update project:", e);
    }
  }
</script>

<small class="card-subtitle mt-1 d-flex align-items-center">
  <!-- Project User Avatar Group -->
  <div
    class="avatar-group avatar-group-xs"
    bind:this={projectUserElement}
    on:click={() => {
      showProjectUsers = !showProjectUsers;
    }}
    on:keydown={() => {}}
  >
    {#each project.users as projectUser, i}
      {#if i < 3}
        <button
          class="btn btn btn-xs btn-icon border-0 avatar avatar-primary avatar-circle"
          use:tooltip={{
            placement: tooltipPlacement,
          }}
          title={projectUser.first_name + " " + projectUser.last_name}
        >
          {#if projectUser.profile_image}
            <img
              class="avatar-img"
              src={projectUser.profile_image}
              alt={projectUser.first_name +
                " " +
                projectUser.last_name +
                "'s Profile Image"}
            />
          {:else}
            <span class="avatar-initials">{projectUser.first_name[0]}</span>
          {/if}
        </button>
      {/if}
    {/each}
    {#if project.users.length > 3}
      <span class="avatar avatar-primary avatar-circle">
        <span class="avatar-initials">{project.users.length - 3}+</span>
      </span>
    {/if}
    {#if project.users.length == 0}
      <span class="avatar avatar-primary avatar-circle">
        <span class="avatar-initials">+</span>
      </span>
    {/if}
  </div>

  <Popover
    triggerEvents={["click"]}
    referenceElement={projectUserElement}
    placement="bottom-start"
    spaceAway={0}
    bind:isOpen={showProjectUsers}
  >
    {#if showProjectUsers}
      <div
        class="dropdown-menu show"
        style="text-transform:none;"
        use:clickOutside
        on:click_outside={() => {
          showProjectUsers = false;
        }}
      >
        <ul class="list-unstyled small mb-0">
          {#if project.users.length > 0}
            <span class="card-subtitle divider-start p-1">Project Team</span>
            {#each project.users as projectUser}
              <span class="dropdown-item d-flex align-items-center"
                >{#if projectUser.profile_image}<img
                    class="avatar-img avatar-xs avatar-circle"
                    src={projectUser.profile_image}
                    alt="{projectUser.first_name +
                      ' ' +
                      projectUser.last_name} Profile Image"
                  />
                {:else}
                  <span class="avatar avatar-xs avatar-primary avatar-circle"
                    ><span class="avatar-initials"
                      >{projectUser.first_name[0] +
                        projectUser.last_name[0]}</span
                    ></span
                  >
                {/if}
                <div class="mx-2">
                  <strong class=""
                    >{projectUser.first_name + " " + projectUser.last_name}
                  </strong>
                </div>
                {#if permissions.includes("assign_projects")}
                  <button
                    class="ms-auto btn btn-xs btn-icon rounded-pill {project
                      .users.length < 2
                      ? 'btn-ghost-secondary'
                      : 'btn-ghost-danger'}"
                    on:click={async () => {
                      project.users = project.users.filter(
                        (user) => user.id !== projectUser.id
                      );
                      await removeProjectUser(projectUser.url);
                      dispatch("fetchTasks");
                    }}
                    disabled={project.users.length < 2}
                  >
                    <i
                      class="bi {project.users.length < 2
                        ? 'bi-lock'
                        : 'bi-dash-circle'}"
                    />
                  </button>
                {/if}
              </span>
            {/each}
          {/if}
          {#if !usedAllOrganizationMembers}
            <span class="card-subtitle divider-start p-1"
              >Organization Members</span
            >
          {/if}
          {#each memberships as membership (membership.user.id)}
            {#if !project.users.some((user) => user.id === membership.user.id)}
              <div class="dropdown-item d-flex align-items-center">
                {#if membership.user.profile_image}<img
                    class="avatar-img avatar-xs avatar-circle"
                    src={membership.user.profile_image}
                    alt="{membership.user.first_name +
                      ' ' +
                      membership.user.last_name} Profile Image"
                  />
                {:else}
                  <span class="avatar avatar-xs avatar-primary avatar-circle"
                    ><span class="avatar-initials"
                      >{membership.user.first_name[0] +
                        membership.user.last_name[0]}</span
                    ></span
                  >
                {/if}
                <div class="mx-2">
                  <strong class="text-primary"
                    >{membership.user.first_name +
                      " " +
                      membership.user.last_name}
                  </strong><br />
                </div>
                {#if permissions.includes("assign_projects")}
                  <button
                    class="ms-auto btn btn-xs btn-icon btn-white rounded-pill"
                    on:click={() => {
                      let newUser = membership.user;
                      project.users = [...project.users, newUser];
                      addProjectUser(newUser.url);
                    }}
                    ><i class="bi bi-plus-circle" />
                  </button>
                {/if}
              </div>
            {/if}
          {/each}
        </ul>
      </div>
    {/if}
  </Popover>

  <!-- End Project User Avatar Group -->
  <span class="px-1">•</span>
  <button
    class="btn btn-link btn-xs text-secondary text-uppercase p-0"
    on:click={() => {
      window.location.href = project.client_projects_url;
    }}
    disabled={!permissions.includes("view_all_projects")}>{client.name}</button
  >
  <span class="px-1">•</span>
  <button
    bind:this={contactsElement}
    class="btn btn-link btn-xs text-secondary text-uppercase p-0"
    on:click={() => {
      showContacts = !showContacts;
    }}
    >{#if project.contacts.length > 1}Contacts ({project.contacts
        .length}){:else}{#each project.contacts as contact}{contact.first_name}
        {contact.last_name}{/each}{/if}
    <i class={showContacts ? "bi-chevron-up" : "bi-chevron-down"} />
  </button>
  <Popover
    triggerEvents={["click"]}
    referenceElement={contactsElement}
    placement="bottom-start"
    spaceAway={0}
    bind:isOpen={showContacts}
  >
    {#if showContacts}
      <div
        class="dropdown-menu show"
        style="text-transform:none;"
        use:clickOutside
        on:click_outside={() => {
          showContacts = false;
        }}
      >
        <ul class="list-unstyled small mb-0">
          {#each project.contacts as contact}
            <button
              on:click={() => {
                if (permissions.includes("change_projects")) {
                  window.location.href = `${contact.contact_edit_url}`;
                }
              }}
              disabled={!permissions.includes("change_projects")}
              class="dropdown-item"
            >
              <strong
                class={permissions.includes("change_projects")
                  ? "text-primary"
                  : "text-dark"}
                >{contact.first_name + " " + contact.last_name}
                {#if contact.id == project.key_contact.id}<i
                    class="bi bi-star-fill text-warning"
                  />{/if}</strong
              >
              <br />{contact.email}</button
            >
          {/each}
        </ul>
        {#if permissions.includes("change_projects")}
          <div class="dropdown-divider" />
          <a class="dropdown-item" href={project.edit_project_url}
            >Manage Contacts</a
          >
        {/if}
      </div>
    {/if}
  </Popover>
</small>
