<script>
  import { onMount } from "svelte";
  import axios from "axios";
  import { getCSRFToken } from "../../utils";
  import Loader from "../Loader.svelte";
  import { fade, fly } from "svelte/transition";
  import { tooltip } from "../bootstrapActivate";
  import { clickOutside } from "../clickOutside";
  import { createEventDispatcher } from "svelte";
  import { getContext } from "svelte";

  let permissions = getContext("permissions");

  export let membership;
  export let roles;
  export let userId;
  export let currentUserIsOrgOwner;

  let roleTooltipDescription;
  let deleteTooltipDescription;

  $: roleTooltipDescription = getRoleTooltipDescription(membership);
  $: deleteTooltipDescription = getDeleteTooltipDescription(membership);

  function getRoleTooltipDescription(membership) {
    if (membership.user_is_owner) {
      return "An organization owner must always remain an admin.";
    } else {
      return "";
    }
  }

  function getDeleteTooltipDescription(membership) {
    if (membership.user.id == userId) {
      return "You cannot remove yourself from an organization.";
    } else if (membership.user_is_owner) {
      return "An organization owner cannot be deleted.";
    } else {
      return "Remove user from organization";
    }
  }

  const dispatch = createEventDispatcher();
</script>

<tr transition:fade>
  <td>
    <div class="d-flex align-items-center">
      <div class="flex-shrink-0">
        {#if membership.user.profile_image}
          <img
            class="avatar avatar-sm avatar-circle"
            src={membership.user.profile_image}
            alt="{membership.user.first_name +
              ' ' +
              membership.user.last_name} Profile Image"
          />
        {:else}
          <span class="avatar avatar-sm avatar-primary avatar-circle">
            <span class="avatar-initials"
              >{membership.user.first_name[0]}{membership.user
                .last_name[0]}</span
            >
          </span>
        {/if}
      </div>

      <div class="flex-grow-1 ms-3">
        <h6 class="mb-0">
          {membership.user.first_name}
          {membership.user.last_name}
          {#if membership.user_is_owner}
            <button
              class="btn btn-xs rounded-pill text-white btn-secondary py-1"
              disabled>Organization Owner</button
            >
          {:else if currentUserIsOrgOwner}
            <button
              on:click={() => {
                dispatch("clickMakeOwner");
              }}
              class="btn btn-xs btn-outline-primary rounded-pill py-1"
              >Make Owner</button
            >
          {/if}
        </h6>
        <small class="d-block">{membership.user.email}</small>
      </div>
    </div>
  </td>
  <td>
    {#if permissions.includes("change_organization_members")}
      <!-- Select -->
      <select
        bind:value={membership.role}
        on:change={() => {
          if (membership.user.id == userId) {
            dispatch("showChangeSelfModal", {
              membership: membership,
            });
          } else {
            dispatch("clickUpdateMembership");
          }
        }}
        disabled={membership.user_is_owner ||
          !permissions.includes("change_organization_members")}
        class="js-select form-select form-select-sm"
        name="membership-access"
        use:tooltip={{
          placement: "top",
          title: roleTooltipDescription,
        }}
      >
        {#each roles as role}
          <option value={role.choice}>
            {role.text}
          </option>
        {/each}
      </select>
      <!-- End Select -->
    {:else}
      {membership.role[0] + membership.role.substring(1).toLowerCase()}
    {/if}
  </td>
  {#if permissions.includes("change_organization_members")}
    <td>
      {#if membership.user_is_owner || membership.user.id == userId}
        <span
          class="btn btn-ghost text-muted"
          use:tooltip={{
            placement: "top",
            title: deleteTooltipDescription,
          }}
        >
          <i
            class={membership.user_is_owner || membership.user.id == userId
              ? "bi-lock"
              : "bi-trash"}
          /></span
        >
      {:else}
        <button
          class="btn btn-ghost"
          on:click={() => {
            dispatch("showDeleteMembershipModal", {
              membership: membership,
            });
          }}
          disabled={membership.user_is_owner || membership.user.id == userId}
          use:tooltip={{
            placement: "top",
            title: deleteTooltipDescription,
          }}
        >
          <i
            class={membership.user_is_owner || membership.user.id == userId
              ? "bi-lock"
              : "bi-trash"}
          />
        </button>
      {/if}
    </td>
  {/if}
</tr>
