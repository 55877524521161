<script>
  import { onMount } from "svelte";
  import axios from "axios";
  import { getCSRFToken } from "../../utils";
  import ProjectActions from "../project/ProjectActions.svelte";
  import ClientContactDropdown from "../ClientContactDropdown.svelte";
  import Popover from "svelte-easy-popover";
  import Loader from "../Loader.svelte";
  import Tasks from "../Tasks.svelte";
  import { fade } from "svelte/transition";
  import { getContext } from "svelte";

  let permissions = getContext("permissions");
  let orguserApiUrl = getContext("orguserApiUrl");
  let currentOrgId = getContext("currentOrgId");

  export let project = {};
  export let tasksApiUrl;
  export let templatesUrl;
  export let clientremindersApiUrl;
  export let userid;
  export let projectView;

  let tasksComponent;

  let loading = 1;
  let client = {};
  let contactsElement;
  let showContacts;
  let memberships;
  let error = "";

  $: if (project.key_contact == null) {
    project.key_contact = { id: null };
  }

  // EXPORT HANDLER TO CALL FETCH TASKS FROM PARENT
  export async function fetchTasksComponentHandler() {
    setTimeout(() => {
      if (tasksComponent && tasksComponent.fetchTasks) {
        tasksComponent.fetchTasks();
      }
    }, 0);
  }

  export async function fetchProject() {
    const response = await fetch(`${project.url}`);

    if (response.ok) {
      const result = await response.json();
      project = result;
    } else {
      error = "Failed to load project details.";
    }
    fetchClient();
  }

  export async function fetchClient() {
    const response = await fetch(`${project.client}`);

    if (response.ok) {
      const result = await response.json();
      client = result;
    } else {
      error = "Failed to load project details.";
    }
  }

  async function fetchMemberships() {
    const response = await fetch(
      `${orguserApiUrl + "?organization=" + currentOrgId}`
    );

    if (response.ok) {
      const result = await response.json();
      memberships = result.results;
    } else {
      error = "Failed to load project details.";
    }
  }

  onMount(async () => {
    await fetchClient();
    await fetchMemberships();
    --loading;
  });
</script>

<!-- Card -->
<li class="card card-bordered shadow-none mb-3">
  <div class="card-body p-3 p-md-5">
    {#if loading > 0}
      <div in:fade class="d-flex justify-content-center">
        <Loader />
      </div>
    {:else}
      <div class="d-flex d-flex-row justify-content-between">
        <div class="">
          <h5>
            <a href={project.view_project_url}>{project.name}</a>
          </h5>
          <ClientContactDropdown
            {memberships}
            {client}
            {project}
            on:fetchTasks={() => {
              tasksComponent.fetchTasks();
            }}
          />
        </div>
        <div class="ms-1">
          {#if permissions.includes("change_projects")}
            <ProjectActions
              bind:project
              {client}
              {templatesUrl}
              {projectView}
              exclude={{
                show_completed_tasks: true,
                archive_template: true,
                edit_template_details: true,
              }}
            />
          {/if}
        </div>
      </div>
      <hr />
      <div>
        <div class="progress" style="height: 15px;">
          <div
            class="progress-bar bg-primary"
            role="progressbar"
            aria-valuenow={project.percent_complete}
            aria-valuemin="0"
            aria-valuemax="100"
            style="width:{project.percent_complete}%"
          />
        </div>
        <div>
          <span class="small text-right"
            >{project.percent_complete}% of Tasks Complete</span
          >
        </div>
      </div>
      <hr />
      <div class="row">
        <small class="text-secondary fw-semi-bold mb-2">Up Next</small>
        <!-- End Col -->
        <Tasks
          bind:this={tasksComponent}
          projectsview={true}
          projectid={project.id}
          {memberships}
          {tasksApiUrl}
          {clientremindersApiUrl}
          {userid}
          {project}
          on:fetchProject={() => {
            fetchProject();
          }}
        />
        <small class="mt-3 text-center">
          <a href={project.view_project_url}>View all project tasks</a>
        </small>
        <!-- End Col -->
      </div>
      <!-- End Row -->
    {/if}
  </div>
</li>

<!-- End Card -->
