<script>
  export let task = null;
  export let hovering = false;
  export let projectIsTemplate = null;
  export let hidden = false;
  export let draggable = false;
  export let alltaskview = null;
  export let projectsview = null;
  export let project = {};
  export let memberships;
  export let client = {};
  export let showcompletetasks;
  export let clientremindersApiUrl;

  let innerDraggable = true;
  let keyCode = null;
  let editable = false;
  let mobileEditable = false;
  let lastSavedTaskName = task.name;
  let taskMessage = "";
  let assignMessage = "";
  let taskDeliverableLink = "";
  let cleanedTaskDeliverableLink = "";
  let quillDetail = "";
  let taskName = task.name ?? "";
  let form;
  let errors = {};
  let projectNameAbbreviated = "";

  import ClientReminders from "./reminders/ClientReminders.svelte";
  import dayjs from "dayjs";
  import relativeTime from "dayjs/plugin/relativeTime";
  import { createEventDispatcher, onMount } from "svelte";
  import { clickOutside } from "./clickOutside.js";
  import SveltyPicker from "./picker/SveltyPicker.svelte";
  import { fade, slide } from "svelte/transition";
  import {
    deliverableSchema,
    detailSchema,
    assignMessageSchema,
  } from "../schema";

  import Popover from "svelte-easy-popover";
  import IsCompletePopover from "./IsCompletePopover.svelte";
  import IsEditingDurationPopover from "./IsEditingDurationPopover.svelte";
  import { stopTyping } from "./onStopTyping";
  import { getCSRFToken } from "../utils";
  import { assign } from "lodash";
  import { tooltip, quill, autoResize } from "./bootstrapActivate";

  import { getContext } from "svelte";

  const permissions = getContext("permissions");

  dayjs.extend(relativeTime);

  export let day = dayjs();
  export let isCompletingTask = false;

  export let fetchClientReminders = null;

  let referenceElementDeadline;
  let referenceElementDeadlineMobile;
  let referenceElementComplete;
  let referenceElementEmptyTaskNameWarning;
  let deliverableEl;
  let membersEl;
  let assignEl;
  let isEditingDate = false;
  let isEditingDateMobile = false;
  let isShowingAssignmentPopover = false;
  let canReSendAssignment;
  let canReSendReminder;
  let isShowingCompletePopover = false;
  let isShowingCompleteAssignedToClient = false;
  let isShowingEmptyTaskNameWarning = false;
  let isShowingDeleteConfirmation = false;
  let isShowingMembers = false;
  let isShowingDetails = false;
  let taskDeadline = task.deadline;
  let messageLength;
  let assignMessageLength;
  let detailLength = task.detail ? task.detail.length : 0;
  let showCC = false;
  let taskToggleTooltipDescription;
  let isEditingTaskDetail = false;

  $: messageLength = taskMessage.length;
  $: canReSendAssignment =
    dayjs(task.last_assignment_notification_sent_at) < day.subtract(1, "day");
  $: canReSendReminder =
    dayjs(task.last_reminder_sent_at) < day.subtract(1, "day") ||
    !task.last_reminder_sent_at;
  $: assignMessageLength = assignMessage.length;
  $: projectNameAbbreviated = task.project.name.substring(0, 19) + "...";
  $: if (task.project.key_contact && task.project.contacts.length > 1) {
    showCC = true;
  }
  $: taskToggleTooltipDescription = getTaskToggleStatus(task);

  $: taskName = task.name;

  const extractErrors = ({ inner }) => {
    return inner.reduce((acc, err) => {
      return { ...acc, [err.path]: err.message };
    }, {});
  };

  const dispatch = createEventDispatcher();

  const enableEditing = (mobile) => {
    lastSavedTaskName = task.name;
    if (mobile) {
      mobileEditable = true;
    } else {
      editable = true;
    }
  };

  const disableEditing = (mobile) => {
    if (mobile) {
      mobileEditable = false;
    } else {
      editable = false;
    }
  };

  function getTaskToggleStatus(task) {
    if (task.is_complete) {
      return "Task is complete.";
    } else if (task.is_deliverable) {
      return "Task marked as deliverable. Will send to client.";
    } else if (task.is_assigned_to_client) {
      return "Task is assigned to client.";
    } else if (task.should_notify_client) {
      return "Task will notify client upon completion.";
    } else if (task.is_hidden_from_client) {
      return "Task is hidden from client.";
    } else {
      return "";
    }
  }

  const clickComplete = () => {
    isCompletingTask = true;
    if (
      task.should_notify_client ||
      task.is_assigned_to_client ||
      task.should_notify_client ||
      task.is_deliverable
    ) {
      isShowingCompletePopover = true;
    } else {
      dispatch("clickComplete");
    }
  };

  const clickCompleteNotify = () => {
    isCompletingTask = true;
    form = {
      taskMessage: taskMessage,
    };
    if (!task.is_deliverable) {
      return deliverableSchema
        .validate(form, { abortEarly: false })
        .then(() => {
          // clear the errors
          errors = {};
          dispatch("clickComplete", {
            message: taskMessage,
            deliverable_link: taskDeliverableLink,
          });
          // clear task info
          taskMessage = "";
          taskDeliverableLink = "";
          // set add message to false
          isShowingCompletePopover = false;
        })
        .catch((err) => {
          errors = extractErrors(err);
          console.log(errors);
        });
    } else {
      if (
        !taskDeliverableLink.includes("http://") &&
        !taskDeliverableLink.includes("https://")
      ) {
        cleanedTaskDeliverableLink = `http://${taskDeliverableLink}`;
      } else {
        cleanedTaskDeliverableLink = taskDeliverableLink;
      }
      form = {
        ...form,
        taskDeliverableLink: cleanedTaskDeliverableLink,
      };
      return deliverableSchema
        .validate(form, { abortEarly: false })
        .then(() => {
          // clear the errors
          errors = {};
          dispatch("clickComplete", {
            message: taskMessage,
            deliverable_link: cleanedTaskDeliverableLink,
          });
          // clear task info
          taskMessage = "";
          taskDeliverableLink = "";
          // set add message to false
          isShowingCompletePopover = false;
        })
        .catch((err) => {
          errors = extractErrors(err);
          console.log(errors);
        });
    }
  };

  const clickCompleteNotifyOff = () => {
    isCompletingTask = true;
    dispatch("clickCompleteNotifyOff");
  };

  const fetchTasks = () => {
    dispatch("fetchTasks");
  };

  const clickDeliverable = () => {
    task.is_deliverable = !task.is_deliverable;
    if (task.is_deliverable) {
      task.should_notify_client = true;
      task.is_hidden_from_client = false;
    }
    dispatch("clickDeliverable");
  };

  const clickNotifyClient = () => {
    task.should_notify_client = !task.should_notify_client;
    if (!task.should_notify_client) {
      task.is_assigned_to_client = false;
      task.is_deliverable = false;
    } else {
      task.is_hidden_from_client = false;
    }
    dispatch("clickNotifyClient");
  };

  async function clickToggleIsAssignedToClient(send = false) {
    task.is_assigned_to_client = !task.is_assigned_to_client;
    if (task.is_assigned_to_client) {
      task.should_notify_client = true;
      task.is_hidden_from_client = false;
    }
    if (assignMessage && send) {
      form = {
        assignMessage: assignMessage,
      };
      return assignMessageSchema
        .validate(form, { abortEarly: false })
        .then(() => {
          // clear the errors
          errors = {};
          dispatch("clickToggleIsAssignedToClient", {
            message: assignMessage,
            should_notify_client_of_assignment: send,
          });
          // set assign message to false
          assignMessage = "";
        })
        .catch((err) => {
          errors = extractErrors(err);
          console.log(errors);
        });
    } else {
      dispatch("clickToggleIsAssignedToClient", {
        should_notify_client_of_assignment: send,
      });
    }
  }

  async function clickSendNotificationAssignedToClient() {
    if (assignMessage) {
      form = {
        assignMessage: assignMessage,
      };
      return assignMessageSchema
        .validate(form, { abortEarly: false })
        .then(() => {
          // clear the errors
          errors = {};
          dispatch("clickSendNotificationAssignedToClient", {
            message: assignMessage,
          });
          // set assign message to false
          assignMessage = "";
        })
        .catch((err) => {
          errors = extractErrors(err);
          console.log(errors);
        });
    } else {
      dispatch("clickSendNotificationAssignedToClient");
    }
  }

  async function clickSendReminderAssignedToClient() {
    dispatch("clickSendReminderAssignedToClient");
  }

  const clickTaskName = (mobile = false) => {
    enableEditing(mobile);
  };

  const clickToggleClientVisibility = () => {
    task.is_hidden_from_client = !task.is_hidden_from_client;
    if (task.is_hidden_from_client) {
      task.is_assigned_to_client = false;
      task.should_notify_client = false;
      task.is_deliverable = false;
    }
    dispatch("clickToggleClientVisibility");
  };

  const clickSetTaskEstimatedDuration = () => {
    dispatch("clickSetTaskEstimatedDuration");
  };

  const hitReturnKey = (event, mobile = false) => {
    keyCode = event.keyCode;
    if (
      (keyCode == 13 && task.name == "" && task.detail == "") ||
      (keyCode == 9 && task.name == "" && task.detail == "") ||
      (keyCode === "Enter" && task.name == "" && task.detail == "")
    ) {
      event.preventDefault();
      dispatch("deleteTask");
      disableEditing(mobile);
      event.target.blur();
    } else if (
      (keyCode == 13 && task.name != "") ||
      (keyCode == 9 && task.name != "")
    ) {
      event.preventDefault();
      dispatch("hitReturnKey", task.name);
      disableEditing(mobile);
      event.target.blur();
    } else if (keyCode == 13 || keyCode == 9) {
      event.preventDefault();
      isShowingDetails = true;
      isShowingEmptyTaskNameWarning = true;
    }
  };

  const clickOutsideTask = (mobile = false) => {
    if (isShowingEmptyTaskNameWarning == false) {
      if (task.name == "" && task.detail == "") {
        dispatch("deleteTask");
        disableEditing(mobile);
      } else if (task.name != "") {
        dispatch("clickOutsideTask", task.name);
        disableEditing(mobile);
      } else {
        isShowingDetails = true;
        isShowingEmptyTaskNameWarning = true;
      }
    }
  };

  const changeDeadline = () => {
    task.deadline = taskDeadline;
    dispatch("changeDeadline", taskDeadline);
  };

  const updateTaskDetail = (quillDetail, isEditingTaskDetail) => {
    form = { taskDetail: quillDetail };
    return detailSchema
      .validate(form, { abortEarly: false })
      .then(() => {
        // clear the errors
        errors = {};
        dispatch("updateTaskDetail", {
          quillDetail: quillDetail,
          isEditingTaskDetail: isEditingTaskDetail,
        });
      })
      .catch((err) => {
        errors = extractErrors(err);
        console.log(errors);
      });
  };

  const mouseDown = (event) => {
    dispatch("mouseDownHandle", event.target);
  };
</script>

{#if task}
  {#if hidden}
    <tr data-testid="task" class="bg-primary"
      ><td colspan="6" class="p-0" style="height:0.1rem" />
    </tr>
  {:else if !(!showcompletetasks && task.is_complete)}
    <tr
      class="task"
      data-testid="task"
      class:is_hidden_from_client={task.is_hidden_from_client}
      class:is-assigned-to-client={task.is_assigned_to_client}
      class:complete={task.is_complete}
      class:hover={hovering}
      class:draggable
      class:hidden
      draggable={innerDraggable && draggable}
      on:drop
      on:dragover
      on:dragstart
      on:dragend
      transition:fade={{ intro: true }}
      data-order-number={task.order_number}
      data-created-at={task.created_at}
    >
      {#if !alltaskview && !projectsview && permissions.includes("change_tasks")}
        <td
          style="width: 2%"
          class="handle"
          on:mousedown={(event) => {
            if (task.url) {
              mouseDown(event);
            }
          }}><i class="bi bi-grip-vertical text-secondary" /></td
        >
      {/if}
      {#if !projectIsTemplate}
        <td
          style="width:2%"
          class={projectsview ||
          alltaskview ||
          !permissions.includes("change_tasks")
            ? "pe-0"
            : "px-0"}
        >
          <button
            data-testid="completeButton"
            on:click={clickComplete}
            bind:this={referenceElementComplete}
            disabled={!task.url}
            type="button"
            class="btn {task.is_complete
              ? 'btn-success'
              : task.is_assigned_to_client
              ? 'btn-soft-secondary'
              : 'btn-white'} btn-icon rounded-pill btn-xs complete-task"
            >{#if !task.url}
              <div class="spinner-grow spinner-grow-sm" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>{:else if isCompletingTask}<div
                class="spinner-border spinner-border-sm"
                role="status"
              >
                <span class="visually-hidden">Loading...</span>
              </div>{:else}
              <i class="bi-check2" />{/if}</button
          >
          <IsCompletePopover
            bind:isCompletingTask
            bind:isShowingCompletePopover
            {errors}
            {client}
            bind:taskMessage
            bind:taskDeliverableLink
            {showCC}
            {messageLength}
            {task}
            {isShowingCompleteAssignedToClient}
            {referenceElementComplete}
            {canReSendReminder}
            on:clickCompleteNotify={clickCompleteNotify}
            on:clickDeliverable={clickDeliverable}
            on:clickCompleteNotifyOff={clickCompleteNotifyOff}
            on:clickSendReminderAssignedToClient={clickSendReminderAssignedToClient}
          />
        </td>
      {/if}
      <td class="pe-0 px-md-2">
        <input
          type="text"
          data-testid="taskName"
          id="taskName-{task.id}"
          class="task-name form-control-sm mx-0 px-2 {task.is_complete
            ? 'text-decoration-line-through text-success'
            : ''}"
          disabled={!task.url}
          class:editable
          bind:this={referenceElementEmptyTaskNameWarning}
          on:dragstart={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          on:click={() => {
            if (permissions.includes("change_tasks")) {
              // False is in reference to Mobile task
              clickTaskName(false);
            }
          }}
          on:keydown={(event) => {
            // False is in reference to Mobile task
            hitReturnKey(event, false);
          }}
          use:clickOutside
          on:click_outside={() => {
            if (editable) {
              // False is in reference to Mobile task
              clickOutsideTask(false);
            }
          }}
          bind:value={task.name}
          readonly={!permissions.includes("change_tasks")}
          maxlength="150"
        />
        <Popover
          triggerEvents={["key"]}
          referenceElement={referenceElementEmptyTaskNameWarning}
          placement="bottom-start"
          spaceAway={10}
          bind:isOpen={isShowingEmptyTaskNameWarning}
        >
          {#if isShowingEmptyTaskNameWarning}
            <div
              class="popover-contents"
              transition:fade={{ duration: 250 }}
              use:clickOutside
              on:click_outside={() => {
                if (isShowingEmptyTaskNameWarning) {
                  task.name = lastSavedTaskName;
                  isShowingEmptyTaskNameWarning = false;
                }
              }}
            >
              <div class="card p-3 task-message warning is-popup">
                <div class="row p-2">
                  <h5>Are you sure you want to delete this task?</h5>
                </div>
                <div class="row">
                  <div class="col ps-3 pe-3 text-left">
                    <p>You'll lose your task details if you delete the task.</p>
                  </div>
                </div>
                <div class="d-flex justify-content-end">
                  <button
                    on:click={() => {
                      disableEditing(false);
                      isShowingDetails = false;
                      isShowingEmptyTaskNameWarning = false;
                      dispatch("deleteTask");
                    }}
                    type="submit"
                    class="btn btn-danger btn-fill btn-sm">Delete Task</button
                  >
                  <button
                    on:click={() => {
                      task.name = lastSavedTaskName;
                      isShowingEmptyTaskNameWarning = false;
                    }}
                    type="submit"
                    class="ms-1 btn btn-white btn-sm">Nevermind</button
                  >
                </div>
              </div>
            </div>
          {/if}
          <!-- If  -->
        </Popover>
      </td>
      {#if alltaskview}
        <td class="text-center d-none d-md-table-cell">
          {#if projectNameAbbreviated}
            <a
              href={`${task.project_url}`}
              class="btn btn-soft-secondary btn-xs rounded-pill"
              disabled={!task.url}
            >
              {projectNameAbbreviated}</a
            >
          {/if}
        </td>
      {/if}<td style="width:2%">
        {#if permissions.includes("assign_tasks")}
          <button
            bind:this={membersEl}
            class="btn btn-xs btn-icon btn-white rounded-pill {task.user &&
            !task.user.profile_image
              ? 'avatar-xs avatar-primary avatar-circle border-0'
              : ''}"
            on:click={() => {
              isShowingMembers = true;
            }}
            disabled={!task.url}
          >
            {#if task.user}
              {#if task.user.profile_image}
                <img
                  class="avatar-img avatar-circle"
                  src={task.user.profile_image}
                  alt="{task.user.first_name +
                    ' ' +
                    task.user.last_name} Profile Image"
                />
              {:else}
                <span class="avatar-initials bg-soft-primary text-primary"
                  >{task.user.first_name[0] + task.user.last_name[0]}</span
                >
              {/if}
            {:else}
              <i class="bi bi-person-add" />
            {/if}
          </button>
          <Popover
            triggerEvents={["click"]}
            referenceElement={membersEl}
            placement="left-start"
            spaceAway={10}
            bind:isOpen={isShowingMembers}
          >
            {#if isShowingMembers}
              <div
                class="card p-2"
                style="text-transform: none;"
                use:clickOutside
                on:click_outside={() => {
                  isShowingMembers = false;
                }}
              >
                <ul class="list-unstyled small mb-0">
                  {#if task.user}
                    <span class="card-subtitle divider-start p-1"
                      >Assigned To</span
                    >
                    <span class="dropdown-item d-flex align-items-center"
                      >{#if task.user.profile_image}<img
                          class="avatar-img avatar-xs avatar-circle"
                          src={task.user.profile_image}
                          alt="{task.user.first_name +
                            ' ' +
                            task.user.last_name} Profile Image"
                        />
                      {:else}
                        <span
                          class="avatar avatar-xs avatar-primary avatar-circle"
                          ><span class="avatar-initials"
                            >{task.user.first_name[0] +
                              task.user.last_name[0]}</span
                          ></span
                        >
                      {/if}
                      <div class="mx-2">
                        <strong class=""
                          >{task.user.first_name + " " + task.user.last_name}
                        </strong>
                      </div>
                      <button
                        class="ms-auto btn btn-xs btn-icon rounded-pill btn-soft-danger"
                        on:click={() => {
                          task.user = null;
                          dispatch("updateTaskUser");
                        }}
                        ><i class="bi bi-dash-circle" />
                      </button>
                    </span>
                  {/if}
                  <span class="card-subtitle divider-start p-1"
                    >Organization Members</span
                  >
                  {#each memberships as { user }}
                    {#if !task.user || task.user.id != user.id}
                      <div class="dropdown-item d-flex align-items-center">
                        {#if user.profile_image}<img
                            class="avatar-img avatar-xs avatar-circle"
                            src={user.profile_image}
                            alt="{user.first_name +
                              ' ' +
                              user.last_name} Profile Image"
                          />
                        {:else}
                          <span
                            class="avatar avatar-xs avatar-primary avatar-circle"
                            ><span class="avatar-initials"
                              >{user.first_name[0] + user.last_name[0]}</span
                            ></span
                          >
                        {/if}
                        <div class="mx-2">
                          <strong class="text-primary"
                            >{user.first_name + " " + user.last_name}
                          </strong><br />
                          {#if alltaskview}
                            {#if task.project.users.some((projectUser) => projectUser.id === user.id)}
                              <div class="small">
                                <em>Project Team Member</em>
                              </div>
                            {/if}
                          {:else if project.users.some((projectUser) => projectUser.id === user.id)}
                            <div class="small">
                              <em>Project Team Member</em>
                            </div>
                          {/if}
                        </div>
                        <button
                          class="ms-auto btn btn-xs btn-icon rounded-pill btn-white"
                          on:click={() => {
                            task.user = { ...user };
                            dispatch("updateTaskUser");
                          }}
                          ><i class="bi bi-plus-circle" />
                        </button>
                      </div>
                    {/if}
                  {/each}
                </ul>
              </div>
            {/if}
          </Popover>
        {:else if task.user}
          {#if task.user.profile_image}
            <span class="avatar avatar-xs avatar-primary avatar-circle">
              <img
                class="avatar-img avatar-circle"
                src={task.user.profile_image}
                alt="{task.user.first_name +
                  ' ' +
                  task.user.last_name} Profile Image"
              />
            </span>
          {:else}
            <span class="avatar avatar-xs avatar-primary avatar-circle"
              ><span class="avatar-initials bg-soft-primary text-primary"
                >{task.user.first_name[0] + task.user.last_name[0]}</span
              ></span
            >
          {/if}
        {/if}
      </td>
      <td style="width: 5%" class="text-center d-none d-md-table-cell">
        {#if projectIsTemplate}
          <!-- Duration -->
          {#if permissions.includes("change_tasks")}
            <button
              data-testid="durationButton"
              disabled={!task.url}
              class="btn rounded-pill btn-xs deadline-btn
            {task.duration_in_days > 0
                ? 'btn-soft-secondary'
                : 'btn-white btn-icon'}"
              bind:this={referenceElementDeadline}
              on:click={() => {
                isEditingDate = !isEditingDate;
              }}
            >
              {#if task.duration_in_days}
                {task.duration_in_days} Day{#if task.duration_in_days > 1}s{/if}
              {:else}
                <i class="bi-hourglass-split" />
              {/if}
            </button>
            <IsEditingDurationPopover
              bind:task
              {referenceElementDeadline}
              {isEditingDate}
              on:clickSetTaskEstimatedDuration={(event) => {
                clickSetTaskEstimatedDuration(event, task);
              }}
            />
          {:else}
            <span class="btn rounded-pill btn-xs deadline-btn pe-none"
              >{#if task.duration_in_days}
                {task.duration_in_days} Day{#if task.duration_in_days > 1}s{/if}{/if}</span
            >
          {/if}
        {:else if permissions.includes("change_tasks")}
          <!-- Deadline -->
          <button
            data-testid="deadlineButton"
            disabled={!task.url}
            class="btn rounded-pill btn-xs deadline-btn
            {task.is_complete && task.deadline
              ? 'btn-soft-success'
              : task.is_complete
              ? 'btn-outline-success btn-icon'
              : task.is_past_due
              ? 'btn-soft-danger'
              : task.is_due_soon
              ? 'btn-soft-warning text-soft-warning'
              : task.deadline
              ? 'btn-soft-primary'
              : 'btn-white btn-icon'}"
            bind:this={referenceElementDeadline}
            on:click={() => {
              isEditingDate = !isEditingDate;
            }}
          >
            {#if task.deadline}
              {dayjs(task.deadline).format("ddd, MMM D")}
            {:else}
              <i class="bi-calendar-event" />
            {/if}
          </button>
          <Popover
            triggerEvents={["click"]}
            referenceElement={referenceElementDeadline}
            placement="bottom-end"
            spaceAway={10}
            bind:isOpen={isEditingDate}
          >
            {#if isEditingDate}
              <div
                class="popover-contents"
                transition:fade={{ duration: 250 }}
                use:clickOutside
                on:click_outside={() => {
                  isEditingDate = false;
                }}
              >
                <SveltyPicker
                  inputClasses=""
                  pickerOnly={true}
                  bind:value={taskDeadline}
                  on:change={changeDeadline}
                  isOpen={isEditingDate}
                />
              </div>
            {/if}
          </Popover>
        {:else}
          <span
            class="btn rounded-pill btn-xs deadline-btn pe-none
            {task.is_complete && task.deadline
              ? 'bg-soft-success text-success'
              : task.is_past_due
              ? 'bg-soft-danger text-danger'
              : task.is_due_soon
              ? 'bg-soft-warning text-warning'
              : task.deadline
              ? 'bg-soft-primary text-primary'
              : ''}"
            >{#if task.deadline}{dayjs(task.deadline).format(
                "ddd, MMM D"
              )}{/if}</span
          >
        {/if}
      </td>
      <!-- Task Details -->
      <td style="width: 5%" class="text-center"
        ><button
          disabled={!task.url}
          use:tooltip={{
            placement: "top",
            title: taskToggleTooltipDescription,
          }}
          on:click={() => {
            isShowingDetails = !isShowingDetails;
          }}
          class="btn btn-rounded rounded-pill btn-xs {(task.is_complete &&
            task.is_deliverable) ||
          (task.is_complete && task.should_notify_client) ||
          (task.is_complete && task.is_hidden_from_client)
            ? 'btn-soft-success'
            : task.is_complete
            ? 'btn-outline-success btn-icon'
            : task.is_deliverable ||
              task.should_notify_client ||
              task.is_assigned_to_client
            ? 'btn-soft-primary'
            : task.is_hidden_from_client
            ? 'btn-soft-secondary'
            : 'btn-white btn-icon'}"
          >{#if task.is_deliverable}
            <i class="bi-truck me-1" />
          {:else if task.is_assigned_to_client}
            <i class="bi-person-check me-1" />
          {:else if task.should_notify_client}
            <i class="bi-send me-1" />
          {:else if task.is_hidden_from_client}
            <i class="bi-eye-slash me-1" />
          {/if}
          <i
            class="bi {isShowingDetails ? 'bi-chevron-up' : 'bi-chevron-down'}"
          /></button
        >
      </td>
    </tr>
    {#if isShowingDetails}
      <!-- Task Details -->
      <tr class="task-details">
        {#if permissions.includes("change_tasks")}
          <td class="d-none d-md-table-cell p-0" />
        {/if}
        <td colspan="11" class="p-0">
          <div transition:slide|global={{ intro: true }}>
            <div class="p-3">
              {#if !projectIsTemplate && permissions.includes("change_tasks")}
                <!-- Nav -->
                <div class="text-center">
                  <ul class="nav nav-segment nav-pills" role="tablist">
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        id="nav-one-eg1-tab"
                        href="#nav-one-eg1"
                        data-bs-toggle="pill"
                        data-bs-target="#nav-one-eg1-{task.id}"
                        role="tab"
                        aria-controls="nav-one-eg1"
                        aria-selected="true">General</a
                      >
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link {!task.is_assigned_to_client
                          ? 'text-muted'
                          : ''}"
                        id="nav-two-eg1-tab"
                        href="#nav-two-eg1"
                        data-bs-toggle="pill"
                        data-bs-target="#nav-two-eg1-{task.id}"
                        role="tab"
                        aria-controls="nav-two-eg1"
                        aria-selected="false"
                        disabled={!task.is_assigned_to_client}
                        title={!task.is_assigned_to_client
                          ? "Assign task to client to enable reminders."
                          : ""}
                      >
                        {#if !task.is_assigned_to_client}
                          <i class="bi bi-lock" />
                          Reminders
                        {:else}
                          Reminders
                        {/if}</a
                      >
                    </li>
                  </ul>
                </div>
                <!-- End Nav -->
              {/if}

              <!-- Tab Content -->
              <div class="tab-content  mt-2">
                <div
                  class="tab-pane fade show active"
                  id="nav-one-eg1-{task.id}"
                  role="tabpanel"
                  aria-labelledby="nav-one-eg1-tab"
                >
                  <!-- Mobile Task Styling -->
                  <div class="d-block d-md-none">
                    <textarea
                      rows="1"
                      data-testid="taskNameMobile"
                      id="taskNameMobile-{task.id}"
                      class="form-control task-name fw-bold mx-0 no-scroll {mobileEditable
                        ? 'editable'
                        : ''}"
                      on:dragstart={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                      use:autoResize={taskName}
                      on:click={() => {
                        if (permissions.includes("change_tasks")) {
                          // True is in reference to Mobile task
                          clickTaskName(true);
                        }
                      }}
                      on:keydown={(event) => {
                        // True is in reference to Mobile task
                        hitReturnKey(event, true);
                      }}
                      on:blur={() => {
                        if (mobileEditable) {
                          // True is in reference to Mobile task
                          clickOutsideTask(true);
                        }
                      }}
                      use:clickOutside
                      on:click_outside={() => {
                        if (mobileEditable) {
                          // True is in reference to Mobile task
                          clickOutsideTask(true);
                        }
                      }}
                      bind:value={task.name}
                      maxlength="150"
                      readonly={!permissions.includes("change_tasks")}
                      style="resize: none;"
                    />
                    {#if alltaskview}
                      <div class="col d-md-none mt-2 text-end small">
                        <label
                          class="form-label mb-0"
                          for="taskProject-{task.id}"
                          >Projects /
                        </label>
                        <em
                          ><a href={`${task.project_url}`} disabled={!task.url}>
                            {task.project.name}</a
                          ></em
                        >
                      </div>
                    {/if}
                  </div>
                  <hr class="d-block d-md-none mt-2" />
                  <div class="col d-grid d-md-none mb-3">
                    <label
                      class="form-label "
                      for="taskDeadlineDuration-{task.id}"
                      ><span class="d-inline "
                        >Task Deadline {#if !permissions.includes("change_tasks")}<strong
                            >(Read Only)</strong
                          >{/if}</span
                      >
                    </label>
                    {#if projectIsTemplate}
                      <!-- Mobile Duration -->
                      <button
                        id="taskDeadlineDuration-{task.id}"
                        class="btn btn-sm
            {task.duration_in_days > 0 ? 'btn-soft-secondary' : 'btn-white'}"
                        bind:this={referenceElementDeadlineMobile}
                        on:click={() => {
                          isEditingDate = !isEditingDate;
                        }}
                        disabled={!permissions.includes("change_tasks")}
                      >
                        <i class="bi-hourglass-split" />
                        {#if task.duration_in_days}
                          {task.duration_in_days} Day{#if task.duration_in_days > 1}s{/if}
                        {:else}
                          Set Task Duration
                        {/if}
                      </button>
                      <IsEditingDurationPopover
                        bind:task
                        {referenceElementDeadlineMobile}
                        {isEditingDate}
                        on:clickSetTaskEstimatedDuration={(event) => {
                          clickSetTaskEstimatedDuration(event, task);
                        }}
                      />
                    {:else}
                      <!-- Mobile Deadline -->
                      <button
                        id="taskDeadlineDuration-{task.id}"
                        class="btn btn-rounded btn-sm {task.is_past_due
                          ? 'btn-soft-danger'
                          : ''}
              {task.is_due_soon ? 'btn-soft-warning text-soft-warning' : ''}
              {task.deadline ? 'btn-soft-primary' : 'btn-white'}"
                        bind:this={referenceElementDeadlineMobile}
                        on:click={() => {
                          isEditingDateMobile = !isEditingDateMobile;
                        }}
                        disabled={!permissions.includes("change_tasks")}
                      >
                        <i class="bi-calendar-event me-1" />
                        {#if task.deadline}
                          {dayjs(task.deadline).format("ddd, MMM D")}
                        {:else}
                          Deadline
                        {/if}
                      </button>

                      <Popover
                        triggerEvents={["click"]}
                        referenceElement={referenceElementDeadlineMobile}
                        placement="bottom-end"
                        spaceAway={10}
                        bind:isOpen={isEditingDateMobile}
                      >
                        {#if isEditingDateMobile}
                          <div
                            class="popover-contents"
                            transition:fade={{ duration: 250 }}
                            use:clickOutside
                            on:click_outside={() => {
                              isEditingDateMobile = false;
                            }}
                          >
                            <SveltyPicker
                              inputClasses=""
                              pickerOnly={true}
                              bind:value={taskDeadline}
                              on:change={changeDeadline}
                              isOpen={isEditingDateMobile}
                            />
                          </div>
                        {/if}
                      </Popover>
                    {/if}
                  </div>
                  <div class="row">
                    <div class="col">
                      <label
                        class="form-label {task.is_hidden_from_client
                          ? 'text-secondary'
                          : ''}"
                        for="taskDescription-{task.id}"
                      >
                        Public Description {#if !permissions.includes("change_tasks")}<strong
                            >(Read Only)</strong
                          >{/if}

                        {#if task.is_hidden_from_client}
                          <i class="ms-1 bi-eye-slash" />
                          <em>Task is Hidden</em>{/if}</label
                      >
                      <div
                        class="quill-custom {task.is_hidden_from_client
                          ? 'disabled'
                          : ''}"
                      >
                        <div
                          id="taskDescription-{task.id}"
                          style="height:200px"
                          use:quill={{
                            readOnly: !permissions.includes("change_tasks"),
                            placeholder: task.is_hidden_from_client
                              ? "Not visible on client review page"
                              : "Visible on client review page",
                            modules: {
                              toolbar: !permissions.includes("change_tasks")
                                ? null
                                : [
                                    [
                                      "bold",
                                      "italic",
                                      "underline",
                                      "strike",
                                      "link",
                                      { list: "bullet" },
                                    ],
                                  ],
                            },
                            initialContent: task.detail,
                          }}
                          on:text-change={(e) => {
                            if (permissions.includes("change_tasks")) {
                              detailLength = e.detail.html.length;
                              quillDetail = e.detail.html;
                              updateTaskDetail(
                                quillDetail,
                                isEditingTaskDetail
                              );
                            }
                          }}
                          on:focus={() => {
                            if (permissions.includes("change_tasks")) {
                              isEditingTaskDetail = true;
                            }
                          }}
                          on:click_outside={(e) => {
                            if (
                              permissions.includes("change_tasks") &&
                              isEditingTaskDetail
                            ) {
                              isEditingTaskDetail = false;
                              quillDetail = e.detail.html;
                              task.detail = quillDetail;
                              updateTaskDetail(
                                quillDetail,
                                isEditingTaskDetail
                              );
                            }
                          }}
                          disabled={!permissions.includes("change_tasks")}
                        />
                      </div>

                      <small
                        class="d-block text-end {detailLength >= 1500
                          ? 'text-danger'
                          : ''}">{detailLength}/1500 characters</small
                      >
                      {#if errors.taskDetail}
                        <small class="d-block text-danger text-end">
                          {errors.taskDetail}
                        </small>
                      {/if}
                    </div>
                    <div class="col-12 col-md">
                      <div class="form-label">
                        Task Options {#if !permissions.includes("change_tasks")}<strong
                            >(Read Only)</strong
                          >{/if}
                      </div>
                      <div class="list-group">
                        <!-- Item -->
                        <div
                          class="list-group-item {task.is_complete
                            ? 'bg-soft-secondary'
                            : ''}"
                        >
                          <div class="d-flex">
                            <div class="flex-shrink-0">
                              <span
                                class="svg-icon svg-icon-sm {task.should_notify_client
                                  ? 'text-primary'
                                  : 'text-muted'}"
                              >
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M15.43 8.56949L10.744 15.1395C10.6422 15.282 10.5804 15.4492 10.5651 15.6236C10.5498 15.7981 10.5815 15.9734 10.657 16.1315L13.194 21.4425C13.2737 21.6097 13.3991 21.751 13.5557 21.8499C13.7123 21.9488 13.8938 22.0014 14.079 22.0015H14.117C14.3087 21.9941 14.4941 21.9307 14.6502 21.8191C14.8062 21.7075 14.9261 21.5526 14.995 21.3735L21.933 3.33649C22.0011 3.15918 22.0164 2.96594 21.977 2.78013C21.9376 2.59432 21.8452 2.4239 21.711 2.28949L15.43 8.56949Z"
                                    fill="#035A4B"
                                  />
                                  <path
                                    opacity="0.3"
                                    d="M20.664 2.06648L2.62602 9.00148C2.44768 9.07085 2.29348 9.19082 2.1824 9.34663C2.07131 9.50244 2.00818 9.68731 2.00074 9.87853C1.99331 10.0697 2.04189 10.259 2.14054 10.4229C2.23919 10.5869 2.38359 10.7185 2.55601 10.8015L7.86601 13.3365C8.02383 13.4126 8.19925 13.4448 8.37382 13.4297C8.54839 13.4145 8.71565 13.3526 8.85801 13.2505L15.43 8.56548L21.711 2.28448C21.5762 2.15096 21.4055 2.05932 21.2198 2.02064C21.034 1.98196 20.8409 1.99788 20.664 2.06648V2.06648Z"
                                    fill="#035A4B"
                                  />
                                </svg>
                              </span>
                            </div>
                            <div class="flex-grow-1 ms-3">
                              <div class="row align-items-center">
                                <label
                                  class="col form-label mb-0"
                                  for="toggle-1-{task.id}"
                                >
                                  <h6
                                    class="mb-1 {task.should_notify_client
                                      ? ''
                                      : 'text-muted'}"
                                  >
                                    Notify Client
                                  </h6>
                                  <span class="d-block small text-body"
                                    >Send your client an email on task
                                    completion</span
                                  >
                                </label>
                                <!-- End Col -->

                                <div class="col-auto">
                                  <!-- Form Switch -->
                                  <div class="form-check form-switch">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      id="toggle-1-{task.id}"
                                      on:change={clickNotifyClient}
                                      checked={task.should_notify_client}
                                      disabled={task.is_complete ||
                                        !permissions.includes("change_tasks")}
                                    />
                                  </div>
                                  <!-- End Form Switch -->
                                </div>
                                <!-- End Col -->
                              </div>
                              <!-- End Row -->
                            </div>
                          </div>
                        </div>
                        <!-- End Item -->

                        <!-- Item -->
                        <div
                          class="list-group-item {task.is_complete
                            ? 'bg-soft-secondary'
                            : ''}"
                        >
                          <div class="d-flex">
                            <div class="flex-shrink-0">
                              <span
                                class="svg-icon svg-icon-sm {task.is_deliverable
                                  ? 'text-primary'
                                  : 'text-muted'}"
                              >
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M20 8H16C15.4 8 15 8.4 15 9V16H10V17C10 17.6 10.4 18 11 18H16C16 16.9 16.9 16 18 16C19.1 16 20 16.9 20 18H21C21.6 18 22 17.6 22 17V13L20 8Z"
                                    fill="#035A4B"
                                  />
                                  <path
                                    opacity="0.3"
                                    d="M20 18C20 19.1 19.1 20 18 20C16.9 20 16 19.1 16 18C16 16.9 16.9 16 18 16C19.1 16 20 16.9 20 18ZM15 4C15 3.4 14.6 3 14 3H3C2.4 3 2 3.4 2 4V13C2 13.6 2.4 14 3 14H15V4ZM6 16C4.9 16 4 16.9 4 18C4 19.1 4.9 20 6 20C7.1 20 8 19.1 8 18C8 16.9 7.1 16 6 16Z"
                                    fill="#035A4B"
                                  />
                                </svg>
                              </span>
                            </div>

                            <div class="flex-grow-1 ms-3">
                              <div class="row align-items-center">
                                <label
                                  for="toggle-2-{task.id}"
                                  class="col form-label mb-0"
                                >
                                  <h6
                                    class="mb-1 {task.is_deliverable
                                      ? ''
                                      : 'text-muted'}"
                                  >
                                    Mark as Deliverable
                                  </h6>
                                  <span class="d-block small text-body"
                                    >Send a deliverable to your client on task
                                    completion</span
                                  >
                                </label>
                                <!-- End Col -->

                                <div class="col-auto">
                                  <!-- Form Switch -->
                                  <div class="form-check form-switch">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      id="toggle-2-{task.id}"
                                      on:change={clickDeliverable}
                                      checked={task.is_deliverable}
                                      disabled={task.is_complete ||
                                        !permissions.includes("change_tasks")}
                                    />
                                  </div>
                                  <!-- End Form Switch -->
                                </div>
                                <!-- End Col -->
                              </div>
                              <!-- End Row -->
                            </div>
                          </div>
                        </div>
                        <!-- End Item -->

                        <!-- Item -->
                        <div
                          class="list-group-item {task.is_complete
                            ? 'bg-soft-secondary'
                            : ''}"
                        >
                          <div class="d-flex">
                            <div class="flex-shrink-0">
                              <span
                                class="svg-icon svg-icon-sm {task.is_assigned_to_client
                                  ? 'text-primary'
                                  : 'text-muted'}"
                              >
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    opacity="0.3"
                                    d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z"
                                    fill="#035A4B"
                                  />
                                  <path
                                    d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z"
                                    fill="#035A4B"
                                  />
                                </svg>
                              </span>
                            </div>
                            <div class="flex-grow-1 ms-3">
                              <div class="row align-items-center">
                                <label
                                  class="col form-label mb-0"
                                  for="toggle-3-{task.id}"
                                >
                                  <h6
                                    class="mb-1 {task.is_assigned_to_client
                                      ? ''
                                      : 'text-muted'}"
                                  >
                                    Assign to Client
                                  </h6>
                                  <span class="d-block small text-body"
                                    >Enable client to complete task on the
                                    review page</span
                                  >
                                </label>
                                <!-- End Col -->

                                <div class="col-auto">
                                  <!-- Form Switch -->
                                  <div class="form-check form-switch">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      id="toggle-3-{task.id}"
                                      on:change={() => {
                                        clickToggleIsAssignedToClient(false);
                                      }}
                                      checked={task.is_assigned_to_client}
                                      disabled={task.is_complete ||
                                        !permissions.includes("change_tasks")}
                                    />
                                  </div>
                                  <!-- End Form Switch -->
                                </div>
                                <!-- End Col -->
                              </div>
                              <!-- End Row -->
                              {#if task.is_assigned_to_client && !projectIsTemplate}
                                <div
                                  class="d-grid mt-2 form-label text-secondary mb-0"
                                  transition:slide
                                >
                                  <button
                                    bind:this={assignEl}
                                    class="btn btn-xs {isShowingAssignmentPopover
                                      ? 'btn-outline-danger'
                                      : 'btn-white'}"
                                    disabled={(!canReSendAssignment &&
                                      task.last_assignment_notification_sent_at) ||
                                      !permissions.includes("change_tasks")}
                                    on:click={() => {
                                      isShowingAssignmentPopover =
                                        !isShowingAssignmentPopover;
                                    }}
                                    ><i
                                      class="bi {!isShowingAssignmentPopover
                                        ? 'bi-send'
                                        : 'bi-x-circle'}"
                                    />
                                    {!isShowingAssignmentPopover
                                      ? " Send Assignment"
                                      : " Cancel"}</button
                                  >
                                  {#if task.last_assignment_notification_sent_at}
                                    <em class="d-block small"
                                      >Last assignment sent {dayjs(
                                        task.last_assignment_notification_sent_at
                                      ).fromNow()}. {#if !canReSendAssignment}Please
                                        allow 24 hours to resend.{/if}</em
                                    >
                                  {/if}

                                  <Popover
                                    triggerEvents={["click"]}
                                    referenceElement={assignEl}
                                    placement="bottom-end"
                                    spaceAway={10}
                                    bind:isOpen={isShowingAssignmentPopover}
                                  >
                                    {#if isShowingAssignmentPopover}
                                      <div
                                        class="popover-contents text-left"
                                        transition:fade={{ duration: 250 }}
                                        use:clickOutside
                                        on:click_outside={(e) => {
                                          const clickEvent =
                                            e.detail.clickEvent;

                                          if (
                                            assignEl?.contains(
                                              clickEvent?.target
                                            )
                                          ) {
                                            return;
                                          }

                                          isShowingAssignmentPopover = false;
                                        }}
                                      >
                                        <div
                                          class="card p-3 task-message assign-client is-popup"
                                        >
                                          <div class="row text-left">
                                            <h5 class="">
                                              Send Task Assignment
                                            </h5>
                                          </div>
                                          <div class="row">
                                            <div class="col">
                                              {#if task.project.key_contact}
                                                <label
                                                  class="form-label"
                                                  for="clientContact">To</label
                                                >
                                                {#each task.project.contacts as contact}
                                                  {#if contact.id == task.project.key_contact.id}
                                                    <input
                                                      id="clientContact"
                                                      type="text"
                                                      class="form-control mb-2"
                                                      value="{contact.first_name} {contact.last_name} <{contact.email}>"
                                                      readonly
                                                    />
                                                  {/if}
                                                {/each}
                                                {#if task.project.contacts}
                                                  {#if showCC}
                                                    <label
                                                      class="form-label"
                                                      for="clientContact"
                                                      >Cc</label
                                                    >
                                                  {/if}
                                                  {#each task.project.contacts as contact}
                                                    {#if contact.id != task.project.key_contact.id}
                                                      <input
                                                        id="clientContact"
                                                        type="text"
                                                        class="form-control mb-2"
                                                        value="{contact.first_name} {contact.last_name} <{contact.email}>"
                                                        readonly
                                                      />
                                                    {/if}
                                                  {/each}
                                                {/if}
                                              {:else if task.project.contacts}
                                                <label
                                                  class="form-label"
                                                  for="clientContact">To</label
                                                >
                                                {#each task.project.contacts as contact}
                                                  <input
                                                    id="clientContact"
                                                    type="text"
                                                    class="form-control mb-2"
                                                    value="{contact.first_name} {contact.last_name} <{contact.email}>"
                                                    readonly
                                                  />
                                                {/each}
                                              {/if}
                                            </div>
                                          </div>
                                          <div class="row text-left mb-1">
                                            <div class="col">
                                              <label
                                                class="form-label"
                                                for="assignClientMessage"
                                                >Message</label
                                              >
                                              <textarea
                                                id="assignClientMessage"
                                                bind:value={assignMessage}
                                                rows="5"
                                                class="form-control"
                                                placeholder="This message will be sent to your client."
                                              />
                                              <small
                                                class="d-block text-end {assignMessageLength >=
                                                1000
                                                  ? 'text-danger'
                                                  : ''}"
                                                >{assignMessageLength}/1000
                                                characters</small
                                              >
                                            </div>
                                          </div>
                                          <div class="row">
                                            <div class="d-grid">
                                              <button
                                                type="submit"
                                                on:click={() => {
                                                  clickSendNotificationAssignedToClient();
                                                  isShowingAssignmentPopover = false;
                                                }}
                                                class="btn btn-primary btn-sm btn-fill btn-block"
                                                >{task.last_assignment_notification_sent_at
                                                  ? "Resend"
                                                  : "Send"} Assignment</button
                                              >
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    {/if}
                                  </Popover>
                                </div>
                              {/if}
                            </div>
                          </div>
                        </div>
                        <!-- End Item -->

                        <!-- Item -->
                        <div class="list-group-item">
                          <div class="d-flex">
                            <div class="flex-shrink-0">
                              <span
                                class="svg-icon svg-icon-sm {task.is_hidden_from_client
                                  ? 'text-primary'
                                  : 'text-muted'}"
                              >
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    opacity="0.3"
                                    d="M11.8 5.2L17.7 8.6V15.4L11.8 18.8L5.90001 15.4V8.6L11.8 5.2ZM11.8 2C11.5 2 11.2 2.1 11 2.2L3.8 6.4C3.3 6.7 3 7.3 3 7.9V16.2C3 16.8 3.3 17.4 3.8 17.7L11 21.9C11.3 22 11.5 22.1 11.8 22.1C12.1 22.1 12.4 22 12.6 21.9L19.8 17.7C20.3 17.4 20.6 16.8 20.6 16.2V7.9C20.6 7.3 20.3 6.7 19.8 6.4L12.6 2.2C12.4 2.1 12.1 2 11.8 2Z"
                                    fill="#035A4B"
                                  />
                                  <path
                                    d="M11.8 8.69995L8.90001 10.3V13.7L11.8 15.3L14.7 13.7V10.3L11.8 8.69995Z"
                                    fill="#035A4B"
                                  />
                                </svg>
                              </span>
                            </div>

                            <div class="flex-grow-1 ms-3">
                              <div class="row align-items-center">
                                <label
                                  class="col form-label mb-0"
                                  for="toggle-4-{task.id}"
                                >
                                  <h6
                                    class="mb-1 {task.is_hidden_from_client
                                      ? ''
                                      : 'text-muted'}"
                                  >
                                    Hide Task from Client
                                  </h6>
                                  <span class="d-block small text-body"
                                    >Hide this task on the project's client
                                    review page</span
                                  >
                                </label>
                                <!-- End Col -->

                                <div class="col-auto">
                                  <!-- Form Switch -->
                                  <div class="form-check form-switch">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      id="toggle-4-{task.id}"
                                      checked={task.is_hidden_from_client}
                                      on:change={clickToggleClientVisibility}
                                      disabled={!permissions.includes(
                                        "change_tasks"
                                      )}
                                    />
                                  </div>
                                  <!-- End Form Switch -->
                                </div>
                                <!-- End Col -->
                              </div>
                              <!-- End Row -->
                            </div>
                          </div>
                        </div>
                        <!-- End Item -->
                      </div>
                    </div>
                  </div>
                  <div
                    class="d-flex justify-content-between flex-column flex-md-row mb-3"
                  >
                    <div
                      class="col d-grid d-md-none m-0 mx-md-1 mb-1 mb-md-0"
                    />
                    <div />
                  </div>
                  {#if permissions.includes("change_tasks")}
                    <div class="text-end mt-3 mb-1">
                      <button
                        on:click={() => {
                          if (!isShowingDeleteConfirmation) {
                            isShowingDeleteConfirmation = true;
                          } else {
                            dispatch("deleteTask");
                          }
                        }}
                        use:clickOutside
                        on:click_outside={() => {
                          if (isShowingDeleteConfirmation) {
                            isShowingDeleteConfirmation = false;
                          }
                        }}
                        class="text-right btn btn-outline-danger {isShowingDeleteConfirmation
                          ? 'btn-sm'
                          : 'btn-xs'}"
                        ><i class="bi bi-trash3" />{isShowingDeleteConfirmation
                          ? " Confirm Delete"
                          : ""}</button
                      >
                    </div>
                  {/if}
                </div>
                <div
                  class="tab-pane fade"
                  id="nav-two-eg1-{task.id}"
                  role="tabpanel"
                  aria-labelledby="nav-two-eg1-tab"
                >
                  {#if task.is_assigned_to_client}
                    <div class="row">
                      <ClientReminders
                        {clientremindersApiUrl}
                        {canReSendReminder}
                        bind:fetchClientReminders
                        {task}
                        on:clickSendReminderAssignedToClient={clickSendReminderAssignedToClient}
                        on:openDeadline={() => {
                          isEditingDate = true;
                        }}
                      />
                    </div>
                  {/if}
                </div>
              </div>
              <!-- End Tab Content -->
            </div>
          </div>
        </td>
      </tr>
    {/if}
  {/if}
{/if}

<style scoped>
  .task {
  }

  .draggable {
    cursor: pointer;
  }

  .hover {
  }

  .hidden {
    display: none;
    /* visibility: hidden; */
  }

  .no-scroll {
    overflow: hidden; /* Hide scrollbars and prevent scrolling */
  }
</style>
