<script>
  import { fade } from "svelte/transition";
  import { onMount } from "svelte";
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();

  export let roomName;
  export let userid;

  let chatSocket;
  let showReloadToast = false;
  let attempts = 0;

  const connectWebSocket = () => {
    var ws_scheme = window.location.protocol == "https:" ? "wss://" : "ws://";
    if (attempts >= 3) {
      console.log("Failed to connect after 3 attempts.");
      return;
    } else {
      chatSocket = new WebSocket(
        ws_scheme +
          window.location.host +
          "/ws/organization/" +
          roomName +
          "/projects/"
      );
      attempts++;
      console.log(`I made ${attempts} attempts`);

      chatSocket.onopen = function (e) {
        // Perform your action here
        console.log("Connection established");
        showReloadToast = false;
        attempts = 0;
      };

      chatSocket.onmessage = function (e) {
        const data = JSON.parse(e.data);

        if (data.message == "pong") {
          console.log("pong");
        }
        if (
          data.message === "removed_project_user" &&
          data.removed_project_user === parseInt(userid)
        ) {
          // this will only be heard by ProjectList listeners
          dispatch("removedProjectUser", { project_id: data.project_id });
        } else if (data.request_user_id !== parseInt(userid)) {
          // if the project was changed in some way
          if (
            data.message === "changed_project" ||
            data.message == "removed_project_user"
          ) {
            dispatch("changedProject", { project_id: data.project_id });
            // if a new project was created
          } else if (data.message === "created_new_project") {
            dispatch("createdNewProject");
          }
        } else {
          dispatch("updateChecklist");
        }
      };

      chatSocket.onclose = function (e) {
        showReloadToast = true;
        console.log("Chat socket closed unexpectedly. Retrying...");

        setTimeout(function () {
          connectWebSocket();
        }, 1000);
      };

      chatSocket.onerror = function (e) {
        console.error("Error occurred: ", e);
      };

      setInterval(() => {
        if (chatSocket.readyState === WebSocket.OPEN) {
          chatSocket.send(
            JSON.stringify({
              message: "ping",
            })
          );
          console.log("ping");
        }
      }, 30000);
    }
  };

  onMount(async () => {
    connectWebSocket();
  });
</script>

{#if showReloadToast}
  <!-- Toast -->
  <div
    transition:fade
    id="liveToast"
    class="position-fixed toast show"
    role="alert"
    style="bottom: 20px; right: 20px; z-index: 1000;"
  >
    <div class="toast-body">
      <div class="d-flex align-items-center flex-grow-1">
        <div class="flex-shrink-0">
          <span
            class="svg-icon svg-icon-sm text-warning"
            aria-label="abs/abs023.svg"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.3"
                d="M12.3408 20.7578C12.3408 21.7578 13.6408 22.0578 14.1408 21.2578L19.5408 11.8578C19.9408 11.1578 19.4408 10.3578 18.6408 10.3578H12.3408V20.7578Z"
                fill="#035A4B"
              />
              <path
                d="M12.3408 3.9578C12.3408 2.9578 11.0408 2.6578 10.5408 3.4578L5.14078 12.8578C4.74078 13.5578 5.24078 14.3578 6.04078 14.3578H12.3408V3.9578Z"
                fill="#035A4B"
              />
            </svg>
          </span>
        </div>
        <div class="flex-grow-1 ms-3">
          <h5 class="mb-0 text-warning">Lost Connection</h5>
          <small class="ms-auto"
            >{#if attempts < 3}Retrying...{:else}Please refresh the page{/if}</small
          >
        </div>
        <div class="text-end">
          {#if attempts >= 3}
            <button
              on:click={() => {
                window.location.reload();
              }}
              type="button"
              class="btn btn-icon btn-outline-primary btn-sm"
              ><i class="bi bi-arrow-repeat" /></button
            >
          {/if}
        </div>
      </div>
    </div>
  </div>
  <!-- End Toast -->
{/if}
