import { debounce } from 'lodash'

export function stopTyping(node) {
  const handleKeyup = debounce((event) => {
    if (node.contains(event.target)) {
      node.dispatchEvent(new CustomEvent('stopTyping'));
    }
  }, 500);
  document.addEventListener('keyup', handleKeyup, true);
  return {
    destroy() {
      document.removeEventListener('keyup', handleKeyup, true);
    }
  };
}