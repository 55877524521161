<script>
  import { onMount } from "svelte";
  import axios from "axios";
  import { getCSRFToken } from "../../utils";
  import Loader from "../Loader.svelte";
  import { fade, fly } from "svelte/transition";
  import Member from "./Member.svelte";
  import Invite from "./Invite.svelte";
  import { setContext } from "svelte";

  import { sendInviteSchema } from "../../schema";
  import { extractErrors } from "../../utils";

  export let permissions = null;
  export let orguserApiUrl;
  export let memberinviteApiUrl;
  export let currentOrgId;
  export let userId;
  export let currentUserApiUrl;
  export let currentOrgApiUrl;
  export let projectsApiUrl;
  export let billingPortalSeatsUrl;

  setContext("permissions", permissions);

  let organization = {};
  let showConfirmation;
  let showInviteConfirmation;
  let showDeleteMembershipModal = false;
  let showDeleteInviteModal = false;
  let showInviteModal = false;
  let showChangeSelfModal = false;
  let currentUserIsOrgOwner;

  $: currentUserIsOrgOwner = memberships.some(
    (membership) =>
      membership.user.id === parseInt(userId) &&
      membership.user_is_owner === true
  );

  let removeText;
  let confirmText;
  let removing = false;
  let inviting = false;

  let loading = 1;
  let memberships = [];
  let member_projects = [];
  let invites = [];
  let roles = [
    {
      choice: "ADMIN",
      text: "Admin",
    },
    {
      choice: "MANAGER",
      text: "Manager",
    },
    {
      choice: "BASIC",
      text: "Basic",
    },
  ];

  let current_user_membership;
  let delete_membership;
  let delete_invite;

  let newInvite = {
    role: "BASIC",
    invitee_email: "",
    invitor: currentUserApiUrl,
    organization: currentOrgApiUrl,
  };

  let form = {};
  let errors = {};

  async function fetchOrganization() {
    const response = await fetch(`${currentOrgApiUrl}`);

    if (response.ok) {
      const result = await response.json();
      organization = result;
    } else {
      error = "Failed to load organization.";
    }
  }

  async function fetchMemberships() {
    const response = await fetch(
      `${orguserApiUrl + "?organization=" + currentOrgId}`
    );

    if (response.ok) {
      const result = await response.json();
      memberships = result.results;
    } else {
      error = "Failed to load memberships.";
    }
  }

  async function fetchProjects(member) {
    const response = await fetch(`${projectsApiUrl + "?member=" + member}`);

    if (response.ok) {
      const result = await response.json();
      member_projects = result.results;
      console.log(member_projects);
    } else {
      error = "Failed to load member projects.";
    }
  }

  async function fetchInvites() {
    const response = await fetch(`${memberinviteApiUrl}`);

    if (response.ok) {
      const result = await response.json();
      invites = result.results;
    } else {
      error = "Failed to load invites.";
    }
  }

  async function updateMembership(membership) {
    const response = await fetch(membership.url, {
      method: "PUT",
      body: JSON.stringify({
        ...membership,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CsrfToken": getCSRFToken(),
      },
      credentials: "include",
    });
    if (response.ok) {
      showConfirmation = true;
      setTimeout(() => (showConfirmation = false), 2000);
    }
    await fetchMemberships();
  }

  async function deleteMembership(membership) {
    removing = true;

    const response = await fetch(membership.url + "delete_membership", {
      method: "DELETE",
      body: JSON.stringify({
        ...membership,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CsrfToken": getCSRFToken(),
      },
      credentials: "include",
    });
    if (response.ok) {
      removing = false;
      delete_membership = null;
      showDeleteMembershipModal = false;
      showConfirmation = true;
      setTimeout(() => (showConfirmation = false), 2000);
    }
    await fetchMemberships();
  }

  async function makeOwner(membership) {
    const response = await fetch(membership.url + "make_owner/", {
      method: "PUT",
      body: JSON.stringify({
        ...membership,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CsrfToken": getCSRFToken(),
      },
      credentials: "include",
    });
    if (response.ok) {
      showConfirmation = true;
      setTimeout(() => (showConfirmation = false), 2000);
    }
    await fetchMemberships();
  }

  async function sendInvite() {
    let formIsValid = await validateForm();
    if (formIsValid) {
      inviting = true;
      const response = await fetch(memberinviteApiUrl, {
        method: "POST",
        body: JSON.stringify({
          ...newInvite,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-CsrfToken": getCSRFToken(),
        },
        credentials: "include",
      });
      if (response.ok) {
        newInvite = {
          role: "BASIC",
          invitee_email: "",
          invitor: currentUserApiUrl,
          organization: currentOrgApiUrl,
        };

        showInviteModal = false;
        showInviteConfirmation = true;
        setTimeout(() => (showInviteConfirmation = false), 2000);
      }
      inviting = false;
      await fetchInvites();
    }
  }

  async function updateInvite(invite) {
    const response = await fetch(invite.url, {
      method: "PUT",
      body: JSON.stringify({
        ...invite,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CsrfToken": getCSRFToken(),
      },
      credentials: "include",
    });
    await fetchInvites();
  }

  async function deleteInvite(invite) {
    removing = true;
    const response = await fetch(invite.url, {
      method: "DELETE",
      body: JSON.stringify({
        ...invite,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CsrfToken": getCSRFToken(),
      },
      credentials: "include",
    });
    if (response.ok) {
      showDeleteInviteModal = false;
      removing = false;
    }
    await fetchInvites();
  }

  function validateForm() {
    for (let m = 0; m < memberships.length; m++) {
      if (newInvite.invitee_email == memberships[m].user.email) {
        errors.email = "This user is already a part of this organization.";
        return false;
      }
    }
    for (let i = 0; i < invites.length; i++) {
      if (newInvite.invitee_email == invites[i].invitee_email) {
        errors.email = "This user has already been invited.";
        return false;
      }
    }
    form = {
      email: newInvite.invitee_email,
    };
    return sendInviteSchema
      .validate(form, { abortEarly: false })
      .then(() => {
        // clear the errors
        errors = {};
        return true;
      })
      .catch((err) => {
        errors = extractErrors(err);
        return false;
      });
  }

  onMount(async () => {
    await fetchOrganization();
    await fetchMemberships();
    await fetchInvites();
    --loading;
  });
</script>

{#if loading > 0}
  <div
    class="d-flex justify-content-center align-items-center"
    style="margin-top:40%"
  >
    <Loader />
  </div>
{:else}
  <div class="d-grid gap-3 gap-lg-5" transition:fly={{ y: 10 }}>
    <!-- Card -->
    <div class="card">
      <div
        class="card-header d-flex justify-content-between align-items-center border-bottom"
      >
        <div>
          <h4 class="card-header-title">Manage Memberships</h4>
          {memberships.length} /
          {organization.available_seats} available seats
        </div>
        {#if permissions.includes("change_organization_members")}
          {#if permissions.includes("change_billing")}
            <div class="btn-group" role="group" aria-label="Basic example">
              <button
                class="btn btn-white btn-sm"
                disabled={memberships.length >= organization.available_seats}
                on:click={() => {
                  showInviteModal = true;
                }}><i class="bi-person-plus" /> Invite Member</button
              >
              <a class="btn btn-white btn-sm" href={billingPortalSeatsUrl}
                ><i class="bi-people" />
                {organization.available_seats > 1 ? "Manage" : "Add"} Seats</a
              >
            </div>
          {:else}
            <button
              class="btn btn-white btn-sm"
              disabled={memberships.length >= organization.available_seats}
              on:click={() => {
                showInviteModal = true;
              }}><i class="bi-person-plus" /> Invite Member</button
            >
          {/if}
        {/if}
      </div>

      <!-- Table -->
      <div class="table-responsive">
        <table
          class="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
        >
          <thead class="thead-light">
            <tr>
              <th>Name</th>
              <th>User Role</th>
              <th style="width: 5%;" />
            </tr>
          </thead>

          <tbody>
            {#if permissions.includes("change_organization_members") && organization.available_seats < memberships.length}
              <tr class="text-center bg-danger text-white">
                <td colspan="12" class="p-3"
                  >Your organization has too many members. Remove {memberships.length -
                    organization.available_seats} member{#if memberships.length - organization.available_seats > 1}s{/if}
                  {#if permissions.includes("change_billing")}
                    or increase the number of seats{/if} to restore access.</td
                >
              </tr>
            {/if}
            {#each memberships as membership}
              <Member
                {membership}
                {currentUserIsOrgOwner}
                {userId}
                {roles}
                on:clickMakeOwner={makeOwner(membership)}
                on:clickUpdateMembership={updateMembership(membership)}
                on:showDeleteMembershipModal={async (event) => {
                  delete_membership = event.detail.membership;
                  await fetchProjects(delete_membership.user.id);
                  showDeleteMembershipModal = true;
                }}
                on:showChangeSelfModal={(event) => {
                  current_user_membership = event.detail.membership;
                  showChangeSelfModal = true;
                }}
              />
            {/each}
          </tbody>
        </table>
      </div>
      <!-- End Table -->
      {#if showConfirmation}
        <div class="row">
          <div class="col">
            <div
              transition:fade
              class="alert alert-soft-success mx-3"
              role="alert"
            >
              Membership Updated
            </div>
          </div>
        </div>
      {/if}
    </div>
    {#if invites.length > 0}
      <div class="card" transition:fade>
        <div
          class="card-header d-flex justify-content-between align-items-center border-bottom"
        >
          <h5 class="card-header-title">Pending Invites</h5>
        </div>
        <div class="table-responsive">
          <table
            class="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
          >
            <thead class="thead-light"
              ><tr
                ><th>Name</th>
                <th>Status</th>
                <th>User Role</th>
                {#if permissions.includes("change_organization_members")}
                  <th style="width: 5%;" />
                {/if}</tr
              ></thead
            >
            <tbody>
              {#each invites as invite}
                <Invite
                  {invite}
                  {roles}
                  on:deleteInvite={() => {
                    delete_invite = invite;
                    showDeleteInviteModal = true;
                  }}
                  on:updateInvite={updateInvite(invite)}
                />{:else}
                <tr
                  ><td colspan="3"
                    ><h5 class="text-center mt-1 mb-1">
                      No pending invites
                    </h5></td
                  ></tr
                >
              {/each}</tbody
            >
          </table>
          {#if showInviteConfirmation}
            <div class="row">
              <div class="col">
                <div
                  transition:fade
                  class="alert alert-soft-success mx-3"
                  role="alert"
                >
                  Invite Sent
                </div>
              </div>
            </div>
          {/if}
        </div>
      </div>
    {/if}
    <div class="card">
      <div
        class="card-header d-flex justify-content-between align-items-center border-bottom"
      >
        <h5 class="card-header-title">Member Roles Explained</h5>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <!-- Icon Blocks -->
            <div class="d-flex">
              <div class="flex-shrink me-3">
                <span class="svg-icon text-primary mb-3">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17 7V17H7V7H17ZM20 3H4C3.4 3 3 3.4 3 4V20C3 20.6 3.4 21 4 21H20C20.6 21 21 20.6 21 20V4C21 3.4 20.6 3 20 3Z"
                      fill="#035A4B"
                    />
                  </svg>
                </span>
              </div>
              <div class="flex-grow">
                <h5>Basic</h5>
                <p>Perfect for sub-contractors or frontline staff members.</p>
                <!-- List -->
                <ul class="list-checked list-checked-sm list-checked-primary">
                  <li class="list-checked-item mb-1 small">
                    Can only view projects assigned to them
                  </li>
                  <li class="list-checked-item mb-1 small">
                    Can complete tasks
                  </li>
                  <li class="list-checked-item mb-1 small">
                    Can view task details
                  </li>
                  <li class="list-checked-item mb-1 small">
                    Can view project clients/contacts
                  </li>
                  <li class="list-checked-item mb-1 small">
                    Can message clients
                  </li>
                  <li class="list-checked-item mb-1 small">
                    Can send deliverables
                  </li>
                  <li class="list-checked-item mb-1 small">
                    Can manage their personal profile
                  </li>
                </ul>
                <!-- End List -->
              </div>
            </div>
            <!-- End Icon Blocks -->
          </div>
          <!-- End Col -->

          <div class="col">
            <!-- Icon Blocks -->
            <div class="d-flex">
              <div class="flex-shrink me-3">
                <span class="svg-icon text-primary mb-3">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.8 6.4L16.7 9.2V14.8L11.8 17.6L6.89999 14.8V9.2L11.8 6.4ZM11.8 2C11.5 2 11.2 2.1 11 2.2L3.79999 6.4C3.29999 6.7 3 7.3 3 7.9V16.2C3 16.8 3.29999 17.4 3.79999 17.7L11 21.9C11.3 22.1 11.5 22.1 11.8 22.1C12.1 22.1 12.4 22 12.6 21.9L19.8 17.7C20.3 17.4 20.6 16.8 20.6 16.2V7.9C20.6 7.3 20.3 6.7 19.8 6.4L12.6 2.2C12.4 2.1 12.1 2 11.8 2Z"
                      fill="#035A4B"
                    />
                  </svg>
                </span>
              </div>
              <div class="flex-grow">
                <h5>Manager</h5>
                <p>Best for project managers or experienced staff.</p>
                <p class="small">
                  Includes <strong>Basic Permissions</strong> plus:
                </p>

                <!-- List -->
                <ul class="list-checked list-checked-sm list-checked-primary">
                  <li class="list-checked-item mb-1 small">
                    Can create and delete tasks
                  </li>
                  <li class="list-checked-item mb-1 small">
                    Can change task settings
                  </li>
                  <li class="list-checked-item mb-1 small">
                    Can assign tasks and projects
                  </li>
                  <li class="list-checked-item mb-1 small">
                    Can create and delete projects
                  </li>
                  <li class="list-checked-item mb-1 small">
                    Can change project settings
                  </li>
                  <li class="list-checked-item mb-1 small">
                    Can add and manage clients
                  </li>
                  <li class="list-checked-item mb-1 small">
                    Can add and manage contacts
                  </li>
                </ul>
                <!-- End List -->
              </div>
            </div>
            <!-- End Icon Blocks -->
          </div>
          <!-- End Col -->

          <div class="col">
            <!-- Icon Blocks -->
            <div class="d-flex">
              <div class="flex-shrink me-3">
                <span class="svg-icon text-primary mb-3">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 6C8.7 6 6 8.7 6 12C6 15.3 8.7 18 12 18C15.3 18 18 15.3 18 12C18 8.7 15.3 6 12 6Z"
                      fill="#035A4B"
                    />
                  </svg>
                </span>
              </div>
              <div class="flex-grow">
                <h5>Admin</h5>
                <p>Ideal for organization leadership or brand police.</p>
                <p class="small">
                  Includes <strong>Manager Permissions</strong> plus:
                </p>
                <!-- List -->
                <ul class="list-checked list-checked-sm list-checked-primary">
                  <li class="list-checked-item mb-1 small">
                    Can change organization settings
                  </li>
                  <li class="list-checked-item mb-1 small">
                    Can manage organization members
                  </li>
                  <li class="list-checked-item mb-1 small">
                    Can update brand info (brand color, website, description)
                  </li>
                </ul>
                <!-- End List -->
              </div>
            </div>
            <!-- End Icon Blocks -->
          </div>
          <!-- End Col -->
        </div>
      </div>
    </div>
    <!-- End Card -->
  </div>
{/if}
{#if showDeleteMembershipModal}
  <!-- Removal Modal -->
  <div
    id="exampleModalCenter"
    class="modal"
    tabindex="-1"
    role="dialog"
    transition:fade
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
      transition:fly={{ y: 10 }}
    >
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="exampleModalCenterTitle">
            Remove user from your organization?
          </h4>
          <button
            on:click={() => {
              showDeleteMembershipModal = false;
            }}
            type="button"
            class="btn-close"
          />
        </div>
        <div class="modal-body">
          <p>
            You may add this user back at any time. However, removing <strong
              >{delete_membership.user.first_name +
                " " +
                delete_membership.user.last_name}</strong
            > from your organization will remove them from all tasks currently assigned
            to them and remove them from the following projects:
          </p>
          <ul>
            {#each member_projects as project}
              <li>
                {project.name}
                <span class="badge badge bg-soft-secondary text-secondary">
                  {#if project.is_archived}Archived{:else if project.is_completed}Completed{:else if project.is_upcoming}Upcoming{:else}Active{/if}</span
                >
              </li>
            {/each}
          </ul>
          <p>
            To ensure data integrity, we will assign to you any project where
            this user was the sole assignee.
          </p>
          <p>
            <strong>This operation cannot be undone.</strong>
          </p>
        </div>
        <div class="modal-footer">
          <div class="col-7">
            <input
              bind:value={removeText}
              type="text"
              class="form-control"
              placeholder="Type 'REMOVE' to confirm"
            />
          </div>
          <div class="col d-grid">
            <button
              on:click={() => {
                deleteMembership(delete_membership);
              }}
              type="button"
              class="btn {removeText != 'REMOVE' ? '' : 'btn-outline-danger'}"
              disabled={removeText != "REMOVE"}
            >
              {#if removing}
                <span class="spinner-border spinner-border-sm" role="status" />
                Removing...
              {:else}
                Confirm Removal
              {/if}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End Modal -->
{/if}
{#if showChangeSelfModal}
  <!-- Removal Modal -->
  <div
    id="exampleModalCenter"
    class="modal"
    tabindex="-1"
    role="dialog"
    transition:fade
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
      transition:fly={{ y: 10 }}
    >
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="exampleModalCenterTitle">
            Change your permissions?
          </h4>
          <button
            on:click={() => {
              fetchMemberships();
              showChangeSelfModal = false;
            }}
            type="button"
            class="btn-close"
          />
        </div>
        <div class="modal-body">
          <p>
            If you change your role to <strong
              >{current_user_membership.role}</strong
            >, you may lose certain permissions.
          </p>
          <ul>
            {#if current_user_membership.role == "MANAGER"}
              <li>Can change organization settings</li>
              <li>Can manage billing</li>
              <li>Can manage organization members</li>
              <li>Can update brand info (brand color, website, description)</li>
            {:else if current_user_membership.role == "BASIC"}
              <li>Can change organization settings</li>
              <li>Can manage billing</li>
              <li>Can manage organization members</li>
              <li>Can update brand info (brand color, website, description)</li>
              <li>Can create and delete tasks</li>
              <li>Can change task settings</li>
              <li>Can assign tasks and projects</li>
              <li>Can create and delete projects</li>
              <li>Can change project settings</li>
              <li>Can add and manage clients</li>
              <li>Can add and manage contacts</li>
            {/if}
          </ul>
          <p>
            <strong
              >You will need an Admin from your organization to change your role
              back.</strong
            >
          </p>
        </div>
        <div class="modal-footer">
          <div class="col-7">
            <input
              bind:value={confirmText}
              type="text"
              class="form-control"
              placeholder="Type 'CONFIRM' to confirm"
            />
          </div>
          <div class="col d-grid">
            <button
              on:click={() => {
                updateMembership(current_user_membership);
                window.location.href = "/organization";
              }}
              type="button"
              class="btn {confirmText != 'CONFIRM' ? '' : 'btn-outline-danger'}"
              disabled={confirmText != "CONFIRM"}
            >
              {#if removing}
                <span class="spinner-border spinner-border-sm" role="status" />
                Updating...
              {:else}
                Change Role
              {/if}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End Modal -->
{/if}
{#if showDeleteInviteModal}
  <!-- Removal Modal -->
  <div
    id="exampleModalCenter"
    class="modal"
    tabindex="-1"
    role="dialog"
    transition:fade
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
      transition:fly={{ y: 10 }}
    >
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="exampleModalCenterTitle">
            Cancel this invite?
          </h4>
          <button
            on:click={() => {
              showDeleteInviteModal = false;
            }}
            type="button"
            class="btn-close"
          />
        </div>
        <div class="modal-body">
          <p>
            Deleting this invite means <strong
              >{delete_invite.invitee_email}</strong
            > will no longer be able to join your organization. You can resend an
            invite anytime.
          </p>
        </div>
        <div class="modal-footer">
          <div class="col d-grid">
            <button
              on:click={() => {
                deleteInvite(delete_invite);
              }}
              type="button"
              class="btn btn-outline-danger"
            >
              {#if removing}
                <span class="spinner-border spinner-border-sm" role="status" />
                Cancel...
              {:else}
                Cancel Invite
              {/if}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End Modal -->
{/if}
{#if showInviteModal}
  <!-- Invite  Modal -->
  <div
    id="exampleModalCenter"
    class="modal"
    tabindex="-1"
    role="dialog"
    transition:fade
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
      transition:fly={{ y: 10 }}
    >
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="exampleModalCenterTitle">
            Invite new user
          </h4>
          <button
            on:click={() => {
              if (inviting) {
                inviting = false;
              }
              showInviteModal = false;
            }}
            type="button"
            class="btn-close"
          />
        </div>
        <div class="modal-body">
          <p>
            Enter the new user's details below and we'll get them squared away.
          </p>

          <div class="row">
            <div class="col-7">
              <label class="form-label" for="emailAddress">Email Address</label>
              <input
                name="emailAddress"
                type="text"
                class="form-control"
                bind:value={newInvite.invitee_email}
              />
            </div>
            <div class="col">
              <!-- Select -->
              <label class="form-label" for="inviteRole">User Role</label>
              <select
                bind:value={newInvite.role}
                class="js-select form-select"
                name="inviteRole"
              >
                {#each roles as role}
                  <option value={role.choice} selected={role.choice == "BASIC"}>
                    {role.text}
                  </option>
                {/each}
              </select>
              <!-- End Select -->
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-between">
          <div class="text-danger">
            {#if errors.email}
              {errors.email}
            {/if}
          </div>
          <button
            on:click={() => {
              if (!inviting) {
                sendInvite();
              }
            }}
            type="button"
            class="btn btn-primary"
          >
            {#if inviting}
              <span class="spinner-border spinner-border-sm" role="status" />
              Inviting...
            {:else}
              Send Invite
            {/if}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- End Modal -->
{/if}
