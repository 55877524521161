<script context="module">
  import settings from "./settings";
  // your script goes here
  export const config = settings;
</script>

<script>
  import { createEventDispatcher, tick } from "svelte";
  import { fade } from "svelte/transition";
  import Calendar from "./Calendar.svelte";
  import Time from "./Time.svelte";
  import { formatDate, parseDate, UTCDate } from "./dateUtils";
  import { usePosition } from "./utils";
  // html
  export let name = "date";
  export let disabled = false;
  export let placeholder = null;
  export let required = false;
  // added custom property
  export let isOpen = false;
  // dates
  export let value = null;
  export let initialDate = null;
  export let startDate = null;
  export let endDate = null;
  export let pickerOnly = false;
  // configurable globally
  export let theme = config.theme;
  export let mode = config.mode;
  export let format = config.format;
  export let formatType = config.formatType;
  export let weekStart = config.weekStart;
  export let visible = config.visible;
  export let inputClasses = config.inputClasses;
  export let todayBtnClasses = config.todayBtnClasses;
  export let clearBtnClasses = config.clearBtnClasses;
  export let todayBtn = config.todayBtn;
  export let clearBtn = config.clearBtn;
  export let autoclose = config.autoclose;
  export let i18n = config.i18n;
  // actions
  export let positionFn = usePosition;
  export let validatorAction = null;
  export function setDateValue(val) {
    innerDate = parseDate(val, format, i18n, formatType);
  }

  // Moved logic to separate function from Input
  export function showPicker() {
    !isFocused && onFocus();
  }

  if (format === "yyyy-mm-dd" && mode === "time") {
    format = "hh:ii";
  }

  const dispatch = createEventDispatcher();
  // Removed this line of code to fix strip happening
  // if (value) value = value.substr(0, format.length); // strip seconds if present in initial value
  let prevValue = value;
  let currentFormat = format;
  let innerDate =
    initialDate && initialDate instanceof Date
      ? UTCDate(
          initialDate.getUTCFullYear(),
          initialDate.getUTCMonth(),
          initialDate.getUTCDate(),
          initialDate.getHours(),
          initialDate.getUTCMinutes()
        )
      : value
      ? parseDate(value, format, i18n, formatType)
      : null;
  if (innerDate && initialDate) {
    value = formatDate(innerDate, format, i18n, formatType);
  }
  let isFocused = pickerOnly;
  let inputEl = null;
  let inputRect = null;
  let inputAction = validatorAction ? validatorAction.shift() : () => {};
  let inputActionParams = validatorAction || [];
  let calendarEl = null;
  let timeEl;
  let preventClose = false;
  let preventCloseTimer = null;
  let resolvedMode =
    mode === "auto"
      ? format.match(/hh?|ii?/i) && format.match(/y|m|d/i)
        ? "datetime"
        : format.match(/hh?|ii?/i)
        ? "time"
        : "date"
      : mode;
  let currentMode = resolvedMode === "time" ? "time" : "date";

  $: internalVisibility = pickerOnly ? true : visible;
  $: {
    if (value !== prevValue) {
      const parsed = value ? parseDate(value, format, i18n, formatType) : null;
      innerDate = parsed;
      prevValue = value;
    }
    if (currentFormat !== format && innerDate) {
      value = formatDate(innerDate, format, i18n, formatType);
      prevValue = value;
      currentFormat = format;
      if (mode === "auto") {
        resolvedMode =
          format.match(/hh?|ii?/i) && format.match(/y|m|d/i)
            ? "datetime"
            : format.match(/hh?|ii?/i)
            ? "time"
            : "date";
      }
    }
  }

  function onDate(e) {
    let setter = e.detail || null;
    if (e.detail && innerDate) {
      if (
        innerDate.getUTCFullYear() === e.detail.getUTCFullYear() &&
        innerDate.getUTCMonth() === e.detail.getUTCMonth() &&
        innerDate.getUTCDate() === e.detail.getUTCDate() &&
        resolvedMode === "date"
      );
      // Bug fix—clicking on current date would clear date
      // setter = null;
    }
    value = setter ? formatDate(setter, format, i18n, formatType) : null;
    if (
      autoclose &&
      (resolvedMode === "date" || !setter) &&
      !pickerOnly &&
      !preventClose
    ) {
      onBlur(false);
    }
    if (
      setter &&
      !preventClose &&
      resolvedMode === "datetime" &&
      currentMode === "date"
    ) {
      currentMode = "time";
    }
    if (preventClose && currentMode === "time") {
      preventCloseTimer = setTimeout(() => {
        preventClose = false;
      }, 400);
    } else {
      preventClose = false;
    }
    tick().then(() => {
      inputEl.dispatchEvent(new Event("input"));
      dispatch("change", value);
      (window.getSelection
        ? window.getSelection()
        : document.selection
      ).empty();
    });
  }

  function onToday() {
    const today = new Date();
    if (startDate && parseDate(startDate, format, i18n, formatType) < today)
      return;
    const todayHours = innerDate ? innerDate.getUTCHours() : today.getHours();
    const todayMinutes = innerDate
      ? innerDate.getUTCMinutes()
      : today.getUTCMinutes();
    onDate({
      detail: UTCDate(
        today.getUTCFullYear(),
        today.getMonth(),
        today.getDate(),
        todayHours,
        todayMinutes,
        0
      ),
    });
  }

  function onClear() {
    onDate({ detail: null });
  }

  function onFocus() {
    inputRect = inputEl.getBoundingClientRect();
    isFocused = true;
  }

  function onKeyDown(e) {
    if (!isOpen) {
      return;
    }
    if (!isFocused) {
      ["Backspace", "Delete"].includes(e.key) && onClear();
      if (e.key !== "Enter") return onFocus();
    }
    switch (e.key) {
      case "PageDown":
      case "PageUp":
      case "ArrowDown":
      case "ArrowUp":
      case "ArrowLeft":
      case "ArrowRight":
        e.preventDefault();
        preventCloseTimer && clearTimeout(preventCloseTimer);
        preventClose = true;
        if (currentMode === "date") {
          calendarEl.handleGridNav(e.key, e.shiftKey);
        } else {
          // if (currentMode === 'time') {
          timeEl.makeTick(
            ["ArrowDown", "ArrowLeft", "PageDown"].includes(e.key) ? -1 : 1
          );
        }
        break;
      case "Escape":
        if (isFocused && !internalVisibility) {
          isFocused = false;
        }
        break;
      case "Backspace":
      case "Delete":
        onClear();
        break;
      case "Enter":
        isFocused && e.preventDefault();
        if (currentMode === "time") {
          if (!timeEl.minuteSwitch()) {
            return timeEl.minuteSwitch(true);
          }
          return onBlur(false);
        }
        if (isFocused && resolvedMode === "date") isFocused = false;
        if (innerDate && resolvedMode.includes("time")) {
          currentMode = "time";
        }
        break;
      case "Tab":
      case "F5":
        break;
      default:
        e.preventDefault();
    }
  }

  function onModeSwitch(e) {
    currentMode = e.detail;
  }

  function onTimeClose() {
    autoclose && !preventClose && !pickerOnly && onBlur(false);
  }

  function onBlur(e) {
    isFocused = false;
    if (resolvedMode.includes("date")) currentMode = "date";
    e && dispatch("blur");
  }
</script>

<svelte:window on:keydown={onKeyDown} />
<input
  type={pickerOnly ? "hidden" : "text"}
  {name}
  bind:this={inputEl}
  use:inputAction={inputActionParams}
  autocomplete="off"
  {disabled}
  {placeholder}
  class={inputClasses}
  {required}
  readonly={isFocused}
  {value}
  on:focus={onFocus}
  on:blur={onBlur}
  on:click={showPicker}
  on:input
  on:change
/>
{#if visible || isFocused}
  <div
    class="std-calendar-wrap is-popup {theme}"
    transition:fade={{ duration: 200 }}
    use:positionFn={{ inputEl, visible: internalVisibility, inputRect }}
    on:mousedown|preventDefault
  >
    {#if currentMode === "date"}
      <Calendar
        date={innerDate}
        startDate={startDate
          ? parseDate(startDate, format, i18n, formatType)
          : null}
        endDate={endDate ? parseDate(endDate, format, i18n, formatType) : null}
        enableTimeToggle={resolvedMode.includes("time")}
        bind:this={calendarEl}
        {i18n}
        {weekStart}
        on:date={onDate}
        on:switch={onModeSwitch}
      />
      {#if todayBtn || clearBtn}
        <div class="std-btn-row">
          {#if todayBtn}
            <button
              on:click={onToday}
              class={todayBtnClasses}
              disabled={startDate >
                formatDate(new Date(), format, i18n, formatType)}
              >{i18n.todayBtn}</button
            >
          {/if}
          {#if clearBtn}
            <button
              on:click={onClear}
              class={clearBtnClasses}
              disabled={!innerDate}>{i18n.clearBtn}</button
            >
          {/if}
        </div>
      {/if}
    {:else}
      <Time
        date={innerDate}
        hasDateComponent={resolvedMode !== "time"}
        bind:this={timeEl}
        showMeridian={format.match("p|P")}
        {i18n}
        on:time={onDate}
        on:switch={onModeSwitch}
        on:close={onTimeClose}
      />
    {/if}
  </div>
{/if}
