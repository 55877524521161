<script>
  import axios from "axios";
  import { getCSRFToken } from "../../utils";
  import { onMount, setContext } from "svelte";

  import ProjectForm from "./ProjectForm.svelte";

  export let contactsApiUrl;
  export let clientsApiUrl;
  export let projectsApiUrl;
  export let currentOrgId;

  let projectForm;
  let formIsValid;
  let user_data = {};

  export let user = "";
  export let currentOrg = "";

  setContext("user", user);
  setContext("currentOrg", currentOrg);

  let project = {
    users: [],
    organization: currentOrg,
    name: "",
    client: "",
    contacts: [],
    key_contact: {},
  };
  let projectContactIds = [];
  let availableContacts = [];

  // Reassigns the contacts from selectedContact IDs
  // based on available contacts for the client.
  async function assignContacts(contactIds, availableContacts) {
    let selectedContacts = [];
    for (var i = 0; i < contactIds.length; i++) {
      for (var j = 0; j < availableContacts.length; j++) {
        if (contactIds[i] === availableContacts[j].id) {
          selectedContacts.push(availableContacts[j]);
        }
      }
    }
    project.contacts = selectedContacts;
  }

  async function create() {
    console.log(project.users);
    // check to see if user is adding contact
    if (projectForm.checkIsAddingContact()) {
    } else {
      // first validating project before posting
      formIsValid = await projectForm.validateForm();
      if (formIsValid) {
        assignContacts(projectContactIds, availableContacts);
        // post the created project object
        try {
          const response = await axios.post(`${projectsApiUrl}`, project, {
            headers: {
              "X-CsrfToken": getCSRFToken(),
            },
          });

          const newProject = response.data;
          window.location.href = `${newProject.view_project_url}`;
        } catch (e) {
          console.error("Failed to create project:", e);
        } finally {
        }
      }
    }
  }

  async function fetchUser() {
    const response = await fetch(`${user}`);

    if (response.ok) {
      const result = await response.json();
      project.users = [result];
      console.log(project);
    } else {
      error = "Failed to load project details.";
    }
  }

  onMount(async () => {
    fetchUser();
  });
</script>

<ProjectForm
  {clientsApiUrl}
  {contactsApiUrl}
  bind:projectName={project.name}
  bind:client={project.client}
  bind:key_contact={project.key_contact}
  bind:selectedContacts={projectContactIds}
  bind:availableContacts
  bind:this={projectForm}
/>
<div class="d-flex justify-content-end">
  <button type="submit" class="btn btn-primary" on:click={create}
    >Create Project</button
  >
</div>
