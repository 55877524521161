<script>
  import { onMount } from "svelte";
  import axios from "axios";
  import Loader from "./Loader.svelte";
  import Tasks from "./Tasks.svelte";
  import { getCSRFToken } from "../utils";
  import { fade, fly } from "svelte/transition";
  import { setContext } from "svelte";
  import { showCompletedTasks } from "../store";
  import ProjectWebSocketComponent from "./websocket/ProjectWebSocketComponent.svelte";

  let alltaskview = true;

  export let permissions = null;
  export let userDetailApiUrl = "";
  export let orguserApiUrl = "";
  export let currentOrgId = "";
  export let tasksApiUrl;
  export let clientremindersApiUrl;
  export let projectsApiUrl;
  export let newProjectUrl;

  let user = {};

  $: showCompletedTasks.set(user.show_completed_mytasks);

  setContext("permissions", permissions);
  setContext("orguserApiUrl", orguserApiUrl);
  setContext("currentOrgId", currentOrgId);

  let loading = 1;
  let memberships;
  let tasksComponent;
  let tasksComponentLoaded = false;
  let newTaskInput;
  let existingProjectList = [];

  async function fetchMemberships() {
    const response = await fetch(
      `${orguserApiUrl + "?organization=" + currentOrgId}`
    );

    if (response.ok) {
      const result = await response.json();
      memberships = result.results;
    } else {
      error = "Failed to load project details.";
    }
  }

  async function fetchProjects() {
    const response = await fetch(`${projectsApiUrl}my_projects`);

    if (response.ok) {
      const result = await response.json();
      existingProjectList = result.projects;
    } else {
      error = "Failed to load project details.";
    }
  }

  async function fetchUser() {
    const response = await fetch(
      `${userDetailApiUrl}?ts=${new Date().getTime()}`
    );

    if (response.ok) {
      const result = await response.json();
      user = result;
    } else {
      error = "Failed to load user details.";
    }
  }

  async function toggleShowCompletedTasks() {
    showCompletedTasks.update((prev) => !prev);
    user.show_completed_mytasks = $showCompletedTasks;
    // put the updated user object
    try {
      const response = await axios.put(user.url, user, {
        headers: {
          "X-CsrfToken": getCSRFToken(),
        },
      });
    } catch (e) {
      console.error("Failed to update project:", e);
    }
  }

  onMount(async () => {
    await fetchMemberships();
    await fetchProjects();
    await fetchUser();
    loading--;
  });
</script>

<ProjectWebSocketComponent
  userid={user.id}
  roomName={currentOrgId}
  on:removedProjectUser={tasksComponent.fetchTasks()}
  on:createdNewProject={tasksComponent.fetchTasks()}
  on:changedProject={tasksComponent.fetchTasks()}
/>

<div class="d-grid gap-3 gap-lg-5">
  <!-- Card -->
  <div class="card">
    {#if loading > 0}<div class="text-center"><Loader /></div>{:else}
      <div transition:fade>
        <!-- Header -->
        <div
          class="card-header d-flex justify-content-between border-bottom align-items-center"
        >
          <div class="text-left text-sm-center">
            <h4 class="card-header-title">My Tasks</h4>
          </div>
          {#if tasksComponentLoaded}
            <!-- Dropdown -->
            <div transition:fade class="dropdown">
              <button
                class="btn btn-white btn-sm dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="bi-eye me-1" />
              </button>
              <div class="dropdown-menu dropdown-menu-end">
                <button
                  on:click={() => {
                    toggleShowCompletedTasks();
                  }}
                  class="dropdown-item {$showCompletedTasks == true
                    ? 'active'
                    : ''}">All Tasks</button
                >

                <button
                  on:click={() => {
                    toggleShowCompletedTasks();
                  }}
                  class="dropdown-item {$showCompletedTasks == false
                    ? 'active'
                    : ''}">Only Remaining Tasks</button
                >
              </div>
              <button
                on:click|preventDefault={() => {
                  newTaskInput.focus();
                  newTaskInput.click();
                }}
                class="btn btn-sm btn-primary"
                ><i class="bi bi-plus" />Add Task</button
              >
            </div>
            <!-- End Dropdown -->
          {/if}
        </div>

        <!-- End Header -->
        <Tasks
          on:loaded={() => {
            tasksComponentLoaded = true;
          }}
          bind:this={tasksComponent}
          bind:newTaskInput
          userid={user.id}
          {newProjectUrl}
          {existingProjectList}
          {alltaskview}
          {tasksApiUrl}
          {clientremindersApiUrl}
          {memberships}
        />
      </div>
    {/if}
  </div>
</div>
