<script>
  import { fade, slide } from "svelte/transition";
  import { tooltip } from "../bootstrapActivate";

  export let completed_steps = [];
  export let step;

  let revealStepInformation = false;
</script>

{#if step}
  <li class="step-item" in:fade out:slide>
    {#if completed_steps.includes(step.id)}
      <div class="step-content-wrapper align-items-center">
        <span class="step-icon step-icon-success align-self-start"
          ><i class="bi-check" /></span
        >
        <div class="step-content">
          <h6 class="text-dark mb-1">{step.name}</h6>
        </div>
      </div>
    {:else if step.dependencies == null || step.dependencies.every( (dependency) => completed_steps.includes(dependency) )}
      <div class="step-content-wrapper align-items-center">
        {#if step.href == "#"}
          <button
            class="step-icon step-icon-light checklist-button align-self-start"
            on:click={() => {
              revealStepInformation = !revealStepInformation;
            }}><i class="bi bi-three-dots text-primary" /></button
          >
          <div class="step-content">
            <h6 class="text-primary mb-1">
              {step.name}
              {#if step.description}<button
                  class="btn btn-icon btn-xs"
                  on:click={() => {
                    revealStepInformation = !revealStepInformation;
                  }}><i class="bi-info-circle-fill" /></button
                >
              {/if}
            </h6>
            {#if revealStepInformation}
              <div transition:slide>
                <small>{step.description}</small>
              </div>
            {/if}
          </div>
        {:else}
          <a href={step.href}>
            <span
              class="step-icon step-icon-light checklist-button align-self-start"
            />
          </a>
          <div class="step-content">
            <a href={step.href}>
              <h6 class="text-primary mb-1">{step.name}</h6>
            </a>
          </div>
        {/if}
      </div>
    {:else}
      <div class="step-content-wrapper align-items-center">
        <span
          use:tooltip={{
            placemenet: "bottom",
            title: "Locked. Must complete other items first.",
          }}
          class="step-icon step-icon-soft-dark checklist-button align-self-start"
          ><i class="bi-lock" /></span
        >

        <div class="step-content">
          <h6 class="text-secondary mb-1">{step.name}</h6>
        </div>
      </div>
    {/if}
  </li>
{/if}
