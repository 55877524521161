<script>
  import Popover from "svelte-easy-popover";
  import { clickOutside } from "./clickOutside";
  import { fade } from "svelte/transition";
  import { createEventDispatcher, onMount } from "svelte";
  import { debounce } from "lodash";
  import { focus } from "./focus";

  const dispatch = createEventDispatcher();

  export let isEditingDate;
  export let referenceElementDeadline = null;
  export let referenceElementDeadlineMobile = null;
  export let task;

  const clickSetTaskEstimatedDuration = debounce(() => {
    dispatch("clickSetTaskEstimatedDuration");
  }, 200);
</script>

<Popover
  triggerEvents={["click"]}
  referenceElement={referenceElementDeadline || referenceElementDeadlineMobile}
  placement="bottom-end"
  spaceAway={10}
  bind:isOpen={isEditingDate}
>
  {#if isEditingDate}
    <div
      class="popover-contents"
      transition:fade={{ duration: 250 }}
      use:clickOutside
      on:click_outside={() => {
        isEditingDate = false;
      }}
    >
      <div
        class="card p-3 task-message assign-client is-popup"
        style="width:280px"
      >
        <div class="row text-left"><h5>Estimated Duration</h5></div>
        <div class="row">
          <div class="col">
            <p class="mb-2 text-left">
              <em>Used to create preliminary project deadlines.</em>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="input-group mb-2 px-3">
            <div class="input-group-prepend">
              <button
                class="btn btn-white"
                type="button"
                on:click={() => {
                  task.duration_in_days -= 1;
                  clickSetTaskEstimatedDuration();
                }}><i class="bi-dash" /></button
              >
            </div>
            <input
              use:focus
              type="number"
              class="form-control text-center"
              placeholder="Workdays"
              min="1"
              max="365"
              bind:value={task.duration_in_days}
              on:change={() => {
                if (task.duration_in_days < 0) {
                  task.duration_in_days = null;
                } else if (task.duration_in_days > 365) {
                  task.duration_in_days = 365;
                }
                clickSetTaskEstimatedDuration();
              }}
            />
            <div class="input-group-append">
              <button
                class="btn btn-white"
                type="button"
                on:click={() => {
                  task.duration_in_days += 1;
                  clickSetTaskEstimatedDuration();
                }}><i class="bi-plus" /></button
              >
            </div>
          </div>
        </div>
        <div class="row px-3">
          <button
            type="button"
            class="btn btn-outline-danger"
            on:click={() => {
              task.duration_in_days = null;
              clickSetTaskEstimatedDuration();
              isEditingDate = false;
            }}>Clear</button
          >
        </div>
      </div>
    </div>
  {/if}
</Popover>
