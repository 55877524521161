<script>
  import { createEventDispatcher } from "svelte";
  import { getCSRFToken } from "../../utils";
  import { tooltip } from "../bootstrapActivate";

  export let clientReminder;
  export let task;
  import dayjs from "dayjs";
  export let i;

  let now = dayjs();
  let clientReminderTooltipDescription;
  let daysAvailableBetweenNowAndDeadline = dayjs(task.deadline).diff(
    now,
    "Days"
  );
  let daysBeforeDeadline = clientReminder.days_before_deadline;

  const dispatch = createEventDispatcher();

  $: clientReminderTooltipDescription = getClientReminderStatus(clientReminder);
  $: daysAvailableBetweenNowAndDeadline = dayjs(task.deadline).diff(
    now,
    "Days"
  );

  function getClientReminderStatus(clientReminder) {
    if (clientReminder.is_sent) {
      return (
        "Client reminder was sent on " +
        dayjs(clientReminder.was_sent_at).format("ddd, MMM D")
      );
    } else if (daysAvailableBetweenNowAndDeadline < 0) {
      return "If the deadline has already passed, you need to change it to a future date to enable auto-reminders.";
    } else if (clientReminder.is_disabled) {
      return "Not enough time to send auto-reminder. Update deadline or reminder.";
    } else {
      return "";
    }
  }

  async function updateClientReminder() {
    if (daysBeforeDeadline > daysAvailableBetweenNowAndDeadline) {
      clientReminder.days_before_deadline = daysAvailableBetweenNowAndDeadline;
    }
    await fetch(clientReminder.url + "update_day/", {
      method: "PUT",
      body: JSON.stringify({
        ...clientReminder,
        days_before_deadline: daysBeforeDeadline,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CsrfToken": getCSRFToken(),
      },
      credentials: "include",
    });
    dispatch("checkOtherClientReminders", clientReminder);
  }
</script>

{#if clientReminder.is_sent}
  <!-- Form Radio -->
  <label
    class="form-control bg-soft-success"
    for="formControlRadioEg{task.id + '-' + i}"
    use:tooltip={{
      placement: "top",
      title: clientReminderTooltipDescription,
    }}
  >
    <span class="form-check">
      <input
        type="checkbox"
        class="form-check-input success"
        name="formControlRadioEg"
        id="formControlRadioEg{task.id + '-' + i}"
        bind:checked={clientReminder.is_sent}
        on:change={updateClientReminder}
        disabled={clientReminder.is_sent}
      />
      <span class="form-check-label text-success"
        >Sent {dayjs(clientReminder.was_sent_at).format("ddd, MMM D")}</span
      >
    </span>
  </label>
  <!-- End Form Radio -->
{:else if clientReminder.days_before_deadline == 0}
  <!-- Form Radio -->
  <label
    class="form-control {clientReminder.is_disabled ? 'bg-soft-secondary' : ''}"
    for="formControlRadioEg{task.id + '-' + i}"
    use:tooltip={{
      placement: "top",
      title: clientReminderTooltipDescription,
    }}
  >
    <span class="form-check">
      <input
        type="checkbox"
        class="form-check-input"
        name="formControlRadioEg"
        id="formControlRadioEg{task.id + '-' + i}"
        bind:checked={clientReminder.is_active}
        on:change={updateClientReminder}
        disabled={clientReminder.is_disabled}
      />
      <span
        class="form-check-label {clientReminder.is_disabled
          ? 'text-secondary'
          : 'text-dark'}">On Due Date</span
      >
    </span>
  </label>
  <!-- End Form Radio -->
{:else}
  <!-- Form Radio -->
  <label
    class="form-control d-inline-flex align-items-center {clientReminder.is_disabled
      ? 'bg-soft-secondary'
      : ''}"
    for="formControlRadioEg{task.id + '-' + i}"
    use:tooltip={{
      placement: "top",
      title: clientReminderTooltipDescription,
    }}
  >
    <span class="form-check d-inline-flex align-items-center">
      <input
        type="checkbox"
        class="form-check-input"
        name="formControlRadioEg"
        id="formControlRadioEg{task.id + '-' + i}"
        bind:checked={clientReminder.is_active}
        on:change={() => {
          if (daysBeforeDeadline <= 0) {
            daysBeforeDeadline = 1;
          }
          updateClientReminder();
        }}
        disabled={clientReminder.is_disabled}
      />
      <div class="d-inline-flex align-items-center">
        <input
          type="number"
          name="quantity"
          min="1"
          max="31"
          bind:value={daysBeforeDeadline}
          on:change={() => {
            if (daysBeforeDeadline <= 0) {
              daysBeforeDeadline = 1;
            }
            updateClientReminder();
          }}
          class="input form-check-label reminder-span border-0 border-bottom border-primary border-2 text-center fw-bold text-dark px-0 pb-0 ms-2"
        />
        <span
          class="form-check-label {clientReminder.is_disabled
            ? 'text-secondary'
            : 'text-dark'}"
        >
          Days Before</span
        >
      </div>
    </span>
  </label>
  <!-- End Form Radio -->
{/if}

<style scoped>
</style>
