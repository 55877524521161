<script>
  import { onMount } from "svelte";
  import { getCSRFToken } from "../utils";
  import Loader from "./Loader.svelte";
  import ReviewTask from "./ReviewTask.svelte";
  import { fade } from "svelte/transition";
  import dayjs from "dayjs";
  import relativeTime from "dayjs/plugin/relativeTime";
  import { stickyBlock } from "./bootstrapActivate";

  dayjs.extend(relativeTime);

  export let projectid = "";
  export let organizationProfileImageUrl = null;
  export let organizationName = null;
  export let projectsApiUrl;
  export let tasksApiUrl;

  let project = {};
  let client = {};
  let tasks = [];
  let assigned_tasks = [];
  let error = "";

  let taskVisibility = "All Tasks";

  let loading = 1;

  async function load(callback) {
    ++loading;
    try {
      const result = await callback();
      return result;
    } finally {
      --loading;
    }
  }

  async function fetchProject() {
    if (projectid != "") {
      const response = await fetch(
        `${projectsApiUrl}${projectid}?ts=${new Date().getTime()}`
      );

      if (response.ok) {
        const result = await response.json();
        if (result.is_template == true) {
          projectIsTemplate = true;
        }
        project = result;
      } else {
        error = "Failed to load project details.";
      }
    }
  }

  async function fetchTasks() {
    if (projectid != "") {
      const response = await fetch(`${tasksApiUrl}?project=${projectid}`);
      if (response.ok) {
        const result = await response.json();
        tasks = result.results.sort((a, b) => a.order_number - b.order_number);
        assigned_tasks = tasks.filter(function (el) {
          return el.is_assigned_to_client == true;
        });
      } else {
        error = "Failed to load tasks.";
      }
    }
  }

  async function fetchClient() {
    const response = await fetch(`${project.client}`);

    if (response.ok) {
      const result = await response.json();
      client = result;
    } else {
      error = "Failed to load project details.";
    }
  }

  async function completeTask(event, task) {
    await fetch(task.url + "is_complete__client_review/", {
      method: "PUT",
      body: JSON.stringify({
        ...task,
        is_complete: !task.is_complete,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CsrfToken": getCSRFToken(),
      },
      credentials: "include",
    });
    await fetchTasks();
    await fetchProject();
  }

  function scrollIntoView({ target }) {
    const el = document.querySelector(target.getAttribute("href"));
    if (!el) return;
    el.scrollIntoView({
      behavior: "smooth",
    });
    var current = document.getElementsByClassName(" step-active");

    if (current.length > 0) {
      current[0].className = current[0].className.replace(" step-active", "");
    }

    el.className += " step-active";
  }

  onMount(async () => {
    await fetchProject();
    await fetchClient();
    await fetchTasks();
    --loading;
  });
</script>

{#if loading > 0}
  <div in:fade class="d-flex justify-content-center">
    <Loader />
  </div>
{:else}
  <main id="content" in:fade>
    <!-- Content Section -->
    <div
      class="container content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10"
    >
      <div class="row">
        <div class="col-lg-4 mb-7 mb-lg-0">
          <!-- Sticky Block -->
          <div id="stickyBlockStartPoint">
            <div
              class="js-sticky-block"
              use:stickyBlock={{
                parentSelector: "#stickyBlockStartPoint",
                breakpoint: "lg",
                startPoint: "#stickyBlockStartPoint",
                endPoint: "#stickyBlockEndPoint",
                stickyOffsetTop: 20,
              }}
            >
              <!-- Card -->
              <div class="card">
                <div class="card-header border-bottom text-center">
                  {#if organizationProfileImageUrl}
                    <img
                      class="avatar avatar-xxl avatar-circle mb-2"
                      src={organizationProfileImageUrl}
                      alt="Logo"
                    />
                  {/if}
                  <div class="row pt-2">
                    <h3>{organizationName}</h3>
                  </div>
                </div>
                <div class="card-body">
                  <dl class="row">
                    <dt class="col-sm-4 col-lg-12 col-xl-4">Project</dt>
                    <dd class="col-sm-8 col-lg-12 col-xl-8">{project.name}</dd>
                  </dl>
                  <!-- End Row -->

                  <dl class="row">
                    <dt class="col-sm-4 col-lg-12 col-xl-4">Progress</dt>
                    <dd class="col-sm-8 col-lg-12 col-xl-8">
                      <div class="progress" style="height: 15px;">
                        <div
                          class="progress-bar bg-primary"
                          role="progressbar"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          aria-valuenow={project.percent_complete}
                          style="width:{project.percent_complete}%"
                        />
                      </div>
                      <div>
                        <span class="small text-right"
                          >{project.percent_complete}% Complete</span
                        >
                      </div>
                    </dd>
                  </dl>

                  <dl class="row">
                    <dt class="col-sm-4 col-lg-12 col-xl-4">Client</dt>
                    <dd class="col-sm-8 col-lg-12 col-xl-8">{client.name}</dd>
                  </dl>
                  <!-- End Row -->

                  <dl class="row">
                    <dt class="col-sm-4 col-lg-12 col-xl-4">
                      Contact{#if project.contacts.length > 1}s{/if}
                    </dt>
                    {#each project.contacts as contact}
                      <!-- {% if not forloop.first %}<dt class="col-sm-4 col-lg-12 col-xl-4"></dt>{% endif %} -->
                      <dd class="col-sm-8 mb-0 col-lg-12 col-xl-8">
                        {contact.first_name + " " + contact.last_name}
                      </dd>
                    {/each}
                  </dl>
                  <!-- End Row -->
                </div>
              </div>
              <!-- End Card -->
              <!-- Card -->
              <div class="card mt-3">
                <div class="card-header border-bottom">
                  <h3 class="mb-0">Assigned to You</h3>
                </div>
                <div class="card-body">
                  <ul class="step step-icon-xs step-border-last-0 mb-0">
                    {#each assigned_tasks as task, i}
                      {#if task.is_assigned_to_client}
                        <li class="step-item">
                          <div class="step-content-wrapper">
                            <span class="step-icon step-icon-soft-primary"
                              >{i + 1}</span
                            >
                            <div class="step-content ">
                              <h5 class="step-title text-break mb-2">
                                {task.name}
                              </h5>
                              <div
                                class="d-flex justify-content-between align-items-center"
                              >
                                <div>
                                  {#if task.deadline}
                                    <div
                                      class="badge bg-soft-primary text-primary"
                                    >
                                      Due {dayjs(task.deadline).format(
                                        "ddd, MMM D"
                                      )}
                                    </div>
                                  {/if}
                                </div>
                                <div>
                                  <a
                                    href="#task-{task.id}"
                                    class="btn btn-sm p-0 text-primary"
                                    on:click|preventDefault={scrollIntoView}
                                    >Show Task</a
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      {/if}
                    {:else}
                      <!-- <img class="card-img w-50 mx-auto d-block" src="{% static 'pages/svg/illustrations/oc-relaxing.svg' %}"
                      alt="Sit back and relax"> -->
                      <div class="row mt-3">
                        <p class="mb-0">
                          No tasks for now. Sit back, relax, and watch the
                          project roll on!
                        </p>
                      </div>
                    {/each}
                  </ul>
                </div>
              </div>
              <!-- End Card -->
            </div>
          </div>
        </div>
        <div class="col-lg">
          <!-- Card -->
          <div class="card">
            <div
              class="card-header d-flex justify-content-between align-items-center border-bottom"
            >
              <div>
                <h4 class="card-header-title">Project Tasks</h4>
              </div>
              <div>
                <!-- Dropdown -->
                <div class="dropdown">
                  <button
                    class="btn btn-white btn-sm dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="bi-eye me-1" />
                    {taskVisibility}
                  </button>
                  <div
                    class="dropdown-menu dropdown-menu-end"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <button
                      on:click={() => {
                        taskVisibility = "All Tasks";
                      }}
                      class="dropdown-item">All Tasks</button
                    >
                    {#if tasks.some((task) => task.is_assigned_to_client)}
                      <button
                        on:click={() => {
                          taskVisibility = "Only My Tasks";
                        }}
                        class="dropdown-item">Only My Tasks</button
                      >
                    {/if}
                    <button
                      on:click={() => {
                        taskVisibility = "Only Remaining Tasks";
                      }}
                      class="dropdown-item">Only Remaining Tasks</button
                    >
                  </div>
                </div>
                <!-- End Dropdown -->
              </div>
            </div>
            <!-- Body -->
            <div class="card-body">
              <!-- Step -->
              <ul class="step step-icon-xs step-border-last-0">
                {#each tasks as task}
                  {#if (taskVisibility === "Only My Tasks" && task.is_assigned_to_client) || (taskVisibility === "Only Remaining Tasks" && !task.is_complete) || taskVisibility === "All Tasks"}
                    <li
                      class="step-item"
                      id="task-{task.id}"
                      style="scroll-margin-top: 22px;"
                      transition:fade
                    >
                      <ReviewTask
                        {task}
                        {organizationProfileImageUrl}
                        {organizationName}
                        on:clickIsComplete={(event) =>
                          completeTask(event, task)}
                      />
                    </li>
                  {/if}
                {/each}
                <!-- Step Item -->
                <li class="step-item">
                  <div class="step-content-wrapper">
                    <span
                      class="step-icon {project.is_complete
                        ? 'step-icon-success'
                        : 'step-icon-soft-primary'}"
                      ><i class="bi bi-flag" /></span
                    >
                    <div class="step-content">
                      <h4 class="step-title">Project Complete</h4>
                      <p class="step-text">
                        We can't wait to get your project to the finish line for
                        you!
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
              <!-- End Step -->
              <!-- End Sticky End Point -->
              <div id="stickyBlockEndPoint" />
            </div>
            <!-- End Body -->
          </div>
          <!-- End Card -->
        </div>
        <!-- End Col -->
      </div>
      <!-- End Row -->
    </div>
    <!-- End Content Section -->
  </main>
{/if}
