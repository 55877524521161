<script>
  import ClientReminder from "./ClientReminder.svelte";
  import { createEventDispatcher, onMount } from "svelte";
  import dayjs from "dayjs";

  export let task;
  export let canReSendReminder;
  export let clientremindersApiUrl;

  let clientReminders = [];

  const dispatch = createEventDispatcher();

  async function handleClickOpenDeadline() {
    dispatch("openDeadline");
  }

  const clickSendReminderAssignedToClient = () => {
    dispatch("clickSendReminderAssignedToClient");
  };

  export async function fetchClientReminders() {
    if (task != "") {
      const response = await fetch(`${clientremindersApiUrl}?task=${task.id}`);
      if (response.ok) {
        const result = await response.json();
        clientReminders = result.results;
      } else {
        error = "Failed to load tasks reminders.";
      }
    }
  }

  async function checkOtherClientReminders(event) {
    const clientReminder = event.detail;

    if (clientReminder.is_active) {
      if (clientReminders) {
        for (let i = 0; i < clientReminders.length; i++) {
          if (clientReminder.id != clientReminders[i].id) {
            if (
              clientReminders[i].days_before_deadline ==
                clientReminder.days_before_deadline &&
              clientReminders[i].is_active
            ) {
              if (task != "") {
                const response = await fetch(
                  `${clientremindersApiUrl}${clientReminders[i].id}`
                );
                if (response.ok) {
                  const result = await response.json();
                  clientReminders[i] = result;
                } else {
                  error = "Failed to load other task reminder.";
                }
              }
            }
          }
        }
      }
    }
    await fetchClientReminders();
  }

  onMount(async () => {
    await fetchClientReminders();
  });
</script>

<span class="divider-center">Auto-Reminders</span>
{#if task.deadline == null}
  <div class="col">
    <p class="mb-2 text-center">
      <em> Add a task deadline to enable auto-reminders.</em>
    </p>
  </div>
  <div class="d-none d-md-grid">
    <button
      type="button"
      class="btn btn-outline-primary btn-sm"
      on:click={handleClickOpenDeadline}
      ><i class="bi-calendar-event" /> Add Deadline</button
    >
  </div>
{:else}
  {#each clientReminders as clientReminder, i}
    <div
      class="col-sm {i % 2 != 0 ? 'ps-sm-1' : 'pe-sm-1'}{i >
      clientReminders.length - 3
        ? ' mb-sm-0'
        : ' mb-sm-2'} mb-2"
    >
      <ClientReminder
        {clientReminder}
        {task}
        {i}
        on:checkOtherClientReminders={(event) =>
          checkOtherClientReminders(event)}
      />
    </div>
    {#if i % 2 != 0}<div class="w-100" />{/if}
  {/each}
{/if}
{#if task.is_assigned_to_client}
  <span class="divider-center">Or</span>
  <div class="d-grid">
    <button
      type="button"
      class="btn btn-primary btn-sm"
      on:click={clickSendReminderAssignedToClient}
      disabled={!canReSendReminder}
      ><i class="bi-megaphone me-1" /> Remind Client Now</button
    >
    {#if task.last_reminder_sent_at}
      <small class="d-block"
        >Last one-off reminder sent {dayjs(
          task.last_reminder_sent_at
        ).fromNow()}. {#if !canReSendReminder}Please allow 24 hours to send
          another reminder.{/if}</small
      >
    {/if}
  </div>
{/if}

<style scoped>
</style>
