<script>
  import { onMount } from "svelte";
  import axios from "axios";
  import { getCSRFToken } from "../../utils";
  import Popover from "svelte-easy-popover";
  import { fade, slide } from "svelte/transition";
  import { showCompletedTasks } from "../../store";
  import { stopTyping } from "../onStopTyping";
  import { getContext } from "svelte";
  import { includes } from "lodash";
  import { projKickoffEmailSchema } from "../../schema";
  import { extractErrors } from "../../utils";
  import { autoResize } from "../bootstrapActivate";

  let permissions;
  permissions = getContext("permissions");

  export let templateToProjectUrl = "";
  export let editTemplateUrl = "";
  export let templatesUrl;
  export let project = {};
  export let client = {};
  export let exclude = {
    show_completed_tasks: null,
    change_project_status: null,
    show_client_review: null,
    edit_project_details: null,
    edit_template_details: null,
    create_project_template: null,
    archive_project: null,
    archive_template: null,
  };
  export let projectView;

  $: showCompletedTasks.set(project.show_completed_tasks);

  let organization = {};
  let actions;
  let showActions = false;
  let showChangeStatusDropdown = false;
  let showChangeReviewDropdown = false;
  let showProjectReviewPassword = false;
  let copiedReviewLink = false;
  let copiedPassword = false;

  let showStatusModal = false;
  let statusText = "";
  let backgroundUrl = "";
  let highFiveUrl = "";

  let errors = {};

  let wizardStep = 0;

  // kickoff email fields
  let kickoffEmail = {
    heading: "We're ready to rock!",
    subheading: `We're so excited to get started on ${project.name}.`,
    message: `We've begun working on your ${project.name} project!\n\nWe'll keep you posted on our progress with email updates like these. If you have any questions or concerns, simply reply to those emails and we'll be in touch.`,
    closing:
      "If you have any questions at all, just respond to this email!\n\nBest,",
  };

  $: if (project.password) {
    showProjectReviewPassword = true;
  }

  function closeModal() {
    wizardStep = 0;
    showStatusModal = false;
  }

  async function startProject() {
    statusText = "Starting Project...";
    project.is_upcoming = false;

    // put the updated project object
    try {
      const response = await axios.put(
        project.url + "start_project/",
        project,
        {
          headers: {
            "X-CsrfToken": getCSRFToken(),
          },
        }
      );
      return true;
    } catch (e) {
      console.error("Failed to start project:", e);
      return false;
    }
  }

  async function sendKickoffEmail() {
    statusText = "Sending...";
    try {
      const response = await axios.put(
        project.url + "send_kickoff_email/",
        { ...project, ...kickoffEmail },
        {
          headers: {
            "X-CsrfToken": getCSRFToken(),
          },
        }
      );
      return true;
    } catch (e) {
      console.error("Failed to send kickoff email:", e);
      return false;
    }
  }

  async function toggleShowCompletedTasks() {
    showCompletedTasks.update((prev) => !prev);
    project.show_completed_tasks = $showCompletedTasks;
    // put the updated project object
    try {
      const response = await axios.put(project.url, project, {
        headers: {
          "X-CsrfToken": getCSRFToken(),
        },
      });
    } catch (e) {
      console.error("Failed to update project:", e);
    }
  }

  async function toggleUpcomingProject() {
    project.is_upcoming = !project.is_upcoming;
    // put the updated project object
    try {
      const response = await axios.put(project.url, project, {
        headers: {
          "X-CsrfToken": getCSRFToken(),
        },
      });
    } catch (e) {
      console.error("Failed to update project:", e);
    }
    // if (projectView) {
    //   window.location.reload();
    // }
  }

  async function toggleCompleteProject() {
    project.is_complete = !project.is_complete;
    // put the updated project object
    try {
      const response = await axios.put(project.url, project, {
        headers: {
          "X-CsrfToken": getCSRFToken(),
        },
      });
      // if (projectView) {
      //   window.location.reload();
      // }
    } catch (e) {
      console.error("Failed to update project:", e);
    }
  }

  async function archiveProject() {
    project.is_archived = !project.is_archived;
    // put the updated project object
    try {
      const response = await axios.put(project.url, project, {
        headers: {
          "X-CsrfToken": getCSRFToken(),
        },
      });
    } catch (e) {
      console.error("Failed to update project:", e);
    }
    // if (projectView) {
    //   window.location.reload();
    // }
  }

  async function toggleProjectReview() {
    project.has_review_page = !project.has_review_page;
    // put the updated project object
    try {
      const response = await axios.put(project.url, project, {
        headers: {
          "X-CsrfToken": getCSRFToken(),
        },
      });
    } catch (e) {
      console.error("Failed to update project:", e);
    }
  }

  async function setProjectPassword() {
    try {
      const response = await axios.put(project.url, project, {
        headers: {
          "X-CsrfToken": getCSRFToken(),
        },
      });
    } catch (e) {
      console.error("Failed to update project:", e);
    }
  }

  onMount(async () => {
    showCompletedTasks.set(project.show_completed_tasks);
  });

  // CREATE TEMPLATE FROM PROJECT
  async function createTemplateFromProject() {
    fetch(`${project.url}create_template_from_project/`, {
      method: "PUT",
      body: JSON.stringify({
        ...project,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CsrfToken": getCSRFToken(),
      },
      credentials: "include",
    }).then((response) => {
      if (response.ok) {
        window.location.href = `${templatesUrl}`;
      } else {
        console.error("Failed to create template from project.");
      }
    });
  }

  // CREATE PROJECT FROM TEMPLATE
  async function createProjectFromTemplate() {
    window.location.href = `${templateToProjectUrl}`;
  }

  async function fetchOrganization() {
    const response = await fetch(`${project.organization}`);

    if (response.ok) {
      const result = await response.json();
      organization = result;
    } else {
      console.error("Failed to load project organization details.");
    }
  }

  // Static Resources
  async function fetchStaticResource(resource) {
    const response = await fetch(`/api/static-resource-url/${resource}`);

    if (response.ok) {
      const result = await response.json();
      return result.url;
    } else {
      console.error("Failed to load static resource.");
    }
  }

  onMount(async () => {
    await fetchOrganization();
    backgroundUrl = await fetchStaticResource(
      "/pages/svg/components/shape-6.svg"
    );
    highFiveUrl = await fetchStaticResource(
      "/pages/svg/illustrations/oc-handshake.svg"
    );
  });
</script>

<div class="d-flex justify-content-end align-items-center">
  {#if !exclude.change_project_status && permissions.includes("change_projects")}
    {#if project.is_upcoming}
      <div>
        <button
          class="btn {project.is_upcoming
            ? 'btn-success'
            : project.percent_complete === 100 &&
              !project.is_complete &&
              !project.is_archived
            ? 'btn-success'
            : ''} btn-sm me-2"
          on:click={() => {
            showStatusModal = !showStatusModal;
          }}
        >
          <i
            class={project.is_upcoming
              ? "bi-play"
              : project.percent_complete === 100 &&
                !project.is_complete &&
                !project.is_archived
              ? "bi-trophy"
              : ""}
          />
          <span class="d-none d-md-inline"
            >{project.is_upcoming ? "Start Project" : ""}</span
          >
          <!-- <i class="bi bi-rocket-takeoff" /> -->
        </button>
      </div>
    {:else}
      <div class="badge text-secondary me-2">
        <span
          class="legend-indicator {project.is_archived
            ? 'bg-secondary'
            : project.is_complete
            ? 'bg-primary'
            : !project.is_upcoming
            ? 'bg-success'
            : ''}"
        />
        {project.is_archived
          ? "Archived"
          : project.is_complete
          ? "Complete"
          : !project.is_upcoming
          ? "Active"
          : ""}
      </div>
    {/if}
  {/if}
  {#if showStatusModal}
    <!-- Modal -->
    <div
      id="exampleModalCenteredScrollable"
      class="modal show"
      tabindex="-1"
      role="dialog"
    >
      <div
        class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg"
        transition:fade
        role="document"
      >
        <div class="modal-content">
          {#if wizardStep == 0}
            <div class="modal-header">
              <h4 class="modal-title" id="exampleModalCenteredScrollableTitle">
                Start Project
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                on:click={closeModal}
              />
            </div>
            <div
              class="modal-body px-0 px-sm-5"
              style="background: url({backgroundUrl}) center bottom no-repeat;"
            >
              <!-- Content -->
              <div class="container">
                <div class="text-center bg-img-start py-6">
                  <div class="mb-5">
                    <h2>Let's start your project!</h2>
                    <p>
                      Would you like to send a kickoff email to {client.name}?
                    </p>
                  </div>
                  <div class="col">
                    <button
                      disabled={statusText != ""}
                      class="btn btn-primary btn-transition"
                      on:click={() => {
                        wizardStep++;
                      }}>Yes, Compose Kickoff Email</button
                    >
                  </div>
                  <div class="col">
                    <button
                      disabled={statusText != ""}
                      class="btn btn-link"
                      on:click={async () => {
                        const success = await startProject();
                        if (success) {
                          statusText = "";
                          closeModal();
                        }
                      }}
                      >{#if statusText}<div
                          class="spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span class="visually-hidden">Loading...</span>
                        </div>{/if}
                      {statusText == ""
                        ? "No, Just Start Project"
                        : statusText}</button
                    >
                  </div>
                </div>
              </div>
              <!-- Content -->
            </div>
            <div class="modal-footer justify-content-center">
              <button
                type="button"
                class="btn btn-white"
                data-bs-dismiss="modal"
                on:click={closeModal}
                ><i class="me-1 bi bi-arrow-left" /> Nevermind, Don't Start Project</button
              >
            </div>
          {:else if wizardStep == 1}
            <div class="modal-header">
              <h4 class="modal-title" id="exampleModalCenteredScrollableTitle">
                Edit Kickoff Email
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                on:click={closeModal}
              />
            </div>
            <div class="modal-body px-0 px-sm-5">
              <!-- Content -->
              <div class="container">
                <!-- Form -->
                <form>
                  <div class="card card-bordered">
                    <div class="card-body">
                      {#if organization.profile_image}
                        <div class="d-flex justify-content-center">
                          <span class="avatar avatar-xl avatar-circle">
                            <img
                              class="avatar-img"
                              src={organization.profile_image}
                              alt="Company Logo"
                            />
                          </span>
                        </div>
                      {/if}
                      <!-- Form -->
                      <div>
                        <input
                          type="text"
                          class="form-control text-center kickoff-email p-1 {errors.heading
                            ? 'is-invalid'
                            : ''}"
                          name="heading"
                          id="heading"
                          placeholder="We're ready to rock!"
                          bind:value={kickoffEmail.heading}
                          style="font-size:1.3em; font-weight: bold;"
                        />

                        {#if errors.heading}
                          <span class="invalid-feedback">{errors.heading}</span>
                        {/if}
                      </div>
                      <div class="mb-4">
                        <input
                          type="text"
                          class="form-control text-center kickoff-email p-1 fst-italic {errors.subheading
                            ? 'is-invalid'
                            : ''}"
                          name="subheading"
                          id="subheading"
                          placeholder="We're ready to rock!"
                          bind:value={kickoffEmail.subheading}
                        />
                        {#if errors.subheading}
                          <span class="invalid-feedback"
                            >{errors.subheading}</span
                          >
                        {/if}
                      </div>
                      <!-- End Form -->
                      <p class="px-3">Hi {client.name},</p>

                      <!-- Form -->
                      <div class="mb-4">
                        <textarea
                          class="form-control kickoff-email {errors.message
                            ? 'is-invalid'
                            : ''}"
                          name="message"
                          id="message"
                          placeholder="Message"
                          rows="1"
                          bind:value={kickoffEmail.message}
                          use:autoResize={kickoffEmail.message}
                        />
                        {#if errors.message}
                          <span class="invalid-feedback">{errors.message}</span>
                        {/if}
                      </div>
                      <!-- End Form -->

                      <div class="px-3">
                        <div>
                          <label class="form-label" for="clientDashboard"
                            >Enable Client Dashboard</label
                          >
                        </div>
                        <!-- Radio Button Group -->
                        <div class="btn-group btn-group-segment" role="group">
                          <input
                            type="radio"
                            class="btn-check"
                            name="enableClientDashboard"
                            id="enableClientDashboard"
                            autocomplete="off"
                            on:change={toggleProjectReview}
                            checked={project.has_review_page}
                          />
                          <label class="btn btn-sm" for="enableClientDashboard"
                            ><i class="bi-hand-thumbs-up me-1" /> Yes</label
                          >

                          <input
                            type="radio"
                            class="btn-check"
                            name="disableClientDashboard"
                            id="disableClientDashboard"
                            autocomplete="off"
                            on:change={toggleProjectReview}
                            checked={!project.has_review_page}
                          />
                          <label class="btn btn-sm" for="disableClientDashboard"
                            ><i class="bi-hand-thumbs-down me-1" /> No</label
                          >
                        </div>
                        <!-- End Radio Button Group -->
                      </div>
                      {#if project.has_review_page}
                        <div class="px-3" transition:slide={{ duration: 500 }}>
                          <p class="mt-4">
                            <em
                              >To view the project and track status, click the
                              button below.</em
                            >
                            {project.password}
                            {#if project.password}
                              This link is password protected and the password
                              is: <strong>{project.password}</strong>{/if}
                          </p>
                          <button
                            class="btn btn-secondary"
                            disabled
                            style="width:100%">View Project Portal</button
                          >
                        </div>
                      {/if}
                      <!-- Form -->
                      <div class="mt-4 mb-4">
                        <textarea
                          rows="1"
                          class="form-control kickoff-email {errors.closing
                            ? 'is-invalid'
                            : ''}"
                          name="closing"
                          id="closing"
                          placeholder="Final Paragraph"
                          bind:value={kickoffEmail.closing}
                          use:autoResize={kickoffEmail.closing}
                        />
                        {#if errors.closing}
                          <span class="invalid-feedback">{errors.closing}</span>
                        {/if}
                        <p class="mt-2 px-3">- {organization.name}</p>
                      </div>
                      <!-- End Form -->
                    </div>
                  </div>
                </form>
                <!-- End Form -->
              </div>
              <!-- Content -->
            </div>
            <div class="modal-footer">
              <button
                disabled={statusText != ""}
                type="button"
                class="btn btn-white"
                data-bs-dismiss="modal"
                on:click={() => {
                  wizardStep--;
                }}>Back</button
              >
              <button
                type="button"
                class="btn btn-primary"
                on:click={async () => {
                  errors = {};
                  return projKickoffEmailSchema
                    .validate(kickoffEmail, { abortEarly: false })
                    .then(async () => {
                      const startsuccess = await startProject();
                      const sendsuccess = await sendKickoffEmail();
                      if (startsuccess && sendsuccess) {
                        statusText = "";
                        wizardStep = 2;
                      }
                    })
                    .catch((err) => {
                      errors = extractErrors(err);
                      console.log(errors);
                    });
                }}
                disabled={statusText != ""}
                >{#if statusText}<div
                    class="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>{/if}
                {statusText == "" ? "Send Kickoff Email" : statusText}</button
              >
            </div>
          {:else if wizardStep == 2}
            <div class="modal-header">
              <h4 class="modal-title" id="exampleModalCenteredScrollableTitle">
                Success
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                on:click={closeModal}
              />
            </div>
            <div class="modal-body px-0 px-sm-5">
              <!-- Content -->
              <div class="container">
                <div class="text-center bg-img-start py-6">
                  <div class="mb-5">
                    <img
                      alt="High Five!"
                      src={highFiveUrl}
                      style="max-width: 15rem;"
                    />
                  </div>
                  <div>
                    <h2>Project Started!</h2>
                    <p>Let's crush this thing.</p>
                  </div>
                </div>
              </div>
              <!-- Content -->
            </div>
            <div class="modal-footer justify-content-center">
              <button
                type="button"
                class="btn btn-primary"
                data-bs-dismiss="modal"
                on:click={closeModal}
                >Start Tackling Tasks<i
                  class="ms-1 bi bi-arrow-right"
                /></button
              >
            </div>
          {/if}
        </div>
      </div>
    </div>
    <!-- End Modal -->
  {/if}

  <button
    class="btn btn-sm btn-white"
    on:click={() => {
      showActions = !showActions;
    }}
    bind:this={actions}><span><i class="bi bi-gear-wide" /></span></button
  >
  <Popover
    triggerEvents={["click"]}
    referenceElement={actions}
    placement="bottom-end"
    spaceAway={0}
    bind:isOpen={showActions}
  >
    {#if showActions}
      <div
        class="popover-contents navbar"
        transition:fade={{ duration: 150 }}
        style="max-width:300px"
      >
        <div class="navbar dropdown-menu">
          {#if !exclude.show_completed_tasks && permissions.includes("change_projects")}
            <button class="dropdown-item" on:click={toggleShowCompletedTasks}
              ><i
                class="me-1 {$showCompletedTasks ? 'bi-eye' : 'bi-eye-slash'}"
              />
              {#if $showCompletedTasks}Hide{:else}Show{/if} Completed Tasks
            </button>
          {/if}
          {#if !exclude.change_project_status && permissions.includes("change_projects")}
            <button
              class="dropdown-item dropdown-toggle {showChangeStatusDropdown
                ? 'above'
                : 'below'}"
              on:click={() => {
                showChangeStatusDropdown = !showChangeStatusDropdown;
              }}
            >
              <i class="me-2 bi bi-flag" /> Change Project Status
            </button>
            {#if showChangeStatusDropdown}
              <div transition:slide>
                <div class="dropdown-divider pt-0 mt-0" />
                {#if project.is_upcoming}
                  <!-- If project is upcoming, start project. -->
                  <button
                    class="ps-3 dropdown-item"
                    on:click={toggleUpcomingProject}
                  >
                    Mark as Active
                  </button>
                {:else if !project.is_complete}
                  <!-- If project is active, move to upcoming or move to completed. -->
                  <button
                    class="ps-3 dropdown-item"
                    on:click={toggleUpcomingProject}
                  >
                    Mark as Upcoming
                  </button>
                  <button
                    class="ps-3 dropdown-item"
                    on:click={toggleCompleteProject}
                  >
                    Mark as Complete
                  </button>
                {:else}
                  <!-- If project is not upcoming and is complete  -->
                  <button
                    class="ps-3 dropdown-item"
                    on:click={toggleCompleteProject}
                  >
                    Mark as Active
                  </button>
                {/if}
                <div class="dropdown-divider pb-0 mb-0" />
              </div>
            {/if}
          {/if}
          {#if !exclude.show_client_review && permissions.includes("change_projects")}
            <button
              class="dropdown-item {project.has_review_page
                ? 'dropdown-toggle'
                : ''}
                {showChangeReviewDropdown ? 'above' : 'below'}"
              on:click={() => {
                if (project.has_review_page) {
                  showChangeReviewDropdown = !showChangeReviewDropdown;
                } else {
                  toggleProjectReview();
                  showChangeReviewDropdown = true;
                }
              }}
            >
              <i class="me-2 bi bi-chat-right-text" />{project.has_review_page
                ? "Edit Client Review Link"
                : "Enable Client Review Page"}
            </button>
            {#if showChangeReviewDropdown && project.has_review_page}
              <div class="d-block text-wrap" transition:slide>
                <div class="dropdown-divider pt-0 mt-0" />
                <div class="mb-2">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <label class="form-label" for="clientReviewLink"
                      >Client Link</label
                    >
                  </div>
                  <div class="input-group input-group-sm">
                    <input
                      type="text"
                      bind:value={project.review_page_url}
                      class="form-control form-control-sm"
                      id="clientReviewLink-{project.id}"
                      readonly
                    />
                    <div class="input-group-append" id="inputGroup-sizing-sm">
                      <button
                        class="input-group-text cursor-pointer {copiedReviewLink
                          ? 'bg-soft-success'
                          : ''}"
                        on:click={() => {
                          document
                            .getElementById(`clientReviewLink-${project.id}`)
                            .select();
                          document.execCommand("Copy");
                          copiedReviewLink = true;
                          setTimeout(() => {
                            copiedReviewLink = false;
                          }, 2000);
                        }}
                        transition:fade
                        ><i
                          class="bi {copiedReviewLink
                            ? 'bi-clipboard-check text-success'
                            : 'bi-clipboard'}"
                          transition:fade
                        /></button
                      >
                    </div>
                    <div class="input-group-append" id="inputGroup-sizing-sm">
                      <button
                        class="input-group-text cursor-pointer {showProjectReviewPassword
                          ? 'bg-primary'
                          : ''}"
                        on:click={() => {
                          project.password = "";
                          setProjectPassword();
                          showProjectReviewPassword =
                            !showProjectReviewPassword;
                        }}
                        transition:fade
                        ><i
                          class="bi-key {showProjectReviewPassword
                            ? 'text-white'
                            : ''}"
                          transition:fade
                        /></button
                      >
                    </div>
                  </div>
                </div>
                {#if showProjectReviewPassword}
                  <div class="mb-2" transition:slide>
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <label class="form-label" for="clientReviewPassword"
                        >Password</label
                      >
                    </div>
                    <div class="input-group">
                      <input
                        type="text"
                        bind:value={project.password}
                        placeholder="8+ characters required"
                        class="form-control form-control-sm {project.password
                          .length < 8
                          ? 'is-invalid'
                          : 'is-valid'}"
                        id="clientReviewPassword-{project.id}"
                        use:stopTyping
                        on:stopTyping={() => {
                          if (project.password.length > 7) {
                            setProjectPassword();
                          }
                        }}
                      />
                      <div class="input-group-append">
                        <button
                          class="input-group-text cursor-pointer {copiedPassword
                            ? 'bg-soft-success'
                            : ''}"
                          on:click={() => {
                            if (project.password.length > 7) {
                              document
                                .getElementById(
                                  `clientReviewPassword-${project.id}`
                                )
                                .select();
                              document.execCommand("Copy");
                              copiedPassword = true;
                              setTimeout(() => {
                                copiedPassword = false;
                              }, 2000);
                            }
                          }}
                          ><i
                            class="bi {copiedPassword
                              ? 'bi-clipboard-check text-success'
                              : 'bi-clipboard'}"
                            transition:fade
                          /></button
                        >
                      </div>
                    </div>
                  </div>
                {/if}
                {#if !project.has_assigned_to_client_tasks}
                  <button
                    class="ps-3 dropdown-item text-danger"
                    on:click={() => {
                      toggleProjectReview();
                      showChangeReviewDropdown = false;
                    }}
                  >
                    Disable Client Review Link
                  </button>
                {:else}
                  <small class="text-wrap" for="clientReviewLink"
                    ><em
                      >Review link must be enabled if tasks are assigned to
                      client.</em
                    ></small
                  >
                {/if}
                <div class="dropdown-divider pb-0 mb-0" />
              </div>
            {/if}
          {/if}
          {#if !exclude.edit_project_details && permissions.includes("change_projects")}
            <a href={project.edit_project_url} class="dropdown-item">
              <i class="me-1 bi bi-gear" /> Edit Project Details
            </a>
          {/if}
          {#if !exclude.edit_template_details && permissions.includes("change_templates")}
            <a href={editTemplateUrl} class="dropdown-item">
              <i class="me-1 bi bi-gear" /> Edit Template Details
            </a>
          {/if}
          {#if !exclude.create_project_template}
            {#if project.is_template & permissions.includes("create_projects")}
              <button
                class="dropdown-item"
                on:click={createProjectFromTemplate}
              >
                <i class="me-1 bi-folder-plus" /> Create Project from Template
              </button>
            {:else if !project.is_template && permissions.includes("create_templates")}
              <button
                class="dropdown-item"
                on:click={createTemplateFromProject}
              >
                <i class="me-1 bi-save" /> Create Template from Project
              </button>
            {/if}
          {/if}
          {#if !exclude.archive_project && permissions.includes("change_projects")}
            <button class="dropdown-item" on:click={archiveProject}>
              <i class="me-1 bi bi-archive" />
              {#if project.is_archived}Unarchive{:else}Archive{/if}
              Project
            </button>
          {/if}
          {#if !exclude.archive_template && permissions.includes("change_templates")}
            <button class="dropdown-item" on:click={archiveProject}>
              <i class="me-1 bi bi-archive" />
              {#if project.is_archived}Unarchive{:else}Archive{/if}
              Template
            </button>
          {/if}
        </div>
      </div>
    {/if}
  </Popover>
</div>
