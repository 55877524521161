<script>
  export let task = null;
  export let organizationProfileImageUrl = null;
  export let organizationName = null;

  let showComments = false;
  let form;
  let errors = {};
  let isShowingCompletePopover;
  let referenceElementComplete;

  import dayjs from "dayjs";
  import { slide, fade } from "svelte/transition";
  import Popover from "svelte-easy-popover";
  import { clickOutside } from "./clickOutside";
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();

  const extractErrors = ({ inner }) => {
    return inner.reduce((acc, err) => {
      return { ...acc, [err.path]: err.message };
    }, {});
  };
</script>

{#if task}
  <div class="step-content-wrapper">
    {#if task.is_assigned_to_client}
      <button
        bind:this={referenceElementComplete}
        on:click={() => {
          if (!isShowingCompletePopover) {
            isShowingCompletePopover = true;
          }
        }}
        class="step-icon step-icon-white border border-soft-primary btn-white"
        ><i class="bi bi-check2" /></button
      >
      <Popover
        triggerEvents={["click"]}
        referenceElement={referenceElementComplete}
        placement="bottom-start"
        spaceAway={10}
        bind:isOpen={isShowingCompletePopover}
      >
        {#if isShowingCompletePopover}
          <div
            class="popover-contents"
            transition:fade={{ duration: 250 }}
            use:clickOutside
            on:click_outside={() => {
              if (isShowingCompletePopover) {
                isShowingCompletePopover = false;
              }
            }}
          >
            <div
              class="card p-3 task-message {task.is_complete &&
              task.should_notify_client
                ? 'warning'
                : task.is_assigned_to_client
                ? 'assign-client'
                : ''} is-popup"
            >
              {#if task.is_assigned_to_client}
                <div class="row text-left">
                  <h5>Are you sure?</h5>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="mb-2 text-left">
                      <em
                        >{#if organizationName}{organizationName}{:else}Your
                          vendor{/if} will review and be in touch if they need anything
                        else from you.</em
                      >
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="d-grid">
                    <button
                      on:click={() => {
                        isShowingCompletePopover = false;
                        dispatch("clickIsComplete");
                      }}
                      type="submit"
                      class="btn btn-primary btn-sm"
                      ><i class="bi bi-check2-circle" /> Mark as Complete</button
                    >
                  </div>
                </div>
              {/if}
            </div>
          </div>
        {/if}
      </Popover>
    {:else}
      <span
        class="step-icon {task.is_complete && !task.is_hidden_from_client
          ? 'step-icon-success'
          : task.is_complete && task.is_hidden_from_client
          ? 'step-icon-soft-success'
          : task.is_assigned_to_client
          ? 'step-icon-white border border-soft-secondary'
          : 'step-icon-soft-secondary'}"
        >{#if task.is_complete}<i class="bi bi-check2" />
        {/if}</span
      >
    {/if}
    <div
      class="step-content {task.is_hidden_from_client
        ? 'text-muted'
        : task.is_assigned_to_client
        ? 'card'
        : ''}"
      style={!task.is_assigned_to_client
        ? "padding-left:1em; padding-right:1em"
        : "padding:1em;"}
    >
      <h4
        class="step-title {task.is_hidden_from_client ? 'text-muted' : ''} mb-2"
      >
        {task.name}
        {#if task.is_hidden_from_client}<span
            class="badge bg-soft-secondary text-secondary me-1"
            ><i class="bi bi-eye-slash" /> Hidden from Client</span
          >{/if}
      </h4>
      {#if task.detail != null}<p class="step-text">
          {@html task.detail}
        </p>{/if}
      <div class="d-flex justify-content-between align-items-center">
        <div>
          {#if task.deadline != null}<div
              class="badge bg-soft-primary text-primary"
            >
              Due {dayjs(task.deadline).format("ddd, MMM D")}
            </div>
          {/if}
          {#if task.is_deliverable}
            <span class="badge bg-soft-secondary text-secondary"
              ><i class="bi bi-truck" /> Deliverable</span
            >
          {/if}
        </div>
        <div>
          <button
            class="btn btn-sm p-0 {task.comments.length < 1
              ? 'text-muted'
              : 'text-primary'}"
            on:click={() => {
              if (!(task.comments.length < 1)) {
                showComments = !showComments;
              }
            }}
          >
            {#if !showComments}
              {#if task.comments.length < 1}No Comments
              {:else}
                {task.comments.length} Comment{#if task.comments.length > 1}s{/if}
              {/if}
            {:else}
              Hide Comment{#if task.comments.length > 1}s{/if}
            {/if}
          </button>
        </div>
      </div>
      {#if showComments || task.is_assigned_to_client}
        <hr />
      {/if}
      {#if showComments}
        <div transition:slide class="row justify-content-lg-center">
          <div class="">
            <!-- Comment -->
            <ul class="list-comment">
              {#each task.comments as comment}
                <!-- Item -->
                <li class="list-comment-item">
                  <!-- Media -->
                  <div class="d-flex align-items-center">
                    {#if organizationProfileImageUrl}
                      <div class="flex-shrink-0">
                        <img
                          class="avatar avatar-circle"
                          src={organizationProfileImageUrl}
                          alt="User Profile"
                        />
                      </div>
                    {/if}
                    <div
                      class="flex-grow-1 {organizationProfileImageUrl
                        ? 'ms-3'
                        : ''}"
                    >
                      <div
                        class="d-flex justify-content-between align-items-center"
                      >
                        <div>
                          <h6>{comment.author_name}</h6>
                          {#if comment.comment == null}
                            <small><em>Sent a project update</em></small>
                          {/if}
                        </div>
                        <span class="d-block small text-muted"
                          >{dayjs(comment.created_at).fromNow()}</span
                        >
                      </div>
                    </div>
                  </div>
                  <!-- End Media -->
                  <p>
                    {#if comment.comment != null}
                      {comment.comment}
                    {/if}
                  </p>
                </li>
                <!-- End Item -->
              {/each}
            </ul>
            <!-- End Comment -->
          </div>
          <!-- End Col -->
          <div class="row">
            <hr class="mb-3" />
          </div>
        </div>
        <!-- End Row -->
        <!-- End Step -->
      {/if}
      {#if task.is_assigned_to_client}
        <button
          class="btn btn-xs btn-success"
          on:click={() => {
            if (!isShowingCompletePopover) {
              isShowingCompletePopover = true;
            }
          }}>Mark as Complete</button
        >
      {/if}
    </div>
  </div>
{/if}

<style scoped>
</style>
