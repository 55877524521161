<script>
  import { fade, fly } from "svelte/transition";
  import { quintOut } from "svelte/easing";
  import Croppie from "croppie";
  import { onMount } from "svelte";
  import { afterUpdate } from "svelte";
  import { getCSRFToken, extractErrors } from "../utils";
  import { setOrganizationSchema } from "../schema";
  import Loader from "./Loader.svelte";
  export let userid;
  export let orgListApi;

  let showInitialSetupChoices = false;

  let loading = 1;
  let user = {};
  let organizations = [];
  let selected;
  let stepNumber = 0;
  let showSetupWizard = false;

  let isUploadingImage = false;
  let file;
  let profileImage;

  let croppieElement;
  let croppieInstance;

  let isDragging;

  let organization = {
    name: null,
    profile_image: null,
    brand_color: null,
    description: null,
    website: null,
    has_been_set_up: null,
  };

  let form;
  let errors = {};

  function clearFile() {
    if (selected.profile_image != null) {
      profileImage = selected.profile_image;
    } else {
      profileImage = null;
    }
    file = null;
  }

  async function decrementStep() {
    if (stepNumber == 2) {
      clearFile();
    }
    stepNumber--;
  }

  async function incrementStep() {
    if (stepNumber == 0) {
      let formIsValid = await validateNameForm();
      if (!formIsValid) {
        return;
      }
    }
    if (stepNumber == 1) {
      let formIsValid = await validateDetailsForm();
      if (!formIsValid) {
        return;
      }
    }
    stepNumber++;
  }

  async function fetchOrganizations() {
    const response = await fetch(`${orgListApi}`);

    if (response.ok) {
      const result = await response.json();
      organizations = result.results;
      if (organizations.length == 1) {
        showInitialSetupChoices = true;
        selected = { ...organizations[0] };
        profileImage = selected.profile_image;
      }
    } else {
      error = "Failed to load user details.";
    }
  }

  async function setCurrentOrganization() {
    window.location = `${selected.view_projects_url}`;
  }

  function handleDragOver(event) {
    event.preventDefault();
    isDragging = true;
  }

  function handleFileChange(event) {
    file = event.target.files[0];
  }

  function handleDrop(event) {
    event.preventDefault();
    file = Array.from(event.dataTransfer.files)[0];
    isDragging = false;
  }

  async function updateOrganization() {
    // Marks the organization as set up.
    selected.has_been_set_up = true;

    let formData = new FormData();
    for (let key in selected) {
      if (key === "profile_image") {
        if (isUploadingImage) {
          let base64Image = selected[key];
          let base64ImageParts = base64Image.split(";base64,");
          let contentType = base64ImageParts[0].split(":")[1];
          let raw = window.atob(base64ImageParts[1]);
          let rawLength = raw.length;
          let uInt8Array = new Uint8Array(rawLength);
          for (let i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
          }
          let blob = new Blob([uInt8Array], { type: contentType });
          formData.append(key, blob, "image.webp");
        }
      } else {
        formData.append(key, selected[key]);
      }
    }

    const response = await fetch(selected.url, {
      method: "PUT",
      body: formData,
      headers: {
        Accept: "application/json",
        "X-CsrfToken": getCSRFToken(),
      },
      credentials: "include",
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Redirect after successful fetch
    window.location.href = `${selected.view_projects_url}`;
  }

  function croppieAction(node) {
    croppieInstance = new Croppie(node, {
      viewport: { width: 150, height: 150, type: "square" },
      boundary: { width: 150, height: 150 },
      customClass: "rounded-croppie",
    });

    let reader = new FileReader();
    reader.onload = (event) => {
      let url = URL.createObjectURL(file);
      croppieInstance
        .bind({
          url: url,
          points: [0, 0, 0, 0],
        })
        .then(() => {
          croppieInstance.setZoom(0);
        });
    };
    reader.readAsDataURL(file);

    return {
      destroy() {
        if (croppieInstance) {
          croppieInstance.destroy();
          croppieInstance = null;
        }
      },
    };
  }

  // Form validation

  function validateNameForm() {
    form = {
      name: selected.name,
    };

    return setOrganizationSchema
      .validate(form, { abortEarly: false })
      .then(() => {
        // clear the errors
        errors = {};
        return true;
      })
      .catch((err) => {
        errors = extractErrors(err);
        return false;
      });
  }

  function validateDetailsForm() {
    form = {
      name: selected.name,
      website: selected.website,
      description: selected.description,
    };

    return setOrganizationSchema
      .validate(form, { abortEarly: false })
      .then(() => {
        // clear the errors
        errors = {};
        return true;
      })
      .catch((err) => {
        console.error(err);
        errors = extractErrors(err);
        return false;
      });
  }

  onMount(async () => {
    await fetchOrganizations();
    loading--;
  });
</script>

{#if loading > 0}<div class="text-center">
    <Loader />
  </div>{:else if organizations.length > 1 && !showSetupWizard}
  <div
    class="text-center mb-5 mb-md-7 pt-7"
    in:fade
    out:fly={{ y: -300, duration: 500 }}
  >
    <h1 class="h2">Welcome back!</h1>
    <p>Which organization would you like to start off with today?</p>
    <ul class="list-group list-group-lg mb-3">
      {#each organizations as organization}
        <button
          class="list-group-item text-start"
          on:click={() => {
            selected = organization;
            if (
              !selected.has_been_set_up &&
              selected.user_permissions.includes("change_organization_profile")
            ) {
              showSetupWizard = true;
            } else {
              setCurrentOrganization();
            }
          }}
        >
          <div class="d-flex align-items-center">
            <div class="flex-shrink-0">
              {#if organization.profile_image}
                <img
                  class="avatar"
                  src={organization.profile_image}
                  alt={organization.name}
                />
              {:else}
                <div class="avatar avatar-md avatar-primary">
                  <span class="avatar-initials">{organization.name[0]}</span>
                </div>
              {/if}
            </div>

            <div class="flex-grow-1 ms-3">
              <div class="d-flex justify-content-between align-items-center">
                <h5 class="mb-0">{organization.name}</h5>
                <a href="../page-careers-overview.html">
                  <i class="bi-chevron-right small ms-1 small ms-1" />
                </a>
              </div>
            </div>
          </div>

          <!-- End Row -->
        </button>
      {/each}
    </ul>
    <!-- <div class="d-grid mb-3">
        <input
          class="btn btn-link btn-lg"
          type="submit"
          value="Create Additional Organization"
        />
      </div> -->
  </div>
{:else if showInitialSetupChoices}
  <div in:fade out:fly={{ y: -300, duration: 500 }}>
    <div class="text-center mb-5 mb-md-7 pt-7">
      <h1 class="h2">Let's get started!</h1>
      <p>What would you like to do first?</p>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 mb-3 mb-md-0">
        <button
          class="btn text-start card card-transition"
          on:click={() => {
            showInitialSetupChoices = false;
            showSetupWizard = true;
          }}
          data-testid="set-up-brand-button"
        >
          <div class="card-body">
            <span class="svg-icon text-primary mb-4">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.3"
                  d="M18 10V20C18 20.6 18.4 21 19 21C19.6 21 20 20.6 20 20V10H18Z"
                  fill="#035A4B"
                />
                <path
                  opacity="0.3"
                  d="M11 10V17H6V10H4V20C4 20.6 4.4 21 5 21H12C12.6 21 13 20.6 13 20V10H11Z"
                  fill="#035A4B"
                />
                <path
                  opacity="0.3"
                  d="M10 10C10 11.1 9.1 12 8 12C6.9 12 6 11.1 6 10H10Z"
                  fill="#035A4B"
                />
                <path
                  opacity="0.3"
                  d="M18 10C18 11.1 17.1 12 16 12C14.9 12 14 11.1 14 10H18Z"
                  fill="#035A4B"
                />
                <path opacity="0.3" d="M14 4H10V10H14V4Z" fill="#035A4B" />
                <path
                  opacity="0.3"
                  d="M17 4H20L22 10H18L17 4Z"
                  fill="#035A4B"
                />
                <path opacity="0.3" d="M7 4H4L2 10H6L7 4Z" fill="#035A4B" />
                <path
                  d="M6 10C6 11.1 5.1 12 4 12C2.9 12 2 11.1 2 10H6ZM10 10C10 11.1 10.9 12 12 12C13.1 12 14 11.1 14 10H10ZM18 10C18 11.1 18.9 12 20 12C21.1 12 22 11.1 22 10H18ZM19 2H5C4.4 2 4 2.4 4 3V4H20V3C20 2.4 19.6 2 19 2ZM12 17C12 16.4 11.6 16 11 16H6C5.4 16 5 16.4 5 17C5 17.6 5.4 18 6 18H11C11.6 18 12 17.6 12 17Z"
                  fill="#035A4B"
                />
              </svg>
            </span>

            <h3 class="card-title">Set up your brand</h3>
            <p class="card-text">
              Personalize your outgoing messages and client portals with your
              brand name, logo, and colors.
            </p>
          </div>
          <div class="card-footer pt-0">
            <span class="card-link text-primary"
              >Select <i class="bi-chevron-right small ms-1" /></span
            >
          </div>
        </button>
      </div>
      <div class="col-12 col-md-6">
        <button
          class="btn text-start card card-transition"
          on:click={() => {
            updateOrganization();
          }}
        >
          <div class="card-body">
            <span class="svg-icon text-primary mb-4">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.3"
                  d="M4.05424 15.1982C8.34524 7.76818 13.5782 3.26318 20.9282 2.01418C21.0729 1.98837 21.2216 1.99789 21.3618 2.04193C21.502 2.08597 21.6294 2.16323 21.7333 2.26712C21.8372 2.37101 21.9144 2.49846 21.9585 2.63863C22.0025 2.7788 22.012 2.92754 21.9862 3.07218C20.7372 10.4222 16.2322 15.6552 8.80224 19.9462L4.05424 15.1982ZM3.81924 17.3372L2.63324 20.4482C2.58427 20.5765 2.5735 20.7163 2.6022 20.8507C2.63091 20.9851 2.69788 21.1082 2.79503 21.2054C2.89218 21.3025 3.01536 21.3695 3.14972 21.3982C3.28408 21.4269 3.42387 21.4161 3.55224 21.3672L6.66524 20.1802L3.81924 17.3372ZM16.5002 5.99818C16.2036 5.99818 15.9136 6.08615 15.6669 6.25097C15.4202 6.41579 15.228 6.65006 15.1144 6.92415C15.0009 7.19824 14.9712 7.49984 15.0291 7.79081C15.0869 8.08178 15.2298 8.34906 15.4396 8.55884C15.6494 8.76862 15.9166 8.91148 16.2076 8.96935C16.4986 9.02723 16.8002 8.99753 17.0743 8.884C17.3484 8.77046 17.5826 8.5782 17.7474 8.33153C17.9123 8.08486 18.0002 7.79485 18.0002 7.49818C18.0002 7.10035 17.8422 6.71882 17.5609 6.43752C17.2796 6.15621 16.8981 5.99818 16.5002 5.99818Z"
                  fill="#035A4B"
                />
                <path
                  d="M4.05423 15.1982L2.24723 13.3912C2.15505 13.299 2.08547 13.1867 2.04395 13.0632C2.00243 12.9396 1.9901 12.8081 2.00793 12.679C2.02575 12.5498 2.07325 12.4266 2.14669 12.3189C2.22013 12.2112 2.31752 12.1219 2.43123 12.0582L9.15323 8.28918C7.17353 10.3717 5.4607 12.6926 4.05423 15.1982V15.1982ZM8.80023 19.9442L10.6072 21.7512C10.6994 21.8434 10.8117 21.9129 10.9352 21.9545C11.0588 21.996 11.1903 22.0083 11.3195 21.9905C11.4486 21.9727 11.5718 21.9252 11.6795 21.8517C11.7872 21.7783 11.8765 21.6809 11.9402 21.5672L15.7092 14.8442C13.6269 16.8245 11.3061 18.5377 8.80023 19.9442V19.9442ZM7.04023 18.1832L12.5832 12.6402C12.7381 12.4759 12.8228 12.2577 12.8195 12.032C12.8161 11.8063 12.725 11.5907 12.5653 11.4311C12.4057 11.2714 12.1901 11.1803 11.9644 11.1769C11.7387 11.1736 11.5205 11.2583 11.3562 11.4132L5.81323 16.9562L7.04023 18.1832Z"
                  fill="#035A4B"
                />
              </svg>
            </span>

            <h3 class="card-title">Dive into projects</h3>
            <p class="card-text">
              Skip the brand stuff for now and start using Dunaroo. You can
              always set up your brand later.
            </p>
          </div>
          <div class="card-footer pt-0">
            <span class="card-link text-primary"
              >Select <i class="bi-chevron-right small ms-1" /></span
            >
          </div>
        </button>
      </div>
    </div>
  </div>
{:else if showSetupWizard}
  <div
    data-testid="setupWizard"
    in:fly={{ y: 300, duration: 500, delay: 500, easing: quintOut }}
  >
    <!-- Heading -->
    <div class="text-center mb-5 mb-md-7 pt-7">
      <h1 class="h2">Set up your brand</h1>
      <p>Give your client comms a little pizzaz.</p>
    </div>
    <!-- End Heading -->

    <!-- Step Form -->
    <!-- Step -->
    <ul
      id="validationFormProgress"
      class="js-step-progress step step-sm step-icon-sm step-inline step-item-between mb-4"
    >
      <li class="step-item">
        <span class="step-content-wrapper">
          <span
            class="step-icon {stepNumber == 0
              ? 'step-icon-soft-primary'
              : 'step-icon-soft-secondary'}">1</span
          >
          <div class="step-content">
            <span class="step-title">Name</span>
          </div>
        </span>
      </li>

      <li class="step-item">
        <span class="step-content-wrapper">
          <span
            class="step-icon {stepNumber == 1
              ? 'step-icon-soft-primary'
              : 'step-icon-soft-secondary'}">2</span
          >
          <div class="step-content">
            <span class="step-title">Details</span>
            <div class="form-label-secondary">(Optional)</div>
          </div>
        </span>
      </li>

      <li class="step-item">
        <span class="step-content-wrapper">
          <span
            class="step-icon {stepNumber == 2
              ? 'step-icon-soft-primary'
              : 'step-icon-soft-secondary'}">3</span
          >
          <div class="step-content">
            <span class="step-title">Logo & Color</span>
            <div class="form-label-secondary">(Optional)</div>
          </div>
        </span>
      </li>
    </ul>
    <!-- End Step -->

    <!-- Content Step Form -->
    <div id="validationFormContent">
      {#if stepNumber == 0}
        <div id="validationFormAccount" in:fly={{ x: 100 }}>
          <!-- Form Group -->
          <div class="row mb-4">
            <div class="col">
              <label for="organizationName" class="form-label"
                >Organization Name</label
              >

              <input
                type="text"
                class="form-control {errors.name ? 'is-invalid' : ''}"
                id="organizationName"
                data-testid="organizationName"
                placeholder="HappyCo, Inc."
                required
                bind:value={selected.name}
              />
              {#if errors.name}
                <span class="invalid-feedback">{errors.name}</span>
              {/if}
            </div>
          </div>
          <!-- End Form Group -->

          <!-- Footer -->
          <div class="d-flex align-items-center">
            <button
              type="button"
              class="btn btn-ghost-secondary mb-3 mb-sm-0 me-2"
              on:click={() => {
                if (organizations.length > 1) {
                  showSetupWizard = false;
                } else {
                  showInitialSetupChoices = true;
                }
              }}
            >
              <i class="bi-chevron-left small" /> Back
            </button>
            <div class="ms-auto">
              <button
                class="btn btn-ghost-secondary"
                type="button"
                on:click={async () => {
                  let formIsValid = await validateNameForm();
                  if (formIsValid) {
                    updateOrganization();
                  }
                }}>Skip all <i class="bi bi-chevron-double-right" /></button
              >
              <button
                type="button"
                class="btn btn-primary"
                on:click={incrementStep}
              >
                Next <i class="bi-chevron-right small" />
              </button>
            </div>
          </div>
          <!-- End Footer -->
        </div>
      {:else if stepNumber == 1}
        <div id="validationFormAddress" in:fly={{ x: 100 }}>
          <!-- Form Group -->
          <div class="row mb-4">
            <div class="col">
              <label for="website" class="form-label">Website</label>

              <input
                type="text"
                class="form-control {errors.website ? 'is-invalid' : ''}"
                id="website"
                placeholder="https://www.yoursite.com"
                required
                bind:value={selected.website}
              />
              {#if errors.website}
                <span class="invalid-feedback">{errors.website}</span>
              {/if}
            </div>
          </div>
          <!-- End Form Group -->

          <!-- Form Group -->
          <div class="row mb-4">
            <div class="col">
              <label for="Description" class="form-label">Description</label>
              <textarea
                type="text"
                class="form-control {errors.description ? 'is-invalid' : ''}"
                id="Description"
                placeholder="May appear on your client portals"
                bind:value={selected.description}
                rows="3"
              />
              {#if errors.description}
                <span class="invalid-feedback">{errors.description}</span>
              {/if}
            </div>
          </div>
          <!-- End Form Group -->

          <!-- Footer -->
          <div class="d-flex align-items-center">
            <button
              type="button"
              class="btn btn-ghost-secondary mb-3 mb-sm-0 me-2"
              on:click={decrementStep}
            >
              <i class="bi-chevron-left small" /> Back
            </button>

            <div class="ms-auto">
              <button
                id="validationFormFinishBtn"
                type="button"
                class="btn btn-primary"
                on:click={incrementStep}
                >Next <i class="bi-chevron-right small" /></button
              >
            </div>
          </div>
          <!-- End Footer -->
        </div>
      {:else if stepNumber == 2}
        <div id="validationFormProfile" in:fly={{ x: 100 }}>
          <!-- Form Group -->
          <div class="row mb-4">
            <div class="col text-center">
              <label
                for="validationFormProfileImageLabel"
                class="form-label d-block">Organization Logo</label
              >
              {#if profileImage}
                <div
                  class="avatar avatar-xxl mb-3"
                  style="width:150px; height:150px"
                  in:fade={{ duration: 200, delay: 100 }}
                >
                  <img
                    class="avatar-img"
                    src={profileImage}
                    alt={selected.name}
                  />
                  <!-- <div class="float-end">
                    <button
                      class="btn btn-secondary btn-icon btn-sm rounded-pill mt-n10 border border-white border-3"
                      on:click={() => {
                        profileImage = null;
                        file = null;
                      }}><i class="bi bi-pencil" /></button
                    >
                  </div> -->
                  <button
                    class="btn btn-link form-link p-0 mt-0"
                    type="button"
                    on:click={() => {
                      profileImage = null;
                      file = null;
                    }}><i class="bi bi-pencil" /> Edit</button
                  >
                </div>
              {:else}
                {#if !file}
                  <!-- Dropzone -->
                  <label
                    for="fileInput"
                    id="basicExampleDropzone"
                    class="dz-dropzone dz-dropzone-card d-flex align-items-center justify-content-center p-0 mx-auto"
                    on:dragover={handleDragOver}
                    on:drop={handleDrop}
                    class:bg-soft-dark={isDragging}
                    in:fade={{ duration: 200, delay: 100 }}
                    style="height:150px; width:150px;"
                  >
                    <div
                      class="dz-message"
                      id="validationFormProfileImageLabel"
                    >
                      <span class="svg-icon svg-icon-lg"
                        ><svg
                          width="333"
                          height="214"
                          viewBox="0 0 333 214"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0)">
                            <path
                              d="M213.4 23.3C207.6 15.8 198.8 11.2 189.3 10.8C144.7 8.9 66.1 5.4 28.1 3.5C15.8 2.9 5.29999 12.3 4.59999 24.6C1.79999 78.5 5.19999 140.7 4.19999 159.5C3.19999 177.1 23 184.6 39.2 186.9"
                              stroke="#21325B"
                              stroke-width="7"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M223.2 22.7C235.4 21.9 246 31.2 246.8 43.4C250.4 97.1 247.9 159.4 249.3 178.3C250.9 199.8 220.8 206.8 204.5 207.2C158.4 208.4 112.2 207.4 66.1 204.2C57.3 203.6 47.1 202.1 42.6 194.5C40.3 190.7 40.1 186.1 39.9 181.7C38.7 157.4 34.5 102.6 31.7 67C30.4 49.2 43.7 33.8 61.5 32.5C61.7 32.5 61.8 32.5 62 32.5C106.6 29.8 185.1 25.2 223.2 22.7Z"
                              fill="#f5ca99"
                              stroke="#f5ca99"
                              stroke-width="7"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M39.2 167.9C68.3 155.1 78.6 134.6 95.8 128.9C112.9 123.2 127.5 151.7 134.3 162.3C138.8 149.9 143.6 137.2 152.2 127.3C160.9 117.4 174.8 110.9 187.5 114.4C195.7 116.7 202.3 122.7 208.5 128.6C220.3 139.7 235.2 153.1 248.9 161.7"
                              stroke="#21325B"
                              stroke-width="7"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M124.6 54.5C129.8 51.1 140.1 49.2 151.3 61.4C162.5 73.6 153.1 95 130 90.5C110.5 86.7 109.8 64.2 124.6 54.5Z"
                              stroke="#21325B"
                              stroke-width="7"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M222.6 171.5C214.4 165.3 208 150.4 209.1 136C210.1 123.7 221.7 107.3 233.8 103.7C241.4 101.4 250.3 99.6 258.2 100.1C270.2 100.8 277.7 107.7 283.4 117.9C292.1 133.6 292.6 152.4 280.3 166.5C271.8 176.1 262.7 179.4 250 180.8C243.9 181.4 230.9 177.8 222.6 171.5Z"
                              fill="white"
                              stroke="#21325B"
                              stroke-width="7"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M278.4 170.1C291.5 183.9 304.9 197.4 318.5 210.6Z"
                              fill="white"
                            />
                            <path
                              d="M278.4 170.1C291.5 183.9 304.9 197.4 318.5 210.6"
                              stroke="#21325B"
                              stroke-width="7"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M223.7 133.1C226 125.7 231.3 119.5 238.3 116.1Z"
                              fill="white"
                            />
                            <path
                              d="M223.7 133.1C226 125.7 231.3 119.5 238.3 116.1"
                              stroke="#21325B"
                              stroke-width="7"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path d="M248.7 113.1L251.5 112.1Z" fill="white" />
                            <path
                              d="M248.7 113.1L251.5 112.1"
                              stroke="#21325B"
                              stroke-width="7"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path d="M275.8 73.9L283.5 55.9Z" fill="white" />
                            <path
                              d="M275.8 73.9L283.5 55.9"
                              stroke="#21325B"
                              stroke-width="7"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path d="M305.5 92.3L320.3 82.4Z" fill="white" />
                            <path
                              d="M305.5 92.3L320.3 82.4"
                              stroke="#21325B"
                              stroke-width="7"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path d="M316.2 127.1L328.8 127Z" fill="white" />
                            <path
                              d="M316.2 127.1L328.8 127"
                              stroke="#21325B"
                              stroke-width="7"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0">
                              <rect width="332.2" height="214" fill="white" />
                            </clipPath>
                          </defs>
                        </svg></span
                      >
                      <h6>Drag-n-drop or<br />click to upload</h6>
                      <input
                        data-testid="logo-input"
                        id="fileInput"
                        type="file"
                        class="d-none"
                        accept=".jpg,.jpeg,.png"
                        on:change={handleFileChange}
                      />
                    </div>
                  </label>
                  <!-- End Dropzone -->
                {:else}
                  <!-- Cropper -->
                  <div
                    use:croppieAction
                    in:fade={{ duration: 200, delay: 100 }}
                    bind:this={croppieElement}
                  />
                  <!-- End Cropper -->
                {/if}
                {#if selected.profile_image != null || file}
                  <button
                    class="btn btn-link form-link text-danger p-0 mt-0"
                    type="button"
                    on:click={() => {
                      clearFile();
                    }}><i class="bi bi-x-circle" /> Cancel</button
                  >
                {/if}
              {/if}
            </div>
            <div class="col text-center">
              <label for="brandColor" class="form-label d-block"
                >Brand Color</label
              >
              <input
                type="color"
                id="brandColor"
                bind:value={selected.brand_color}
                data-testid="brand-color"
                class="set-organization brand-color"
                maxlength="7"
                style="border=none;"
              />
            </div>
          </div>
          <!-- End Form Group -->

          <!-- Footer -->
          <div class="d-flex align-items-center">
            <button
              type="button"
              class="btn btn-ghost-secondary me-2"
              on:click={decrementStep}
            >
              <i class="bi-chevron-left small" /> Back
            </button>

            <div class="ms-auto">
              <button
                type="button"
                class="btn btn-primary"
                on:click={async () => {
                  if (file) {
                    await croppieInstance
                      .result({
                        type: "base64",
                        size: "original",
                        format: "webp",
                      })
                      .then(function (resp) {
                        profileImage = resp;
                        selected.profile_image = profileImage;
                      });
                    isUploadingImage = true;
                  }

                  updateOrganization();
                }}
              >
                Let's Go!
              </button>
            </div>
          </div>
          <!-- End Footer -->
        </div>
      {/if}
    </div>
    <!-- End Content Step Form -->
    <!-- End Step Form -->
  </div>
{/if}
