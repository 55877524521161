<script>
  export let signupUrl = "";
  export let peekingImageUrl = "";

  let selectedSeats = 5;
  let total;
  let annual = true;
  let inputElement;

  $: total = selectedSeats * (annual ? 15 : 20);

  $: if (inputElement) {
    const value = ((selectedSeats - 1) / (20 - 1)) * 100;
    inputElement.style.background = `linear-gradient(to right, #1a6aff 0%, #1a6aff ${value}%, rgba(33, 50, 91, 0.1) ${value}%, rgba(33, 50, 91, 0.1) 100%)`;
  }
</script>

<!-- Pricing -->
<div class="bg-light overflow-hidden">
  <div class="container content-space-2 content-space-lg-3">
    <!-- Heading -->
    <div class="w-md-75 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
      <span class="text-cap">Pricing</span>
      <h2 class="h1">One simple plan</h2>
    </div>
    <!-- End Heading -->

    <div class="position-relative">
      <div class="row gx-0 justify-content-center">
        <div class="col-md-8 mb-3 mb-md-0">
          <!-- Card -->
          <div class="card card-lg zi-2" data-aos="fade-up">
            <div class="card-body">
              <form>
                <!-- Radio Button Group -->
                <div class="text-center mb-5">
                  <div
                    class="btn-group btn-group-segment btn-group-pills"
                    role="group"
                    aria-label="Pricing radio button group"
                  >
                    <input
                      type="radio"
                      class="js-switch-text btn-check"
                      name="btnradio"
                      id="btnradio1"
                      autocomplete="off"
                      checked
                      value={true}
                      bind:group={annual}
                    />
                    <label class="js-toggle-switch btn btn-sm" for="btnradio1">
                      <span class="form-switch-promotion">
                        Billed Annually
                      </span>
                      <!-- Form Switch Promotion -->
                      <span class="form-switch-promotion-container ps-2 pt-2">
                        <span class="form-switch-promotion-body">
                          <svg
                            class="form-switch-promotion-arrow"
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            viewBox="0 0 99.3 57"
                            width="48"
                          >
                            <path
                              fill="none"
                              stroke="#bdc5d1"
                              stroke-width="4"
                              stroke-linecap="round"
                              stroke-miterlimit="10"
                              d="M2,39.5l7.7,14.8c0.4,0.7,1.3,0.9,2,0.4L27.9,42"
                            />
                            <path
                              fill="none"
                              stroke="#bdc5d1"
                              stroke-width="4"
                              stroke-linecap="round"
                              stroke-miterlimit="10"
                              d="M11,54.3c0,0,10.3-65.2,86.3-50"
                            />
                          </svg>
                          <span class="form-switch-promotion-text">
                            <span class="badge bg-primary rounded-pill ms-1"
                              >Save 25%</span
                            >
                          </span>
                        </span>
                      </span>
                      <!-- End Form Switch Promotion -->
                    </label>

                    <input
                      type="radio"
                      class="js-switch-text btn-check"
                      name="btnradio"
                      id="btnradio2"
                      value={false}
                      bind:group={annual}
                      autocomplete="off"
                    />
                    <label class="js-toggle-switch btn btn-sm" for="btnradio2">
                      Billed Monthly
                    </label>
                  </div>
                </div>
                <!-- End Radio Button Group -->

                <!-- Range Slider -->
                <div class="display-4 text-dark text-center">
                  ${annual ? "15" : "20"}/mo
                </div>
                <div class="text-center">Per User</div>
                <div class="d-flex justify-content-center mt-3">
                  ${total}/month for
                  <strong class="ms-1">
                    {selectedSeats}
                    users</strong
                  >
                </div>

                <!-- End Range Slider -->
              </form>
              <div class="text-center mb-5">
                <div class="range-slider">
                  <input
                    type="range"
                    bind:value={selectedSeats}
                    min="1"
                    max="20"
                    bind:this={inputElement}
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-sm-6">
                  <!-- List Checked -->
                  <ul class="list-checked list-checked-primary mb-0">
                    <li class="list-checked-item">Unlimited Projects</li>
                    <li class="list-checked-item">Unlimited Clients</li>
                    <li class="list-checked-item">Unlimited Messages</li>
                  </ul>
                  <!-- End List Checked -->
                </div>
                <!-- End Col -->

                <div class="col-sm-6">
                  <!-- List Checked -->
                  <ul class="list-checked list-checked-primary mb-0">
                    <li class="list-checked-item">Branded Outgoing Emails</li>
                    <li class="list-checked-item">Branded Project Portals</li>
                    <!-- <li class="list-checked-item">Custom Domain</li> -->
                  </ul>
                  <!-- End List Checked -->
                </div>
                <!-- End Col -->
              </div>
              <!-- End Row -->
            </div>

            <div class="bg-light card-footer card-link text-center">
              <a
                href={signupUrl +
                  "?seats=" +
                  selectedSeats +
                  "&annual=" +
                  annual}
                class="btn btn-primary"
                >Start free 14-day trial <i
                  class="bi-chevron-right small ms-1"
                /></a
              >
            </div>
          </div>
          <!-- End Card -->
        </div>
        <!-- End Col -->
      </div>
      <!-- End Row -->

      <!-- SVG Shape -->
      <div
        class="position-absolute bottom-0 start-0 d-none d-xl-block"
        style="width: 10rem; margin-left: 1.7rem;"
      >
        <img class="img-fluid" src={peekingImageUrl} alt="SVG" />
      </div>
      <!-- End SVG Shape -->
    </div>
  </div>
</div>

<!-- End Pricing -->
<style>
  input[type="range"] {
    appearance: none; /* Hides the slider so that custom slider can be made */
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    width: 100%; /* Specific width is required for Firefox. */
    background: transparent; /* Otherwise white in Chrome */
    border: none;
    border-radius: 8px;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
  }

  input[type="range"]:focus {
    outline: none;
  }

  input[type="range"]::-ms-track {
    width: 100%;
    cursor: pointer;

    /* Hides the slider so custom styles can be added */
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  /* Special styling for WebKit/Blink */
  input[type="range"]::-webkit-slider-thumb {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background: #ffffff;
    cursor: pointer;
    margin-top: -10px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
    box-shadow: 0 0.1875rem 0.375rem rgba(140, 152, 164, 0.5);
  }

  /* All the same stuff for Firefox */
  input[type="range"]::-moz-range-thumb {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    border: none;
    background: #ffffff;
    cursor: pointer;
    box-shadow: 0 0.1875rem 0.375rem rgba(140, 152, 164, 0.5);
  }

  /* All the same stuff for IE */
  input[type="range"]::-ms-thumb {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background: #ffffff;
    cursor: pointer;
    box-shadow: 0 0.1875rem 0.375rem rgba(140, 152, 164, 0.5);
  }

  input[type="range"]::-webkit-slider-runnable-track,
  input[type="range"]:focus::-webkit-slider-runnable-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    border-radius: 4px;
  }

  input[type="range"]::-moz-range-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    border-radius: 4px;
  }

  input[type="range"]::-ms-fill-lower {
    background: #1a6aff;
  }
  input[type="range"]:focus::-ms-fill-lower {
    background: #1a6aff;
  }
  input[type="range"]::-ms-fill-upper {
    background: rgb(103, 119, 136);
  }
  input[type="range"]:focus::-ms-fill-upper {
    background: rgb(103, 119, 136);
  }
</style>
