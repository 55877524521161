<script>
  import ProjectActions from "./project/ProjectActions.svelte";
  import { onMount } from "svelte";
  import Tasks from "./Tasks.svelte";
  import { setContext } from "svelte";
  import ProjectWebSocketComponent from "./websocket/ProjectWebSocketComponent.svelte";
  import Loader from "./Loader.svelte";

  export let permissions = null;
  export let orguserApiUrl = "";
  export let currentOrgId = "";
  export let templateToProjectUrl = "";
  export let editTemplateUrl = "";
  export let tasksApiUrl;
  export let clientremindersApiUrl;

  setContext("permissions", permissions);
  setContext("orguserApiUrl", orguserApiUrl);
  setContext("currentOrgId", currentOrgId);

  export let backUrl = "";
  export let backText = "";
  export let projectid = "";
  export let userid = "";
  export let projectUrl = null;
  export let templatesUrl;
  export let alltaskview = null;
  export let projectsview = null;

  let projectView = false;
  let project = {};
  let projectIsTemplate;
  let tasksComponent;

  let loading = 1;

  async function fetchProject() {
    if (projectid != "") {
      const response = await fetch(`${projectUrl}?ts=${new Date().getTime()}`);

      if (response.ok) {
        const result = await response.json();
        if (result.is_template == true) {
          projectIsTemplate = true;
        }
        project = result;
      } else {
        error = "Failed to load project details.";
      }
    }
  }

  onMount(async () => {
    await fetchProject();
    loading--;
  });
</script>

<ProjectWebSocketComponent
  {userid}
  roomName={currentOrgId}
  on:changedProject={async (event) => {
    let project_id = event.detail.project_id;
    if (project_id === parseInt(project.id)) {
      // force reload to projects url if user is removed from project
      await fetchProject();
      tasksComponent.fetchTasks();
    }
  }}
/>
<div class="d-grid gap-3 gap-lg-5">
  <!-- Card -->
  <div class="card">
    {#if loading > 0}<div class="text-center"><Loader /></div>{:else}
      {#if !projectsview}
        <!-- Header -->
        <div
          class="card-header d-flex justify-content-between align-items-center border-bottom"
        >
          {#if backUrl}
            <div>
              <a href={backUrl}><i class="bi bi-arrow-left" /> {backText}</a>
            </div>
          {/if}
          <div class="text-center">
            <h4 class="card-header-title">
              {#if alltaskview}All{:else}{project.name}{/if} Tasks
            </h4>
            <span class="small text-right">Template</span>
          </div>
          {#if permissions.includes("change_templates")}
            <ProjectActions
              bind:project
              {templatesUrl}
              {editTemplateUrl}
              {templateToProjectUrl}
              {projectView}
              exclude={{
                show_completed_tasks: true,
                change_project_status: true,
                show_client_review: true,
                archive_project: true,
                edit_project_details: true,
              }}
            />
          {:else}<div />{/if}
        </div>
        <!-- End Header -->
      {/if}
      <Tasks
        bind:this={tasksComponent}
        {tasksApiUrl}
        {clientremindersApiUrl}
        {projectid}
        {project}
        {userid}
        {alltaskview}
        {projectsview}
        on:fetchProject={() => {
          fetchProject();
        }}
      />
    {/if}
  </div>
</div>
