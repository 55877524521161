<script>
  import Project from "./Project.svelte";
  import Loader from "../Loader.svelte";
  import { onMount, setContext } from "svelte";
  import { fade } from "svelte/transition";
  import ProjectWebSocketComponent from "../websocket/ProjectWebSocketComponent.svelte";

  const projectView = true;

  export let permissions = null;
  export let orguserApiUrl = "";
  export let currentOrgId = "";
  export let emptyProjectsImageUrl = "";

  export let archivedProjectsUrl;
  export let createProjectUrl;
  export let tasksApiUrl;
  export let templatesUrl;
  export let clientremindersApiUrl;
  export let userid;
  export let projectsUrl;

  setContext("permissions", permissions);
  setContext("orguserApiUrl", orguserApiUrl);
  setContext("currentOrgId", currentOrgId);

  let projects = [];
  let upcomingProjects = [];
  let completedProjects = [];
  let activeProjects = [];

  let projectInstances = {};
  let visibleProjects = [];

  let loading = 1;

  let selectedTab = "active";

  // Reactive statement to sort projects
  $: {
    let upcoming = [];
    let active = [];
    let completed = [];

    projects.forEach((project) => {
      if (project.is_archived) {
        // Skip archived projects
        return;
      }
      if (project.is_upcoming) {
        upcoming.push(project);
      } else if (project.is_complete) {
        completed.push(project);
      } else {
        active.push(project);
      }
    });

    upcomingProjects = upcoming.filter((project) => !project.is_archived);
    activeProjects = active.filter((project) => !project.is_archived);
    completedProjects = completed.filter((project) => !project.is_archived);
  }

  // Reactive statement to update visible projects based on the selected tab
  $: {
    if (selectedTab === "active") {
      visibleProjects = activeProjects;
    } else if (selectedTab === "upcoming") {
      visibleProjects = upcomingProjects;
    } else if (selectedTab === "completed") {
      visibleProjects = completedProjects;
    }
  }

  async function fetchProjects() {
    const response = await fetch(`${projectsUrl}my_projects`);

    if (response.ok) {
      const result = await response.json();
      projects = result.projects;
    } else {
      error = "Failed to load project details.";
    }
  }

  onMount(async () => {
    await fetchProjects();
    loading--;
  });
</script>

<div class="card-body p-2 p-md-5">
  {#if loading > 0}
    <div in:fade class="d-flex justify-content-center">
      <Loader />
    </div>
  {:else}
    <ProjectWebSocketComponent
      {userid}
      roomName={currentOrgId}
      on:removedProjectUser={fetchProjects}
      on:createdNewProject={fetchProjects}
      on:changedProject={async (event) => {
        await fetchProjects();
        // gets project id from ws message
        let project_id = event.detail.project_id;

        // checks through all project instances
        for (let key in projectInstances) {
          // skips the loop if instance is null
          if (projectInstances[key] === null) {
            continue;
          }

          // checks if the instance id matched project_id
          if (projectInstances[key].$$.ctx[0].id === project_id) {
            // projectInstances[key].fetchProject();

            // handler in Project component
            // to fetch tasks in TaskComponent
            projectInstances[key].fetchTasksComponentHandler();
          }
        }
      }}
    />
    <!-- Nav -->
    <ul class="nav nav-segment nav-fill mb-2 mb-md-7 flex-column flex-sm-row">
      <li class="nav-item">
        <button
          class="btn nav-link {selectedTab === 'upcoming' ? 'active' : ''}"
          on:click={() => {
            selectedTab = "upcoming";
            visibleProjects = upcomingProjects;
            projectInstances = {};
          }}
          >Upcoming {#if upcomingProjects.length > 0}<span
              class="badge bg-soft-secondary text-secondary rounded-pill"
              >{upcomingProjects.length}</span
            >{/if}</button
        >
      </li>
      <li class="nav-item">
        <button
          class="btn nav-link {selectedTab === 'active' ? 'active' : ''}"
          on:click={() => {
            selectedTab = "active";
            visibleProjects = activeProjects;
            projectInstances = {};
          }}
          >Active {#if activeProjects.length > 0}<span
              class="badge bg-soft-secondary text-secondary rounded-pill"
              >{activeProjects.length}</span
            >{/if}</button
        >
      </li>
      <li class="nav-item">
        <button
          class="btn nav-link {selectedTab === 'completed' ? 'active' : ''}"
          on:click={() => {
            selectedTab = "completed";
            visibleProjects = completedProjects;
            projectInstances = {};
          }}
          >Completed {#if completedProjects.length > 0}<span
              class="badge bg-soft-secondary text-secondary rounded-pill"
              >{completedProjects.length}</span
            >{/if}</button
        >
      </li>
    </ul>
    <!-- End Nav -->

    <div>
      <!-- Tab Content -->
      <ul class="list-unstyled mb-5">
        <!-- Card -->
        {#each visibleProjects as project (project.id)}
          <div transition:fade>
            <Project
              bind:this={projectInstances[project.id]}
              bind:project
              {userid}
              {projectView}
              {tasksApiUrl}
              {templatesUrl}
              {clientremindersApiUrl}
            />
          </div>
        {:else}
          <!-- Empty State -->
          <div class="text-center content-space-1">
            <img
              class="avatar avatar-xl mb-3"
              src={emptyProjectsImageUrl}
              alt="No Projects Yet"
            />
            <p class="card-text">Alas, no projects here.</p>
            {#if permissions.includes("create_projects")}
              <a class="btn btn-white btn-sm" href={createProjectUrl}
                >Start a Project</a
              >
            {/if}
          </div>
          <!-- End Empty State -->
        {/each}
        <!-- End Card -->
      </ul>
      <!-- End Tab Content -->
    </div>

    <div class="text-center">
      <small
        >Missing your project?
        <a href={archivedProjectsUrl}>View archived projects</a>
      </small>
    </div>
  {/if}
</div>
