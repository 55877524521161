<script>
  import ProjectActions from "./project/ProjectActions.svelte";
  import ClientContactDropdown from "./ClientContactDropdown.svelte";
  import { onMount } from "svelte";
  import Tasks from "./Tasks.svelte";
  import Loader from "./Loader.svelte";
  import { fade, fly } from "svelte/transition";
  import { setContext } from "svelte";
  import ProjectWebSocketComponent from "./websocket/ProjectWebSocketComponent.svelte";

  export let permissions = null;
  export let orguserApiUrl = "";
  export let currentOrgId = "";
  export let tasksApiUrl;
  export let clientremindersApiUrl;

  setContext("permissions", permissions);
  setContext("orguserApiUrl", orguserApiUrl);
  setContext("currentOrgId", currentOrgId);

  export let backUrl = "";
  export let backText = "";
  export let projectid = "";
  export let userid = "";
  export let projectUrl = null;
  export let editProjectUrl = null;
  export let templatesUrl;
  export let projectsUrl;
  export let clientProjectsUrl;
  export let alltaskview = null;
  export let projectsview = null;

  let loading = 1;
  let project = {};
  let client = {};
  let contactsElement;
  let memberships;
  let showContacts;
  let showRemovedProjectUserModal = false;

  let tasksComponent;

  $: if (
    project &&
    project.users &&
    !project.users.some((user) => user.id !== userid) &&
    !permissions.includes("view_all_projects")
  ) {
    showRemovedProjectUserModal = true;
  }

  $: if (project.key_contact == null) {
    project.key_contact = { id: null };
  }

  async function fetchProject() {
    if (projectid != "") {
      const response = await fetch(`${projectUrl}?ts=${new Date().getTime()}`);

      if (response.ok) {
        const result = await response.json();
        if (result.is_template == true) {
          projectIsTemplate = true;
        }
        project = result;
      } else {
        error = "Failed to load project details.";
      }
    }
  }

  async function fetchClient() {
    const response = await fetch(`${project.client}`);

    if (response.ok) {
      const result = await response.json();
      client = result;
    } else {
      error = "Failed to load project details.";
    }
  }

  async function fetchMemberships() {
    const response = await fetch(
      `${orguserApiUrl + "?organization=" + currentOrgId}`
    );

    if (response.ok) {
      const result = await response.json();
      memberships = result.results;
    } else {
      error = "Failed to load project details.";
    }
  }

  onMount(async () => {
    await fetchProject();
    await fetchClient();
    await fetchMemberships();
    loading--;
  });
</script>

<div class="d-grid gap-3 gap-lg-5">
  <!-- Card -->
  <div class="card">
    {#if loading > 0}<div class="text-center"><Loader /></div>{:else}
      <ProjectWebSocketComponent
        {userid}
        roomName={currentOrgId}
        on:removedProjectUser={() => {
          if (!permissions.includes("view_all_projects")) {
            showRemovedProjectUserModal = true;
          }
        }}
        on:changedProject={async (event) => {
          let project_id = event.detail.project_id;
          if (project_id === parseInt(project.id)) {
            // force reload to projects url if user is removed from project
            await fetchProject();
            tasksComponent.fetchTasks();
            fetchClient();
          }
        }}
      />
      <div transition:fade>
        {#if !projectsview}
          <!-- Header -->
          <div
            class="card-header d-flex justify-content-between align-items-center border-bottom"
          >
            {#if backUrl}
              <div class="d-none d-sm-block">
                <a href={backUrl}><i class="bi bi-arrow-left" /> {backText}</a>
              </div>
            {/if}

            <div class="text-center text-sm-center">
              <h4 class="card-header-title">
                {#if alltaskview}All{:else}{project.name}{/if} Tasks
              </h4>
              {#if !alltaskview}
                <ClientContactDropdown
                  {memberships}
                  {client}
                  {clientProjectsUrl}
                  {editProjectUrl}
                  {project}
                  on:fetchTasks={() => {
                    tasksComponent.fetchTasks();
                  }}
                />
                <div class="mt-2">
                  <div class="progress" style="height: 15px;">
                    <div
                      class="progress-bar bg-primary"
                      role="progressbar"
                      aria-valuenow={project.percent_complete}
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style="width:{project.percent_complete}%"
                    />
                  </div>
                  <div>
                    <span class="small text-right"
                      >{project.percent_complete}% of Tasks Complete</span
                    >
                  </div>
                </div>
              {/if}
            </div>
            {#if permissions.includes("change_projects")}
              <ProjectActions
                bind:project
                {client}
                {templatesUrl}
                projectView="false"
                exclude={{
                  archive_template: true,
                  edit_template_details: true,
                }}
              />
            {:else}
              <div />
            {/if}
          </div>

          <!-- End Header -->
        {/if}
        <Tasks
          bind:project
          bind:this={tasksComponent}
          {memberships}
          {tasksApiUrl}
          {clientremindersApiUrl}
          {projectid}
          {client}
          {userid}
          {alltaskview}
          {projectsview}
          on:fetchProject={() => {
            fetchProject();
          }}
        />
      </div>
    {/if}
  </div>
</div>
{#if showRemovedProjectUserModal}
  <!-- Removal Modal -->
  <div
    id="exampleModalCenter"
    class="modal"
    tabindex="-1"
    role="dialog"
    transition:fade
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
      transition:fly={{ y: 10 }}
    >
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="exampleModalCenterTitle">
            You've been removed from this project.
          </h4>
        </div>
        <div class="modal-body">
          <p>
            Please reach out to a project manager or admin for your organization
            to be added to the project. Otherwise, navigate back to view your
            projects.
          </p>
        </div>
        <div class="modal-footer">
          <div class="col d-grid">
            <a href={projectsUrl} type="button" class="btn btn-primary">
              Back to Your Projects
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End Modal -->
{/if}
