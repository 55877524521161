<script>
  import { onMount } from "svelte";
  import axios from "axios";
  import { getCSRFToken } from "../../utils";
  import Loader from "../Loader.svelte";
  import { fade, fly } from "svelte/transition";
  import { tooltip } from "../bootstrapActivate";
  import { clickOutside } from "../clickOutside";
  import { createEventDispatcher } from "svelte";
  import { getContext } from "svelte";
  import dayjs from "dayjs";

  export let invite;
  export let roles;

  let permissions = getContext("permissions");

  const dispatch = createEventDispatcher();
</script>

<tr in:fade
  ><td
    ><div class="d-flex align-items-center">
      <div class="flex-grow-1 ms-3">
        <h6 class="mb-0">{invite.invitee_email}</h6>
      </div>
    </div></td
  ><td
    ><small class="d-block"
      >Sent at {dayjs(invite.sent_at).format("ddd, MMM D")}</small
    ></td
  >
  <td>
    {#if permissions.includes("change_organization_members")}
      <!-- Select -->
      <select
        bind:value={invite.role}
        on:change={() => {
          dispatch("updateInvite");
        }}
        class="js-select form-select form-select-sm"
        name="invite-access"
      >
        {#each roles as role}
          <option value={role.choice}>
            {role.text}
          </option>
        {/each}
      </select>
      <!-- End Select -->
    {:else}
      {invite.role[0] + invite.role.substring(1).toLowerCase()}
    {/if}
  </td>
  {#if permissions.includes("change_organization_members")}
    <td
      ><button
        class="btn btn-ghost"
        title="Delete Invite"
        on:click={() => {
          dispatch("deleteInvite");
        }}
        disabled=""><i class="bi-trash" /></button
      ></td
    >
  {/if}</tr
>
