<script>
  import { fade, slide } from "svelte/transition";
  import { onMount } from "svelte";
  import { getCSRFToken } from "../utils";
  import Loader from "./Loader.svelte";
  export let userProfileImage;
  export let userEmail;
  export let editUserUrl;
  export let changePasswordUrl;
  export let userIsSuperuser;
  export let discordUrl;
  export let logoutUrl;
  export let userFirstName;
  export let currentOrganizationId;
  export let orgUsersApi;

  let loading = 1;
  let user = {};
  let organizations = [];
  let selected;

  async function fetchOrganizations() {
    const response = await fetch(`${orgUsersApi}`);

    if (response.ok) {
      const result = await response.json();
      organizations = result.results;
      for (let i = 0; i < organizations.length; i++) {
        if (organizations[i].id == currentOrganizationId) {
          selected = organizations[i];
          break;
        }
      }
    } else {
      error = "Failed to load user details.";
    }
  }

  async function setCurrentOrganization() {
    window.location = `${selected.view_projects_url}`;
  }

  onMount(async () => {
    await fetchOrganizations();
    loading--;
  });
</script>

<div class="row align-items-center" in:slide>
  <!-- Col -->
  <div class="col">
    <div class="d-flex align-items-center">
      <!-- Select Company Dropdown -->
      <div class="btn-group d-block" in:fade>
        {#if loading > 0}
          <div class="avatar avatar-md avatar-white me-2">
            <span class="avatar-initials"
              ><div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div></span
            >
          </div>
        {:else}
          <button
            class="btn p-0 m-0"
            type="button"
            data-bs-toggle="dropdown"
            data-bs-dropdown-animation
            in:fade
          >
            <!-- Avatar -->
            {#if selected.profile_image}
              <div class="avatar avatar-md me-2">
                <img
                  data-testid="organizationLogo"
                  class="avatar-img"
                  src={selected.profile_image}
                  alt="{selected.name}'s Profile Image"
                />
              </div>
            {:else}
              <div class="avatar avatar-md avatar-primary me-2">
                <span
                  class="avatar-initials"
                  style="background-color:{selected.brand_color}"
                  >{selected.name[0]}</span
                >
              </div>
            {/if}
            <!-- End Avatar -->
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            {#each organizations as organization}
              <div class="hs-has-sub-menu">
                <div
                  class="dropdown-item d-flex align-items-center justify-content-between p-2"
                >
                  <button
                    class="d-flex align-items-center btn btn-sm btn-link-dark p-0 border-none"
                    on:click={() => {
                      selected = organization;
                      setCurrentOrganization();
                    }}
                  >
                    {#if organization.profile_image}
                      <div class="avatar avatar-sm">
                        <img
                          class="avatar-img"
                          src={organization.profile_image}
                          alt="{organization.name}'s Profile Image"
                        />
                      </div>
                    {:else}
                      <div class="avatar avatar-sm avatar-primary">
                        <span
                          class="avatar-initials"
                          style="background-color:{organization.brand_color}"
                          >{organization.name[0]}</span
                        >
                      </div>
                    {/if}
                    <span class="mx-2"> {organization.name}</span>
                  </button>
                  {#if organization.user_permissions.some( (permission) => ["change_billing", "change_organization_members", "change_organization_profile"].includes(permission) )}
                    <a
                      href={organization.organization_details_url}
                      class="btn btn-sm btn-icon btn-soft-secondary"
                      ><i class="bi-pencil" /></a
                    >
                  {/if}
                </div>
              </div>
            {/each}
          </div>
        {/if}
      </div>
      <!-- End Select Company Dropdown -->

      <div class="d-block">
        <!-- User Name -->
        <h1 class="h2 text-white mb-0">Hi, {userFirstName}!</h1>
        <!-- End User Name -->

        <!-- Breadcrumb -->
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb breadcrumb-light mb-0">
            <li class="breadcrumb-item">
              <div class="pb-1">
                {#if loading > 0}<div in:fade>
                    <span class="btn btn-sm text-white p-0">Loading...</span>
                  </div>{:else}
                  <span class="small" data-testid="currentWorkspace"
                    >{selected.name}'s Workspace</span
                  >
                {/if}
              </div>
            </li>
          </ol>
        </nav>
        <!-- End Breadcrumb -->
      </div>
    </div>
  </div>
  <!-- End Col -->

  <!-- Col -->
  <div class="col-auto">
    <div class="d-none d-lg-flex align-items-center">
      <a
        class="btn btn-link text-white btn-sm me-1"
        href="https://docs.google.com/forms/d/e/1FAIpQLSctXcfdyjhE1h-uCoPk9wszjxa5GcdN2UHx_zCkMxRJ4TBs1w/viewform?usp=pp_url&entry.1771459563={userEmail}"
        target="_blank"
        ><i class="me-2 bi bi-chat-square-text-fill" />Request a Feature</a
      >
      <a
        class="btn btn-link text-white btn-sm me-1"
        href={discordUrl}
        target="_blank"><i class="me-2 bi bi-discord" />Join Beta Community</a
      >
      <!-- User Settings Dropdown -->
      <div class="btn-group d-none d-md-block" in:fade>
        <button
          class="btn p-0"
          type="button"
          data-bs-toggle="dropdown"
          data-bs-dropdown-animation
        >
          <!-- Avatar -->
          {#if userProfileImage}
            <div class="avatar avatar-md avatar-circle me-2">
              <img
                class="avatar-img"
                src={userProfileImage}
                alt="{userFirstName}'s Profile Image"
              />
            </div>
          {:else}
            <div class="avatar avatar-md avatar-primary avatar-circle me-2">
              <span class="avatar-initials"
                >{userFirstName[0]}{userFirstName[0]}</span
              >
            </div>
          {/if}
          <!-- End Avatar -->
        </button>

        <div
          class="dropdown-menu dropdown-menu-end"
          aria-labelledby="dropdownMenuButton"
        >
          <a class="dropdown-item" href={editUserUrl}>
            <i class="bi-person-badge dropdown-item-icon" /> Personal Info
          </a>
          <a class="dropdown-item" href={changePasswordUrl}>
            <i class="bi-shield-shaded dropdown-item-icon" /> Password / Security
          </a>
          {#if userIsSuperuser === "True"}
            <div class="dropdown-divider" />
            <a class="dropdown-item" href="/admin">
              <i class="bi-gear-wide-connected dropdown-item-icon" /> Admin
            </a>
          {/if}
          <div class="dropdown-divider" />
          <a class="dropdown-item" href={logoutUrl}>
            <i class="bi-box-arrow-right dropdown-item-icon" /> Log Out
          </a>
        </div>
      </div>
      <!-- End User Settings Dropdown -->
    </div>

    <!-- Responsive Toggle Button -->
    <button
      class="navbar-toggler d-lg-none"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#sidebarNav"
      aria-controls="sidebarNav"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-default">
        <i class="bi-list" />
      </span>
      <span class="navbar-toggler-toggled">
        <i class="bi-x" />
      </span>
    </button>
    <!-- End Responsive Toggle Button -->
  </div>
  <!-- End Col -->
</div>
<!-- End Row -->
