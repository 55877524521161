<script>
  import Task from "./Task.svelte";
  import { taskCreateSchema } from "../schema";
  import { extractErrors } from "../utils/extract_errors";
  import { onMount } from "svelte";
  import { debounce } from "lodash";
  import { getCSRFToken } from "../utils";
  import { clickOutside } from "./clickOutside.js";
  import Loader from "./Loader.svelte";
  import { fade, fly } from "svelte/transition";
  import dayjs from "dayjs";
  import { showCompletedTasks } from "../store.js";
  import { createEventDispatcher } from "svelte";
  import { tooltip } from "./bootstrapActivate";
  export let projectid = "";
  export let userid = "";
  export let alltaskview = null;
  export let projectsview = null;
  export let project = {};
  export let tasksApiUrl = null;
  export let clientremindersApiUrl = null;
  export let client = {};
  export let memberships = [];
  export let existingProjectList = [];
  export let newProjectUrl = "";

  import { getContext } from "svelte";

  let permissions = getContext("permissions");

  let taskComponent;

  const dispatch = createEventDispatcher();

  let loading = 1;

  let fetchClientReminders = {};

  let tasks = [];
  let handle = null;
  let hoverTask = null;
  let dragTask = null;
  let dragMountConfig = null;
  let error = "";
  let errors = {};
  let taskName = "";
  let taskDeadline = "";
  let taskMessage = "";
  let assignMessage = "";
  let shouldNotifyClientOfAssignment = null;
  let keyCode = null;

  let newTask = {
    name: "",
    project: null,
    user: null,
    order_number: null,
  };

  // exported for newTask button focus() in MyTasks
  export let newTaskInput = null;
  let taskCount = 0;
  let editing = false;
  let projectIsTemplate = null;
  let day = dayjs();
  let user;
  let canCreateNewTask = false;
  let showDropdown;

  $: projectIsTemplate = project.is_template;
  $: taskCount = tasks.length;
  // canCreateNewTask is essentially pre-validation
  // not necessary but nice to have.
  $: if (alltaskview) {
    canCreateNewTask = newTask.name != "" && newTask.project != null;
  } else {
    canCreateNewTask = newTask.name != "";
  }

  function clickNewTask() {
    editing = true;
  }

  export async function fetchTasks() {
    // If no project id provided for the component, get all tasks.
    if (projectid != "") {
      const response = await fetch(`${tasksApiUrl}?project=${projectid}`);
      if (response.ok) {
        const result = await response.json();
        if (!projectsview) {
          tasks = result.results.sort(
            (a, b) => a.order_number - b.order_number
          );
          taskCount = tasks.length;
          return taskCount;
        } else {
          tasks = result.results
            .filter((task) => task.is_complete === false)
            .sort((a, b) => a.order_number - b.order_number)
            .slice(0, 3);
          taskCount = tasks.length;
          return taskCount;
        }
      } else {
        error = "Failed to load tasks.";
      }
    } else {
      // If all-tasks, only get user's all tasks.
      const response = await fetch(`${tasksApiUrl}?user=${userid}`);

      if (response.ok) {
        const result = await response.json();
        tasks = result.results.sort((a, b) => a.project.id - b.project.id);
        taskCount = tasks.length;
        return taskCount;
      } else {
        error = "Failed to load tasks.";
      }
    }
  }

  function unmount(el) {
    const mountConfig = {
      el: el,
      position: el.position,
      left: el.left,
    };

    el.style.position = "fixed";
    el.style.left = "-9999px";

    return mountConfig;
  }

  function remount(mountConfig) {
    if (!mountConfig) return;

    const { el, position, left } = mountConfig;
    el.style.position = "relative";
    el.style.left = "auto";
  }

  function onDrop(event) {
    event.preventDefault();
  }

  function onDragOver(event) {
    event.preventDefault();
  }

  async function onDragEnd(event) {
    handle = null;
    const oldIndex = tasks.indexOf(dragTask);
    const newIndex = tasks.indexOf(hoverTask);

    tasks.splice(oldIndex, 1);
    tasks.splice(newIndex, 0, dragTask);

    tasks = [...tasks];

    const response = await fetch(dragTask.url + "order_number/", {
      method: "PUT",
      body: JSON.stringify({
        ...dragTask,
        order_number: newIndex,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CsrfToken": getCSRFToken(),
      },
      credentials: "include",
    });

    if (response.ok) {
      fetchTasks();
    } else {
      error = "Failed to re-order tasks.";
    }

    hoverTask = null;
    dragTask = null;

    remount(dragMountConfig);

    dragMountConfig = null;
  }

  function onDragStart(event, task) {
    if (!handle || !event.target.contains(handle)) {
      event.preventDefault();
      return;
    }
    event.dataTransfer.effectAllowed = "move";
    event.dataTransfer.dropEffect = "move";
    event.dataTransfer.setData("text/plain", task);

    const dragEl = event.target;

    event.dataTransfer.setDragImage(dragEl, 0, 0);

    setTimeout(() => {
      dragMountConfig = unmount(dragEl);
    }, 1);

    dragTask = task;
  }

  function mouseDownHandle(event) {
    handle = event.detail;
  }

  onMount(async () => {
    await fetchTasks();

    for (let membership of memberships) {
      if (membership.user.id == userid) {
        user = { ...membership.user };
        delete user.profile_image;
      }
    }
    --loading;
    // dispatches loaded for parent components that rely
    // on this component to render siblings in parent.
    dispatch("loaded");

    const interval = setInterval(() => {
      day = dayjs();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  });

  async function hitEnter(event) {
    keyCode = event.keyCode;
    if (keyCode == 13 && newTask.name !== "") {
      createNewTask();
    }
  }

  const debouncedFetchTasksProject = debounce(async () => {
    await fetchTasks();
    dispatch("fetchProject");
  }, 1000);

  async function validateNewTaskForm() {
    let form = {
      taskName: newTask.name,
      project: newTask.project,
    };

    return taskCreateSchema
      .validate(form, { abortEarly: false })
      .then(() => {
        // clear the errors
        errors = {};
        return true;
      })
      .catch((err) => {
        errors = extractErrors(err);
        return false;
      });
  }

  async function createNewTask() {
    if (!alltaskview) {
      newTask.project = project;
    }

    let formIsValid = await validateNewTaskForm();
    if (formIsValid) {
      if (alltaskview) {
        // ensures taskCount is scoped to the selected project
        taskCount = tasks.filter(
          (task) => task.project.id == newTask.project.id
        ).length;
      }
      newTask.order_number = +taskCount;

      let newLocalTask = {
        ...newTask,
        id: `temp-${+taskCount}-proj-${newTask.project.id}`,
      };
      tasks = [...tasks, newLocalTask];

      const response = await fetch(`${tasksApiUrl}`, {
        method: "POST",
        body: JSON.stringify(newTask),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-CsrfToken": getCSRFToken(),
        },
        credentials: "include",
      });

      if (response.ok) {
        debouncedFetchTasksProject();
        newTask.name = "";
      }
    }
  }

  async function toggleClientVisibility(event, task) {
    await fetch(task.url + "toggle_client_visibility/", {
      method: "PUT",
      body: JSON.stringify({
        ...task,
        is_hidden_from_client: task.is_hidden_from_client,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CsrfToken": getCSRFToken(),
      },
      credentials: "include",
    });
    await fetchTasks();
  }

  async function toggleIsAssignedToClient(event, task) {
    shouldNotifyClientOfAssignment =
      event.detail.should_notify_client_of_assignment;

    if (shouldNotifyClientOfAssignment) {
      try {
        assignMessage = event.detail.message;
      } catch {
        assignMessage = "";
      }
    }

    await fetch(task.url + "is_assigned_to_client/", {
      method: "PUT",
      body: JSON.stringify({
        ...task,
        should_notify_client_of_assignment: shouldNotifyClientOfAssignment,
        message: assignMessage,
        is_assigned_to_client: task.is_assigned_to_client,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CsrfToken": getCSRFToken(),
      },
      credentials: "include",
    }).then(fetchTasks);

    if (task.is_assigned_to_client) {
      await fetchClientReminders[task.id]?.();
    }
    dispatch("fetchProject");
  }

  async function sendNotificationAssignedToClient(event, task) {
    try {
      assignMessage = event.detail.message;
    } catch {
      assignMessage = "";
    }

    await fetch(task.url + "send_notification_assigned_to_client/", {
      method: "PUT",
      body: JSON.stringify({
        ...task,
        message: assignMessage,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CsrfToken": getCSRFToken(),
      },
      credentials: "include",
    });
    await fetchTasks();
  }

  async function sendReminderAssignedToClient(event, task) {
    await fetch(task.url + "send_reminder_assigned_to_client/", {
      method: "PUT",
      body: JSON.stringify({
        ...task,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CsrfToken": getCSRFToken(),
      },
      credentials: "include",
    });
    await fetchTasks();
  }

  async function setTaskEstimatedDuration(event, task) {
    await fetch(task.url + "set_estimated_duration/", {
      method: "PUT",
      body: JSON.stringify({
        ...task,
        duration_in_days: task.duration_in_days,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CsrfToken": getCSRFToken(),
      },
      credentials: "include",
    });
    await fetchTasks();
  }

  async function completeTask(event, task) {
    taskMessage = event.detail;
    if (taskMessage) {
      if (taskMessage.message || taskMessage.deliverable_link) {
        await fetch(task.url + "is_complete/", {
          method: "PUT",
          body: JSON.stringify({
            ...task,
            is_complete: !task.is_complete,
            message: taskMessage.message,
            deliverable_link: taskMessage.deliverable_link,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-CsrfToken": getCSRFToken(),
          },
          credentials: "include",
        });
      } else {
        await fetch(task.url + "is_complete/", {
          method: "PUT",
          body: JSON.stringify({
            ...task,
            is_complete: !task.is_complete,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-CsrfToken": getCSRFToken(),
          },
          credentials: "include",
        });
      }
    } else {
      await fetch(task.url + "is_complete/", {
        method: "PUT",
        body: JSON.stringify({
          ...task,
          is_complete: !task.is_complete,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-CsrfToken": getCSRFToken(),
        },
        credentials: "include",
      });
    }
    task.isCompletingTask = false;
    await fetchTasks();
    dispatch("fetchProject");
  }

  async function completeTaskNotifyOff(event, task) {
    await fetch(task.url + "is_complete_notify_off/", {
      method: "PUT",
      body: JSON.stringify({
        ...task,
        is_complete: !task.is_complete,
        should_notify_client: false,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CsrfToken": getCSRFToken(),
      },
      credentials: "include",
    });
    await fetchTasks();
    dispatch("fetchProject");
  }

  async function toggleDeliverable(event, task) {
    fetch(task.url + "toggle_deliverable/", {
      method: "PUT",
      body: JSON.stringify({
        ...task,
        is_deliverable: task.is_deliverable,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CsrfToken": getCSRFToken(),
      },
      credentials: "include",
    }).then(fetchTasks);
  }

  async function toggleNotifyClient(event, task) {
    fetch(task.url + "toggle_notify_client/", {
      method: "PUT",
      body: JSON.stringify({
        ...task,
        should_notify_client: task.should_notify_client,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CsrfToken": getCSRFToken(),
      },
      credentials: "include",
    }).then(fetchTasks);
  }

  async function updateTaskName(event, task) {
    taskName = event.detail;
    fetch(task.url + "update_task_name/", {
      method: "PUT",
      body: JSON.stringify({
        ...task,
        name: taskName,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CsrfToken": getCSRFToken(),
      },
      credentials: "include",
    }).then(fetchTasks);
  }

  async function deleteTask(event, task) {
    const taskIndex = tasks.findIndex(
      (existing_task) => existing_task.id === task.id
    );
    if (taskIndex !== -1) {
      tasks.splice(taskIndex, 1);
    }
    tasks = [...tasks];

    await fetch(task.url + "delete_task/", {
      method: "DELETE",
      body: JSON.stringify({
        ...task,
        order_number: task.order_number,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CsrfToken": getCSRFToken(),
      },
      credentials: "include",
    });
    debouncedFetchTasksProject();
  }

  async function updateDeadline(event, task) {
    taskDeadline = event.detail;
    await fetch(task.url + "update_task_deadline/", {
      method: "PUT",
      body: JSON.stringify({
        ...task,
        deadline: taskDeadline,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CsrfToken": getCSRFToken(),
      },
      credentials: "include",
    }).then(fetchTasks);

    if (task.is_assigned_to_client) {
      await fetchClientReminders[task.id]?.();
    }
  }

  const updateTaskDetail = debounce((event, task) => {
    let taskDetail = event.detail.quillDetail;
    let stillEditing = event.detail.isEditingTaskDetail;
    fetch(task.url + "update_task_detail/", {
      method: "PUT",
      body: JSON.stringify({
        ...task,
        detail: taskDetail,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CsrfToken": getCSRFToken(),
      },
      credentials: "include",
    }).then(() => {
      if (!stillEditing) {
        fetchTasks();
      }
    });
  }, 500);

  async function updateTaskUser(event, task) {
    await fetch(task.url + "update_task_user/", {
      method: "PUT",
      body: JSON.stringify({
        ...task,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CsrfToken": getCSRFToken(),
      },
      credentials: "include",
    }).then(fetchTasks);
    dispatch("fetchProject");
  }
</script>

<div class="d-grid gap-3 gap-lg-5">
  <!-- Card -->
  <div class="card">
    <!-- Table -->
    {#if loading > 0}
      <td colspan="6" class="border-bottom">
        <div in:fade class="d-flex justify-content-center">
          <Loader />
        </div>
      </td>
    {:else}
      <table class="table table-thead-bordered table-align-middle table-hover">
        {#if !projectsview}
          <thead class="thead-light">
            <tr>
              {#if !alltaskview & permissions.includes("change_tasks")}
                <th style="width: 2%" class="text-body text-center" />
              {/if}
              {#if !projectIsTemplate}
                <th
                  style="width: 2%;"
                  class="text-body text-center {alltaskview ||
                  projectsview ||
                  !permissions.includes('change_tasks')
                    ? 'pe-0'
                    : 'px-0'}"
                >
                  <i class="bi-check2-circle" />
                </th>
              {/if}
              <th
                style=""
                class="text-body"
                on:click={() => {
                  if (permissions.includes("create_tasks")) {
                    clickNewTask();
                    newTaskInput.focus();
                  }
                }}>Task Name</th
              >
              {#if alltaskview}
                <th
                  style="width: 5%;"
                  class="text-body text-center d-none d-md-table-cell"
                  ><i class="bi-folder" /></th
                >
              {/if}
              <th style="width: 5%;" class="text-body text-center"
                ><i class="bi-person" /></th
              >
              {#if projectIsTemplate}
                <th
                  style="width: 5%;"
                  class="text-body text-center d-none d-md-table-cell"
                  title="Estimated Duration"
                  use:tooltip={"top"}
                >
                  <i class="bi-hourglass-split" />
                </th>
              {:else}
                <th
                  style="width: 5%;"
                  class="text-body text-center d-none d-md-table-cell"
                  title="Deadline"
                  use:tooltip={"top"}
                >
                  <i class="bi-calendar-event" />
                </th>
              {/if}
              <th
                style="width: 5%;"
                class="text-body text-center"
                use:tooltip
                title="Task Settings"
                aria-label="Task Settings"><i class="bi bi-toggles" /></th
              >
            </tr>
          </thead>
        {/if}
        <tbody>
          <!-- Table Body -->
          {#if error}
            <td colspan="6" class="border-bottom">
              <div in:fade class="d-flex justify-content-center">
                <div class="d-flex">
                  <div class="flex-shrink-0">
                    <i class="bi-exclamation-triangle-fill" />
                  </div>
                  <div class="flex-grow-1 ms-2">{error}</div>
                </div>
              </div>
            </td>
          {:else if taskCount > 0}
            {#each tasks as task, index}
              {#if hoverTask === task && index < tasks.indexOf(dragTask)}
                <Task task={dragTask} hidden={true} {projectIsTemplate} />
              {/if}
              <Task
                bind:project
                bind:isCompletingTask={task.isCompletingTask}
                bind:this={taskComponent}
                bind:fetchClientReminders={fetchClientReminders[task.id]}
                {task}
                {day}
                {client}
                {clientremindersApiUrl}
                {memberships}
                draggable={true}
                hovering={hoverTask === task}
                {projectIsTemplate}
                {alltaskview}
                {projectsview}
                showcompletetasks={$showCompletedTasks}
                on:fetchTasks={fetchTasks}
                on:mouseDownHandle={mouseDownHandle}
                on:dragover={() => (hoverTask = task)}
                on:dragstart={(event) => onDragStart(event, task)}
                on:dragend={onDragEnd}
                on:clickComplete={(event) => completeTask(event, task)}
                on:clickToggleClientVisibility={(event) =>
                  toggleClientVisibility(event, task)}
                on:clickCompleteNotifyOff={(event) =>
                  completeTaskNotifyOff(event, task)}
                on:clickDeliverable={(event) => toggleDeliverable(event, task)}
                on:clickNotifyClient={(event) =>
                  toggleNotifyClient(event, task)}
                on:clickToggleIsAssignedToClient={(event) =>
                  toggleIsAssignedToClient(event, task)}
                on:clickSendNotificationAssignedToClient={(event) =>
                  sendNotificationAssignedToClient(event, task)}
                on:clickSendReminderAssignedToClient={(event) =>
                  sendReminderAssignedToClient(event, task)}
                on:clickSetTaskEstimatedDuration={(event) =>
                  setTaskEstimatedDuration(event, task)}
                on:hitReturnKey={(event) => updateTaskName(event, task)}
                on:clickOutsideTask={(event) => updateTaskName(event, task)}
                on:deleteTask={(event) => deleteTask(event, task)}
                on:changeDeadline={(event) => updateDeadline(event, task)}
                on:updateTaskDetail={(event) => updateTaskDetail(event, task)}
                on:updateTaskUser={(event) => {
                  updateTaskUser(event, task);
                }}
              />
              {#if hoverTask === task && index > tasks.indexOf(dragTask)}
                <Task task={dragTask} hidden={true} {projectIsTemplate} />
              {/if}
            {/each}
            <!-- </div> -->
          {:else}
            <td colspan="6" class="border-bottom">
              <div class="text-center content-space-1">
                <img
                  class="avatar avatar-lg mb-2"
                  src="/static/pages/svg/illustrations/empty-state-no-data.svg"
                  alt="No tasks here yet."
                />
                <p class="card-text">
                  {#if project.percent_complete === 100}
                    All tasks completed.
                  {:else}
                    No tasks yet. {#if projectsview}
                      Open the project{:else if alltaskview}
                      Create a project
                    {:else}
                      Click below{/if} to add some.
                  {/if}
                </p>
              </div>
            </td>
          {/if}
          {#if !projectsview && permissions.includes("create_tasks")}
            <tr class="task text-secondary border-top">
              <td colspan="6" class="px-md-2 py-2">
                <div
                  class="input-card new-task py-2 {Object.keys(errors).length >
                  0
                    ? 'border border-danger'
                    : ''}"
                  use:clickOutside
                  on:click_outside={() => {
                    editing = false;
                  }}
                >
                  <div
                    class="input-card-form d-flex flex-column flex-md-row pe-1"
                  >
                    <input
                      id="addNewTask"
                      type="text"
                      placeholder={!editing
                        ? "+ Add Task"
                        : "Type here and hit 'Enter' to add task"}
                      class="form-control-xs task-name new p-1 flex-fill"
                      bind:this={newTaskInput}
                      bind:value={newTask.name}
                      on:click={clickNewTask}
                      on:keydown={hitEnter}
                    />
                    {#if alltaskview && editing}
                      <div transition:fly={{ x: -10 }}>
                        <button
                          type="button"
                          class="btn text-wrap {errors.project
                            ? 'btn-soft-danger'
                            : 'btn-white'} rounded-pill btn-xs dropdown-toggle project-selector"
                          data-bs-toggle="dropdown"
                          on:focus={() => {
                            showDropdown = true;
                          }}
                          on:blur={(e) => {
                            if (
                              e.relatedTarget &&
                              !e.relatedTarget.closest("#project-selector")
                            ) {
                              showDropdown = false;
                            }
                          }}
                          tabindex="0"
                        >
                          {newTask.project
                            ? newTask.project.name
                            : "Select Project"}
                        </button>
                        <div
                          tabindex="-1"
                          id="project-selector"
                          class="dropdown-menu dropdown-menu-end {showDropdown
                            ? 'show'
                            : ''}"
                          style="max-height:150px; overflow-y:auto"
                        >
                          {#each existingProjectList as existingProject}
                            <button
                              tabindex="-1"
                              class="dropdown-item {existingProject ==
                              newTask.project
                                ? 'active'
                                : ''}"
                              type="button"
                              on:click={() => {
                                newTask.project = existingProject;
                                showDropdown = false;
                              }}
                              on:keydown|preventDefault={(e) => {
                                if (e.key == "Enter") {
                                  newTask.project = existingProject;
                                  showDropdown = false;
                                  newTaskInput.focus();
                                } else if (e.key == "Tab") {
                                  newTask.project = existingProject;
                                  showDropdown = false;

                                  newTaskInput.focus();
                                } else if (e.key == "Tab" && e.shiftKey) {
                                  showDropdown = false;
                                  newTaskInput.focus();
                                }
                              }}>{existingProject.name}</button
                            >
                          {/each}
                          <hr class="dropdown-divider" />
                          <a class="dropdown-item" href={newProjectUrl}
                            ><i class="bi bi-folder-plus me-2" />Create New
                            Project</a
                          >
                        </div>
                      </div>
                    {/if}
                  </div>
                  {#if editing}
                    <button
                      transition:fly={{ x: -10 }}
                      tabindex="0"
                      on:click|preventDefault={() => {
                        if (editing && canCreateNewTask) {
                          createNewTask();
                        }
                      }}
                      class="btn btn-xs {editing && canCreateNewTask
                        ? 'btn-primary'
                        : 'btn-link-secondary'}"
                      disabled={!canCreateNewTask}
                      ><i class="bi bi-plus" /><span
                        class={editing ? "d-none d-md-inline" : ""}
                        >Add Task</span
                      ></button
                    >
                  {/if}
                </div>
                {#if errors}
                  {#each Object.values(errors) as error}
                    <span class="text-danger small">{error}</span>
                  {/each}
                {/if}
              </td>
            </tr>
          {/if}
        </tbody>
      </table>
    {/if}
  </div>
</div>
