<script>
  import axios from "axios";
  import { onMount } from "svelte";
  import { getCSRFToken } from "../../utils";
  import { setContext } from "svelte";

  import ProjectForm from "./ProjectForm.svelte";

  export let contactsApiUrl;
  export let clientsApiUrl;
  export let organizationProjectsUrl;

  let projectForm;
  let formIsValid;
  export let convertProjectToTemplate = false;
  export let user = "";
  export let currentOrg = "";

  setContext("user", user);
  setContext("currentOrg", currentOrg);

  export let projectUri = "";
  export let httpReferrer = "";

  let project = {};
  let projectContactIds = [];
  let availableContacts = [];

  // Reassigns the contacts from selectedContact IDs
  // based on available contacts for the client.
  async function assignContacts(contactIds, availableContacts) {
    let selectedContacts = [];
    for (var i = 0; i < contactIds.length; i++) {
      for (var j = 0; j < availableContacts.length; j++) {
        if (contactIds[i] === availableContacts[j].id) {
          selectedContacts.push(availableContacts[j]);
        }
      }
    }
    project.contacts = selectedContacts;
  }
  async function update() {
    if (projectForm.checkIsAddingContact()) {
    } else {
      formIsValid = await projectForm.validateForm();
      if (formIsValid) {
        assignContacts(projectContactIds, availableContacts);
        try {
          const response = await axios.put(
            convertProjectToTemplate
              ? projectUri + "create_project_from_template/"
              : projectUri,
            project,
            {
              headers: {
                "X-CsrfToken": getCSRFToken(),
              },
            }
          );

          const newProject = response.data;
          console.log(newProject);
          window.location.href = convertProjectToTemplate
            ? `${newProject.view_project_url}`
            : httpReferrer != ""
            ? httpReferrer
            : `${organizationProjectsUrl}`;
        } catch (e) {
          console.error("Failed to update project:", e);
        }
      }
    }
  }

  onMount(async () => {
    try {
      const response = await axios.get(projectUri);
      project = response.data;

      // Strips the IDs out of the project.contacts
      // to enable selection from available projects
      // with checkboxes.
      if (project.contacts) {
        for (let i = 0; i < project.contacts.length; i++) {
          projectContactIds.push(project.contacts[i].id);
        }
      }
    } catch (e) {
      console.error("Failed to load project:", e);
    }
  });
</script>

{#if project}
  <ProjectForm
    {clientsApiUrl}
    {contactsApiUrl}
    bind:this={projectForm}
    bind:projectName={project.name}
    bind:client={project.client}
    bind:key_contact={project.key_contact}
    bind:selectedContacts={projectContactIds}
    bind:availableContacts
    bind:startDate={project.start_date}
    {convertProjectToTemplate}
  />
{/if}
<div class="d-flex justify-content-end">
  <button type="submit" class="btn btn-primary" on:click={update}
    >{#if convertProjectToTemplate}Create Project{:else}Update Project{/if}</button
  >
</div>
