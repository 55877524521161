// yup schema.js

import * as yup from 'yup';

const taskCreateSchema = yup.object().shape({
  taskName: yup.string().required(
    'Please provide a task name.'
  ).max(255, "We recommend task names less than 255 characters."),
  project: yup.object().nullable(true).notOneOf([null], 'Your task must belong to a project.').required('Your task must belong to a project.'),
});

const projCreateSchema = yup.object().shape({
  projectName: yup.string().required(
    'Please provide a project name.'
  ),  
  selectedContacts: yup.array().min(1, "Your project must have at least one client contact.").max(5, "Your project may not have more than 5 contacts.").required('Your project must have a client contact.'),
});

const projCreateContactSchema = yup.object().shape({
  projectName: yup.string().required('This is a required field.'),
  firstName: yup.string().required('This is a required field.'),
  lastName: yup.string().required('This is a required field.'),
  title: yup.string(),
  email: yup.string().required('This is a required field.').email('Email must be a valid email.'),
});

const projCreateClientContactSchema = yup.object().shape({
  projectName: yup.string().required('This is a required field.'),
  clientName: yup.string().required('This is a required field.'),
  firstName: yup.string().required('This is a required field.'),
  lastName: yup.string().required('This is a required field.'),
  title: yup.string(),
  email: yup.string().required('This is a required field.').email('Email must be a valid email.'),
});

const projKickoffEmailSchema = yup.object().shape(
  {heading: yup.string().max(100, 'Too many characters').required('This is a required field.'),
    subheading: yup.string().max(100, 'Too many characters').required('This is a required field.'),
    message: yup.string().max(1500, 'Too many characters').required('This is a required field.'),
    closing: yup.string().max(1000, 'Too many characters').required('This is a required field.'),
});

const deliverableSchema = yup.object().shape({
  taskDeliverableLink: yup.string().url(
    'This isn\'t a valid link'
    ).min(1, 'Did you forget to add a deliverable link?'),
  taskMessage: yup.string().max(1000, 'Too many characters'),
});

const assignMessageSchema = yup.object().shape({
  assignMessage: yup.string().max(1000, 'Too many characters'),
});


const detailSchema = yup.object().shape({
  taskDetail: yup.string().max(1500, 'Too long. Not saved!'),
});

// Manage Memberships

const sendInviteSchema = yup.object().shape({
  email: yup.string().required('This is a required field.'),
});

// Manage Organization (First Time)

const setOrganizationSchema = yup.object().shape({
  name: yup.string().max(255, 'Your organization name is too long.').required('Organization name is a required field.'),
  website: yup.string().url('Website must be a valid website. Please include http:// or https://'),
  description: yup.string().max(255, 'Your organization description is too long. Max is 255 characters.'),
});

export { taskCreateSchema, projCreateSchema, projCreateContactSchema, projCreateClientContactSchema, projKickoffEmailSchema, deliverableSchema, detailSchema, assignMessageSchema, sendInviteSchema, setOrganizationSchema };