import ProjectTasks from './components/ProjectTasks.svelte';
import TemplateTasks from './components/TemplateTasks.svelte';
import Tasks from './components/Tasks.svelte';
import Task from './components/Task.svelte';
import Pricing from './components/Pricing.svelte';
import ReviewTask from './components/ReviewTask.svelte';
import ProjectClientReview from './components/ProjectClientReview.svelte'
import WelcomeMessageModal from './components/WelcomeMessageModal.svelte';
import WelcomeChecklist from './components/welcome_checklist/WelcomeChecklist.svelte';
import SetOrganizationPage from './components/SetOrganizationPage.svelte';
import SetOrganizationDropdown from './components/SetOrganizationDropdown.svelte';
import MyTasks from './components/MyTasks.svelte';
import * as projectComponents from './components/project';
import * as projectListComponents from './components/project_list';
import * as organizationComponents from './components/organization';
import * as websocketComponents from './components/websocket';

const components = {
  ProjectTasks,
  TemplateTasks,
  Tasks,
  MyTasks,
  Task,
  ProjectClientReview,
  ReviewTask,
  WelcomeMessageModal,
  WelcomeChecklist,
  SetOrganizationDropdown,
  SetOrganizationPage,
  Pricing,
  ...projectComponents,
  ...organizationComponents,
  ...projectListComponents,
  ...websocketComponents,
};

Object.keys(components).forEach(componentName => {
  const selector = `[data-component="${componentName}"]`;

  document.querySelectorAll(selector).forEach(el => {
    const { component, ...props } = el.dataset;

    new components[componentName]({
      props,
      target: el,
    });
  })
});