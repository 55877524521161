/** Dispatch event on click outside of node */
export function clickOutside(node) {
  
  const handleClick = event => {
    const isClickOutsideNode = node != null && !node.contains(event.target) && !event.defaultPrevented;

    if (isClickOutsideNode) {
      node.dispatchEvent(
        new CustomEvent('click_outside', {
          detail: {
            clickEvent: event,
          },
        })
      )
    }
  }

	document.addEventListener('click', handleClick, true);
  
  return {
    destroy() {
      document.removeEventListener('click', handleClick, true);
    }
	}
}