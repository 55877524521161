<script>
  import { fade, fly, slide } from "svelte/transition";
  import { videoPlayer } from "./bootstrapActivate";
  import { onMount } from "svelte";
  import { getCSRFToken } from "../utils";
  export let userid;
  export let founderImage;
  export let backgroundImage;
  export let newProjectUrl;
  export let permissions;

  let user = {};

  let showWelcomeModal = false;

  async function fetchUser() {
    const response = await fetch(
      `/api/users/${userid}?ts=${new Date().getTime()}`
    );

    if (response.ok) {
      const result = await response.json();
      user = result;
      showWelcomeModal = !user.dismissed_welcome_message;
    } else {
      error = "Failed to load user details.";
    }
  }

  async function dismissWelcomeMessage() {
    await fetch(`/api/users/${userid}/`, {
      method: "PUT",
      body: JSON.stringify({
        ...user,
        dismissed_welcome_message: true,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CsrfToken": getCSRFToken(),
      },
      credentials: "include",
    });
    if (permissions.includes("create_projects")) {
      window.location.href = `${newProjectUrl}`;
    } else {
      fetchUser();
    }
  }

  onMount(async () => {
    await fetchUser();
    document
      .getElementById("showWelcomeModalButton")
      .addEventListener("click", function () {
        // Assuming you have a reference to the Svelte component instance
        showWelcomeModal = true;
      });
  });
</script>

{#if showWelcomeModal}
  <div out:fade class="modal">
    <div
      class="modal-dialog modal-dialog-centered modal-lg"
      data-testid="welcomeMessageModal"
    >
      <div in:fly={{ y: 200, duration: 1000 }} class="modal-content">
        {#if user.dismissed_welcome_message}
          <div class="modal-close">
            <button
              on:click={() => {
                showWelcomeModal = false;
              }}
              type="button"
              class="btn-close"
            />
          </div>
        {/if}
        <!-- Body -->
        <div class="modal-body">
          <!-- Heading -->
          <div class="m-1 m-md-5">
            {#if !user.dismissed_welcome_message}
              <div class="float-end avatar avatar-xxl avatar-circle ms-3 mb-3">
                <img class="avatar-img" src={founderImage} alt="Founder" />
              </div>
              <h3 class="mb-3">Howdy {user.first_name},</h3>
              <p>I’m Jean-Marc—your tour guide for today!</p>
              <p>
                For a lightning-fast start, I've made a quick video walkthrough
                for you, highlighting key features.
              </p>
            {/if}

            <div class="rounded-2">
              <!-- Video Block -->
              <div
                id="youTubeVideoPlayer"
                class="video-player video-player-inline-btn rounded-2"
              >
                <img
                  class="img-fluid video-player-preview rounded-2"
                  src={backgroundImage}
                  alt="Walkthrough Video"
                />

                <!-- Play Button -->
                <a
                  class="js-inline-video-player video-player-btn video-player-centered"
                  href="javascript:;"
                  use:videoPlayer={{
                    videoId: "urGRnZaXtmg",
                    parentSelector: "#youTubeVideoPlayer",
                    targetSelector: "#youTubeVideoIframe",
                    isAutoplay: true,
                  }}
                >
                  <span class="video-player-icon shadow-sm">
                    <i class="bi-play-fill" />
                  </span>
                </a>
                <!-- End Play Button -->

                <!-- Video Iframe -->
                <div class="ratio ratio-16x9 rounded-2">
                  <div id="youTubeVideoIframe" class="rounded-2" />
                </div>
                <!-- End Video Iframe -->
              </div>
              <!-- End Video Block -->
            </div>
            {#if !user.dismissed_welcome_message}
              <p class="mt-3">Happy Dunaroo-ing!</p>
              <p>
                <strong>— JM</strong><br /><em
                  >Founder & Wearer of Many Funny Hats</em
                >
              </p>

              <div class="text-center mt-4">
                <p class="small mb-0">
                  <button
                    class="btn btn-primary"
                    on:click={() => {
                      showWelcomeModal = false;
                      dismissWelcomeMessage();
                    }}
                    >{#if permissions.includes("create_projects")}
                      Create Your First Project{:else}Get Started{/if}
                    <i class="bi-chevron-right small ms-1" />
                  </button>
                </p>
              </div>
            {/if}
          </div>
          <!-- End Heading -->
        </div>
      </div>
    </div>
  </div>
{/if}
