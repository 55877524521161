<script>
  import Popover from "svelte-easy-popover";
  import { clickOutside } from "./clickOutside";
  import { fade, slide } from "svelte/transition";
  import { createEventDispatcher } from "svelte";
  import dayjs from "dayjs";

  const dispatch = createEventDispatcher();

  let day = dayjs();

  export let referenceElementComplete;
  export let isShowingCompletePopover;
  export let isShowingCompleteAssignedToClient;
  export let task;
  export let client;
  export let taskMessage;
  export let taskDeliverableLink;
  export let showCC;
  export let messageLength;
  export let errors;
  export let canReSendReminder;
  export let isCompletingTask;

  const clickCompleteNotify = () => {
    dispatch("clickCompleteNotify");
    isShowingCompleteAssignedToClient = false;
  };
  const clickCompleteNotifyOff = () => {
    dispatch("clickCompleteNotifyOff");
  };
  const clickDeliverable = () => {
    dispatch("clickDeliverable");
  };
  const clickSendReminderAssignedToClient = () => {
    dispatch("clickSendReminderAssignedToClient");
    isShowingCompletePopover = false;
  };
</script>

<Popover
  triggerEvents={["click"]}
  referenceElement={referenceElementComplete}
  placement="bottom-start"
  spaceAway={10}
  bind:isOpen={isShowingCompletePopover}
>
  {#if isShowingCompletePopover}
    <div
      class="popover-contents"
      transition:fade={{ duration: 250 }}
      use:clickOutside
      on:click_outside={() => {
        if (isShowingCompletePopover) {
          isCompletingTask = false;
          isShowingCompletePopover = false;
          isShowingCompleteAssignedToClient = false;
        }
      }}
    >
      <div
        class="card p-3 task-message {task.is_complete &&
        task.should_notify_client
          ? 'warning'
          : task.is_assigned_to_client
          ? 'assign-client'
          : ''} is-popup"
      >
        {#if task.is_complete && task.should_notify_client}
          <div class="row text-left">
            <h5>Mark the Task as Incomplete?</h5>
          </div>
          <div class="row">
            <div class="col">
              <p class="mb-2 text-left">
                <em
                  >You've already notified {#if client.name}{client.name}{:else}your
                    client{/if} of this completed task.</em
                >
              </p>
            </div>
          </div>
          <div class="row">
            <div class="d-grid">
              <button
                on:click={() => {
                  isShowingCompletePopover = false;
                  clickCompleteNotifyOff();
                }}
                type="submit"
                class="btn btn-outline-danger btn-sm"
                >Turn Off Notify Client & Proceed</button
              >
            </div>
          </div>
        {:else if task.is_assigned_to_client && !isShowingCompleteAssignedToClient}
          <div transition:slide>
            <div class="row text-left">
              <h5>
                {#if task.is_assigned_to_client && !isShowingCompleteAssignedToClient}
                  Assigned to {client.name ? client.name : "Your Client"}
                {/if}
              </h5>
            </div>
            <div class="row">
              <div class="col">
                <p class="mb-2">
                  <em>
                    {#if task.is_assigned_to_client && !isShowingCompleteAssignedToClient}
                      Are you sure you want to mark it as complete?
                    {/if}
                  </em>
                </p>
              </div>
            </div>
            <div class="d-grid">
              {#if task.is_assigned_to_client && !isShowingCompleteAssignedToClient}
                <button
                  type="submit"
                  on:click={() => {
                    isShowingCompleteAssignedToClient = true;
                  }}
                  class="btn btn-white btn-sm btn-fill btn-block"
                  ><i class="bi-check2-circle" /> Complete & Notify Client</button
                >
                <span class="divider-center">Or</span>
                <button
                  on:click={clickSendReminderAssignedToClient}
                  type="submit"
                  class="btn btn-primary btn-sm btn-fill btn-block"
                  disabled={!canReSendReminder}
                  ><i class="bi-bell" />
                  {task.last_reminder_sent_at ? "Resend" : "Send"} Reminder</button
                >
                {#if task.last_reminder_sent_at}
                  <small class="d-block"
                    >Last reminder sent {dayjs(
                      task.last_reminder_sent_at
                    ).fromNow()}. {#if !canReSendReminder}Please allow 24 hours
                      to send another reminder.{/if}</small
                  >
                {/if}
              {/if}
            </div>
          </div>
        {:else}
          <div transition:slide>
            <div
              class={isShowingCompleteAssignedToClient
                ? "d-flex flex-row align-items-center"
                : "row text-left"}
            >
              {#if isShowingCompleteAssignedToClient}
                <button
                  class="btn btn-icon btn-white btn-xs me-2"
                  on:click={() => {
                    isShowingCompleteAssignedToClient = false;
                  }}><i class="bi-arrow-left" /></button
                >
              {/if}
              <h5 class={isShowingCompleteAssignedToClient ? "mb-0" : ""}>
                Complete Task & Notify Client
              </h5>
            </div>
            <div class="row mb-2">
              <div class="col">
                {#if task.project.key_contact}
                  <label class="form-label" for="clientContact">To</label>
                  {#each task.project.contacts as contact}
                    {#if contact.id == task.project.key_contact.id}
                      <input
                        id="clientContact"
                        type="text"
                        class="form-control mb-2"
                        value="{contact.first_name} {contact.last_name} <{contact.email}>"
                        readonly
                      />
                    {/if}
                  {/each}
                  {#if task.project.contacts}
                    {#if showCC}
                      <label class="form-label" for="clientContact">Cc</label>
                    {/if}
                    {#each task.project.contacts as contact}
                      {#if contact.id != task.project.key_contact.id}
                        <input
                          id="clientContact"
                          type="text"
                          class="form-control mb-2"
                          value="{contact.first_name} {contact.last_name} <{contact.email}>"
                          readonly
                        />
                      {/if}
                    {/each}
                  {/if}
                {:else if task.project.contacts}
                  <label class="form-label" for="clientContact">To</label>
                  {#each task.project.contacts as contact}
                    <input
                      id="clientContact"
                      type="text"
                      class="form-control mb-2"
                      value="{contact.first_name} {contact.last_name} <{contact.email}>"
                      readonly
                    />
                  {/each}
                {/if}
              </div>
            </div>
            <div class="row text-left">
              <div class="col">
                <label class="form-label" for="notifyClientMessage"
                  >Message</label
                >
                <textarea
                  bind:value={taskMessage}
                  id="notifyClientMessage"
                  rows="5"
                  class="form-control"
                  placeholder="This message will be sent to your client and visible on your client review page."
                />
                <small
                  class="d-block text-end {messageLength >= 1000
                    ? 'text-danger'
                    : ''}">{messageLength}/1000 characters</small
                >
                {#if errors.taskMessage}
                  <small class="d-block text-danger text-end">
                    {errors.taskMessage}
                  </small>
                {/if}
              </div>
            </div>
            {#if task.is_deliverable}
              <div class="row mb-2">
                <div class="col">
                  <label class="form-label" for="deliverableLink"
                    >Deliverable Link</label
                  >
                  <input
                    id="deliverableLink"
                    type="text"
                    bind:value={taskDeliverableLink}
                    on:keydown={() => {
                      // This checks if the deliverable field is empty. If so, sets task.is_deliverable to true.
                      if (!task.is_deliverable) {
                        if (taskDeliverableLink != null) {
                          clickDeliverable();
                        }
                      }
                    }}
                    placeholder="DropBox, Vimeo, etc."
                    class="form-control"
                    maxlength="255"
                  />
                  {#if errors.taskDeliverableLink}
                    <small class="d-block text-danger text-end">
                      {errors.taskDeliverableLink}
                    </small>
                  {/if}
                </div>
              </div>
            {/if}
            <div class="d-grid">
              <button
                on:click={clickCompleteNotify}
                type="submit"
                class="btn btn-primary btn-fill btn-sm">Complete & Send</button
              >
            </div>
          </div>
        {/if}
      </div>
    </div>
  {/if}
</Popover>
